import _invoke from "lodash/invoke";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["allAudienceCheckbox", "audienceTypeCheckbox", "ageInput", "typeFieldset", "ageFieldset"];

  // LIFECYCLE

  connect() {
    this.processAudienceType();

    this.ageInputTargets.forEach((ageInput) => {
      this._updateAgeCheckbox(ageInput);
    });
  }

  // ACTIONS

  processAudienceType(_event) {
    this._toggleCheckedElements(this.allAudienceCheckboxTargets, this._hasAllAudienceTypeCheckboxChecked());
  }

  processAllAudience(event) {
    this._toggleCheckedElements(this.audienceTypeCheckboxTargets, event.target.checked);
  }

  focusAgeInput(event) {
    const checkbox = event.target;
    const ageInput = _invoke(checkbox, "parentNode.parentNode.querySelector", 'input[type="number"]');

    if (ageInput && checkbox.checked) {
      ageInput.focus();
    } else {
      ageInput.value = "";
    }
  }

  checkAgeCheckbox(event) {
    this.updateAgeCheckbox(event, true);
  }

  updateAgeCheckbox(event, forced) {
    this._updateAgeCheckbox(event.target, forced);
  }

  // PRIVATE

  _updateAgeCheckbox(ageInput, forced) {
    const checkbox = _invoke(ageInput, "parentNode.querySelector", 'input[type="checkbox"]');

    if (ageInput && (ageInput.value === "0" || Number.isNaN(parseInt(ageInput.value)))) {
      ageInput.value = "";
    }

    if (checkbox) {
      checkbox.checked = forced || ageInput.value !== "";
    }
  }

  _hasAllAudienceTypeCheckboxChecked() {
    return this.audienceTypeCheckboxTargets.every((target) => target.checked);
  }

  _toggleCheckedElements(elements, toggle) {
    elements.forEach((element) => {
      element.checked = toggle;
    });
  }
}
