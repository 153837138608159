import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["items", "template"];

  // LIFECYCLE

  initialize() {
    this.selectors = {
      nestedItem: ".nested-item",
      destroyInput: 'input[name*="_destroy"]',
    };
  }

  connect() {
    this._hideRemovedItems(this.items);
  }

  // ACTIONS

  addItem(event) {
    event.preventDefault();

    let templateTarget = this.templateTarget;
    const templateId = _get(event, "target.dataset.templateTarget");
    if (templateId) {
      templateTarget = document.getElementById(templateId);
    }

    const templateDefaultIndex = _get(event, "target.dataset.templateDefaultIndex", "NEW_RECORD");
    const content = templateTarget.innerHTML.replace(new RegExp(templateDefaultIndex, "g"), new Date().getTime());
    this.itemsTarget.insertAdjacentHTML("beforeend", content);
  }

  removeItem(event) {
    event.preventDefault();

    const item = event.target.closest(this.selectors.nestedItem);
    this._removeItem(item);
  }

  // GETTERS / SETTERS

  get items() {
    return this.hasItemsTarget ? this.itemsTarget.querySelectorAll(this.selectors.nestedItem) : [];
  }

  clearItems() {
    this.items.forEach((item) => {
      this._removeItem(item);
    });
  }

  // PRIVATE

  _removeItem(item) {
    if (item.dataset.newRecord == "true") {
      item.remove();
    } else {
      item.querySelector(this.selectors.destroyInput).value = 1;
      item.hidden = true;
    }
  }

  _hideRemovedItems(items) {
    items.forEach((item) => {
      if (_get(item.querySelector(this.selectors.destroyInput), "value") === "true") {
        item.hidden = true;
      }
    });
  }
}
