import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["fieldset", "association"];
  static values = { type: String };

  // LIFECYCLE

  connect() {
    this._initFieldset();
  }

  // ACTIONS

  selectPlacingType(event) {
    const placingType = _get(event, "target.dataset.placingType");

    if (!this.hasFieldsetTarget || !placingType) {
      return;
    }

    this.typeValue = placingType;

    this.fieldsetTargets.forEach((fieldset) => {
      fieldset.hidden = fieldset.dataset.placingType !== placingType;
      this._resetFieldset(fieldset, !fieldset.hidden);
    });
  }

  updatePlacingAssociations(event) {
    const associationId = _get(event, "detail.id");
    const associationModel = _get(event, "detail.model");

    this.associationTargets.forEach((association) => {
      association.value = association.dataset.associationModel == associationModel ? associationId : "";
    });
  }

  // GETTERS & SETTERS

  get duplicatedNames() {
    if (this._duplicatedNames) {
      return this._duplicatedNames;
    }

    this._duplicatedNames = [];
    const nameRegister = new Set();
    const namedElements = this.element.querySelectorAll("[name]");
    namedElements.forEach((namedElem) => {
      const name = namedElem.name;

      // radio inputs have duplicate name by definition
      if (namedElem.type == "radio" || !nameRegister.has(name)) {
        nameRegister.add(name);
        return;
      }

      this._duplicatedNames.push(name);
    });

    return this._duplicatedNames;
  }

  // PRIVATE

  _initFieldset() {
    this.fieldsetTargets.forEach((fieldset) => {
      if (fieldset.hidden) {
        this._disableRequiredInsideFieldset(fieldset);
        this._toggleDisabledOnDuplicateNameInputs(fieldset, true);
      }
    });
  }

  _resetFieldset(fieldset, active) {
    // inputs
    const inputs = fieldset.querySelectorAll('input[type="hidden"], input[type="text"], textarea');
    inputs.forEach((input) => (input.value = ""));

    // places
    const places = fieldset.querySelectorAll('[data-autocomplete-target="selection"]');
    places.forEach((place) => {
      place.innerHTML = "";
      delete place.dataset.autocompleteParams;
    });

    // hide back toggled btn-check
    const checks = fieldset.querySelectorAll('.btn-check[data-action~="toggle#hidden"]');
    checks.forEach((check) => {
      check.checked = false;
      check.dispatchEvent(new Event("input"));
    });

    // maps
    const mapIdentifier = "geo-picker";
    const mapElem = fieldset.querySelector(`[${this.application.schema.controllerAttribute}~="${mapIdentifier}"]`);
    // map exist and is visible
    if (mapElem && mapElem.offsetParent) {
      const mapController = this.application.getControllerForElementAndIdentifier(mapElem, mapIdentifier);
      mapController.reset();
    }

    // accessibility
    const a11yIdentifier = "accessibility";
    const a11yElem = fieldset.querySelector(`[${this.application.schema.controllerAttribute}~="${a11yIdentifier}"]`);
    if (a11yElem) {
      const a11yController = this.application.getControllerForElementAndIdentifier(a11yElem, a11yIdentifier);
      a11yController.reset();
    }

    // disable required
    if (active) {
      this._enalbeRequiredInsideFieldset(fieldset);
    } else {
      this._disableRequiredInsideFieldset(fieldset);
    }

    // toggle disabled on duplicates
    this._toggleDisabledOnDuplicateNameInputs(fieldset, !active);
  }

  _disableRequiredInsideFieldset(fieldset) {
    const required = fieldset.querySelectorAll("[required]");
    required.forEach((r) => {
      r.dataset.required = r.required;
      r.required = false;
    });
  }

  _enalbeRequiredInsideFieldset(fieldset) {
    const required = fieldset.querySelectorAll('[data-required="true"]');
    required.forEach((r) => {
      r.required = true;
      delete r.dataset.required;
    });
  }

  _toggleDisabledOnDuplicateNameInputs(fieldset, disabled) {
    const selectors = this.duplicatedNames.map((name) => {
      return `[name="${name}"]`;
    });
    const duplicates = fieldset.querySelectorAll(selectors.join(", "));
    duplicates.forEach((d) => (d.disabled = disabled));
  }
}
