import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row", "updateBtn"];

  // ACTIONS
  toggleChoice(event) {
    const elem = event.target;
    const wrapper = event.target.closest(".sowprog-row");
    const valueField = wrapper.querySelector(".sowprog-value");

    const pageElem = wrapper.querySelector(".page-elem");
    const sowprogElem = wrapper.querySelector(".sowprog-elem");
    if (elem.classList.contains("sowprog-event")) {
      wrapper.querySelectorAll(".sowprog-event-value").forEach((item) => {
        const name = elem.checked ? item.dataset.name : null;
        item.setAttribute("name", name);
      });
    } else if (elem.classList.contains("sowprog-place")) {
      wrapper.querySelectorAll(".sowprog-value").forEach((field) => {
        field.name = elem.checked ? field.dataset.name : null;
      });
    } else {
      if (sowprogElem.classList.contains("image")) {
        valueField.value = elem.checked ? sowprogElem.getAttribute("src") : pageElem.getAttribute("src");
      } else if (sowprogElem.hasAttribute("data-value")) {
        const value = elem.checked ? sowprogElem.dataset.value : pageElem.dataset.value;
        valueField.value = value || "";
      } else {
        valueField.value = elem.checked ? sowprogElem.innerText : pageElem.innerText;
      }
    }

    this._toggleUpdateBtn();
  }

  _getSowprogsController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("sowprogs-listing-wrapper"),
      "sowprogs"
    );
  }

  _toggleUpdateBtn() {
    if (!this.hasUpdateBtnTarget) return;
    const checkeds = document.querySelectorAll("input[type=checkbox]:checked");
    const sowprogsController = this._getSowprogsController();

    sowprogsController.updateLinkTarget.classList.toggle("disabled", !checkeds.length);
  }
}
