import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["exceptionField"];

  // LIFECYCLE

  connect() {
    //
  }

  // ACTIONS

  toggleExceptions(event) {
    if (!this.hasExceptionFieldTarget) {
      return;
    }

    const checked = Boolean(event.target.checked);
    this.exceptionFieldTarget.hidden = !checked;

    if (!checked) {
      this.exceptionFieldTarget.querySelector("textarea").value = "";
    }
  }
}
