import ApplicationController from "./application_controller";

import { currentUserFullName } from "../helpers/gon_helper";
import { removeExtensionFromName } from "../helpers/string_helper";
import { csrfToken } from "../helpers/csrf_helper";
import axios from "axios";

export default class extends ApplicationController {
  static targets = [
    // ImageComponent
    "imageId",
    "imageContainer",
    "imageComponentSelector",
    "imagePlaceholder",
    "imagePreview",
    "imageSelector",
    "imageSrc",
    // ModalComponent
    "modal",
    "body",
    "newFileButton",
    "headerImageId",
    "close",
    "title",
    "pageableType",
    "author",
    "backward",
    "progressContainer",
    "overlay",
    "dropZone",
    // HeaderImageComponent
    "headerImagePreview",
  ];

  static values = {
    path: String,
    newPath: String,
  };

  connect() {
    this.listenDropZone();
    this.defaultHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  }

  listenDropZone() {
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      this.dropZoneTarget.addEventListener(eventName, (e) => this._preventDefaults(e), false);
    });

    this.dropZoneTarget.addEventListener("drop", (e) => this._drop(e), false);

    ["dragenter", "dragover"].forEach((eventName) => {
      this.dropZoneTarget.addEventListener(eventName, (e) => this._highligth(e, true), false);
    });

    ["dragleave", "drop"].forEach((eventName) => {
      this.dropZoneTarget.addEventListener(eventName, (e) => this._highligth(e, false), false);
    });
  }

  openModal(e) {
    e.preventDefault();
    this.bodyTarget.innerHTML = "";
    this._toggleButtons(true);

    this.target = e.target.dataset.previewTargetId;

    this._fetchMedia(this.pathValue);

    this.source = e.target;
  }

  triggerFileSelector(e) {
    e.preventDefault();
    this.imageComponentSelectorTarget.click();
  }

  chooseImage(event) {
    event.preventDefault();

    this._fetchMedia(this.newPathValue).then(() => {
      this._toggleButtons(false);
      this.imageComponentSelectorTarget.click();
      this._setSubmitDataTargetValue();
    });
  }

  insert(e) {
    if (e) e.preventDefault();

    if (this.source.dataset.origin === "headerImage") {
      this.headerImageIdTarget.value = this.hasImageIdTarget ? this.imageIdTarget.value : e.target.dataset.imageId;
      this._insertMedium(e?.target);
    } else {
      const imgPreviewTarget = document.querySelector(`#${this.target}`);
      const target = e?.target || this.element.querySelector("#medium-params");
      this._insertMedium(target, imgPreviewTarget);
    }
  }

  edit(e) {
    e.preventDefault();
    this._fetchMedia(e.target.href).then(() => this._toggleButtons(false));
    this._setSubmitDataTargetValue();
  }

  pageableType() {
    const type = document.querySelector("#page_pageable_type")?.value;
    if (this.hasPageableTypeTarget && type) {
      this.pageableTypeTarget.value = type;
    }
  }

  handlePreview(event) {
    const reader = new FileReader();

    reader.onload = () => {
      this._toggleButtons(false);

      const file = event ? event.target.files[0] : this.droppedFiles[0];
      const previewImageSrc = reader.result;

      this._fillAside(file);
      this._fillImageData(previewImageSrc);
    };

    if (!event) this.imageComponentSelectorTarget.files = this.droppedFiles;

    reader.readAsDataURL(event ? event.target.files[0] : this.droppedFiles[0]);
  }

  goToListing() {
    this._fetchMedia(this.pathValue);
    this._toggleButtons(true);
  }

  toggleLoading(loading) {
    this.progressContainerTarget.classList.toggle("d-none", loading);
    this.overlayTarget.classList.toggle("d-none", loading);
    this.overlayTarget.style.zIndex = loading ? -1 : 1;
  }

  delete_remotly(e) {
    this.stopEventPropagation(e);

    if (confirm(e.target.dataset.confirmText)) {
      const headers = { "X-CSRF-Token": csrfToken(), ...this.defaultHeaders };
      this._fetchMedia(e.target.getAttribute("href"), "DELETE", headers).then(() => {
        this.backwardTarget.dispatchEvent(new Event("click"));
      });
    }
  }

  // Private
  _drop(e) {
    this.droppedFiles = e.dataTransfer?.files;
    this._fetchMedia(this.newPathValue).then(() => {
      this._toggleButtons(false);
      this._setSubmitDataTargetValue();
      this.handlePreview();
    });
  }

  _highligth(e, shouldHighlight) {
    this.dropZoneTarget.classList.toggle("highlight", shouldHighlight);
  }

  _preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  _setSubmitDataTargetValue() {
    const pingTarget = setInterval(() => {
      if (this.element.querySelector("#insert-target")) {
        clearInterval(pingTarget);
        this.element.querySelector("#insert-target").value = this.target;
      }
    });
  }

  _insertMedium(target, imgPreviewTarget) {
    if (imgPreviewTarget) {
      imgPreviewTarget.src = target.dataset.imageSrc;
      const figure = imgPreviewTarget.closest(".pe-image-figure");
      figure.dataset.mediumParams = target.dataset.params;
      figure.querySelector(".pe-image-figure__caption").value = target.dataset.caption;
    } else {
      this.headerImagePreviewTarget.src = this.hasImageSrcTarget ? this.imageSrcTarget.value : target.dataset.imageSrc;
    }

    this.closeTarget.click();
  }

  _fillAside(file) {
    this.titleTarget.value = removeExtensionFromName(file.name);
    this.authorTarget.innerHTML = currentUserFullName();
  }

  _fillImageData(src) {
    this.imagePreviewTarget.src = src;
  }

  _toggleButtons(shown) {
    this.newFileButtonTarget.classList.toggle("d-none", !shown);
    this.backwardTarget.classList.toggle("d-none", shown);
  }

  _fetchMedia(path, method = "GET", headers = this.defaultHeaders) {
    return new Promise((resolve, reject) => {
      axios(path, { method, headers })
        .then((response) => {
          this.bodyTarget.innerHTML = response.data.html;
          this.pageableType();
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  }
}
