import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["singleDateFieldset", "multipleDatesFieldset", "endedOnFieldset"];

  // LIFECYCLE

  connect() {
    //
  }

  // ACTIONS

  enableSingleDateFieldset(_event) {
    this._toggleHidddenElements(this.singleDateFieldsetTargets, false);
    this._toggleHidddenElements(this.multipleDatesFieldsetTargets, true);
    this._resetElements(this.multipleDatesFieldsetTargets);
  }

  enableMultipleDatesFieldset(_event) {
    this._toggleHidddenElements(this.multipleDatesFieldsetTargets, false);
    this._toggleHidddenElements(this.singleDateFieldsetTargets, true);
    this._resetElements(this.singleDateFieldsetTargets);
  }

  resetEndedOnFieldset(_event) {
    if (!this.hasEndedOnFieldsetTarget || !this.endedOnFieldsetTarget.hidden) {
      return;
    }

    this._resetFieldset(this.endedOnFieldsetTarget);
  }

  // PRIVATE

  _toggleHidddenElements(elements, toggle) {
    elements.forEach((element) => {
      element.hidden = toggle;
    });
  }

  _resetElements(elements) {
    elements.forEach((element) => {
      this._resetFieldset(element);
    });
  }

  _resetFieldset(fieldset) {
    // reset datetime input
    const datetimes = fieldset.querySelectorAll('[data-controller~="datetime"]');
    datetimes.forEach((datetimeElem) => {
      const datetimeController = this.application.getControllerForElementAndIdentifier(datetimeElem, "datetime");
      datetimeController.clear();
    });

    // remove nested items
    const nestedItems = fieldset.querySelectorAll(".nested-item");
    nestedItems.forEach((nestedItem) => {
      const nestedController = this.getControllerForIdentifierContainingElement("nested-form", nestedItem);
      nestedController._removeItem(nestedItem);
    });
  }
}
