/* global Bliss */

import _assign from "lodash/assign";
import _find from "lodash/find";
import _get from "lodash/get";
import _isNil from "lodash/isNil";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
import _pick from "lodash/pick";

import { slugify } from "helpers/string_helper";
import { copyAnchor } from "helpers/editor_helper";

import "./title_block.scss";

export default class TitleBlock {
  // TitleBlock data schema
  // {
  //   id: < string>,
  //   title: < string: inline HTML, required >,
  //   level: < integer: 2 | 3 | 4 | 5, default: 2 >,
  //   modifiers: [< string, default: "in-toc">]
  // }

  static get icons() {
    return {
      text: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><path d="M0 0v2h.5c0-.55.45-1 1-1h1.5v5.5c0 .28-.22.5-.5.5h-.5v1h4v-1h-.5c-.28 0-.5-.22-.5-.5v-5.5h1.5c.55 0 1 .45 1 1h.5v-2h-8z" /></svg>',
      inToc: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/bookmark.svg"),
      anchor: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/anchor.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "title",
      icon: TitleBlock.icons.text,
    };
  }

  static get pasteConfig() {
    return {
      tags: ["H1", "H2", "H3", "H4", "H5", "H6"],
    };
  }

  static get conversionConfig() {
    return {
      export: "title",
      import: "title",
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
      defaultLevel: 2,
      levels: [1, 2, 3, 4, 5, 6],
      modifiers: ["in-toc"],
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      id: true,
      title: {},
      level: true,
      modifiers: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-title",
      settings: "pe-title-settings",
      levelSettings: "pe-title-settings-level",
      modifierSettings: "pe-title-settings-modifier",
      clipboardSettings: "pe-title-settings-clipboard",
    };

    this._blockElem = undefined;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const title = blockContent.textContent;

    const blockData = {
      title: blockContent.innerHTML,
      level: this.currentLevel.number,
      modifiers: this.currentModifiers,
    };

    blockData.id = slugify(title);

    // empty title then block is in error
    if (title.trim() === "") {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["texte manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  onPaste(event) {
    const content = event.detail.data;
    const level = this._levelFromTag(content.tagName);

    // unavailable level
    if (_isNil(level)) {
      console.error("TitleBlock: unavailable level");
      return;
    }

    this._replaceBlockElem({
      level: level.number,
      title: content.innerHTML,
    });
  }

  // GETTERS & SETTERS

  // get current level according to block element tagName
  // or default level
  get currentLevel() {
    return this._levelFromTag(_get(this._blockElem, "tagName")) || this.defaultLevel;
  }

  // get level according to config default level number
  get defaultLevel() {
    return this._levelFromNumber(this.config.defaultLevel);
  }

  // get current modifiers according to block element classes
  get currentModifiers() {
    return this.modifiers.reduce((result, modifier) => {
      if (this._blockElem.classList.contains(modifier.class) && modifier.levels.includes(this.currentLevel.number)) {
        result.push(modifier.class);
      }

      return result;
    }, []);
  }

  get inTocModifier() {
    return _find(this.config.modifiers, ["class", "in-toc"]);
  }

  get levels() {
    return [
      {
        number: 1,
        tag: "h1",
        html: '<span class="title is-level-1">T</span>',
        title: "titre",
      },
      {
        number: 2,
        tag: "h2",
        html: '<span class="title is-level-2">T<small>1</small></span>',
        title: "titre de niveau 1",
      },
      {
        number: 3,
        tag: "h3",
        html: '<span class="title is-level-3">T<small>2</small></span>',
        title: "titre de niveau 2",
      },
      {
        number: 4,
        tag: "h4",
        html: '<span class="title is-level-4">T<small>3</small></span>',
        title: "titre de niveau 3",
      },
      {
        number: 5,
        tag: "h5",
        html: '<span class="title is-level-5">T<small>4</small></span>',
        title: "titre de niveau 4",
      },
      {
        number: 6,
        tag: "h6",
        html: '<span class="title is-level-6">T<small>5</small></span>',
        title: "titre de niveau 5",
      },
    ];
  }

  get modifiers() {
    return [
      {
        class: "in-toc",
        levels: [2],
        html: TitleBlock.icons.inToc,
        title: "afficher/masquer du sommaire",
      },
    ];
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["id", "title", "level", "modifiers"]);

    data.id = _isString(data.id) ? data.id : "";
    data.title = _isString(data.title) ? data.title : "";
    data.level = parseInt(data.level, 10) || this.defaultLevel.number;

    if (!_isArray(data.modifiers)) {
      data.modifiers = [];

      // default level 2 block with in-toc modifier
      if (data.level === 2) {
        data.modifiers.push(this.inTocModifier.class);
      }
    }

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, TitleBlock.defaultConfig, config);

    // default level isn't one of the authorized levels
    if (!assignedConfig.levels.includes(assignedConfig.defaultLevel)) {
      throw new Error("TitleBlock: default level isn't an available level.");
    }

    // populate levels
    assignedConfig.levels = this.levels.filter((level) => {
      return assignedConfig.levels.includes(level.number);
    });

    // populate modifiers
    assignedConfig.modifiers = this.modifiers.filter((modifier) => {
      return assignedConfig.modifiers.includes(modifier.class);
    });

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    const blockElemOptions = {
      tag: this._levelFromNumber(blockData.level).tag,
      class: this._selectors.block + " title is-level-" + blockData.level,
      contenteditable: !this.readOnly,
      "data-placeholder": this.config.placeholder,
      innerHTML: blockData.title,
    };

    // update according to modifiers
    if (_isArray(blockData.modifiers)) {
      blockElemOptions.class += " " + blockData.modifiers.join(" ");
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _replaceBlockElem(blockData) {
    // default level 2 block with in-toc modifier
    if (blockData.level === 2) {
      blockData.modifiers = [this.inTocModifier.class];
    }

    // create new block
    const newBlockElem = this._buildBlockElem(blockData);

    // replace block in DOM
    this._blockElem.parentNode.replaceChild(newBlockElem, this._blockElem);

    // keep new block reference
    this._blockElem = newBlockElem;
  }

  _buildSettingsElem() {
    const settingsOptions = {
      tag: "div",
      class: this._selectors.settings,
      contents: [],
    };

    // add levels options
    this.config.levels.forEach((level) => {
      const levelOptions = {
        tag: "span",
        class: [this.api.styles.settingsButton, this._selectors.levelSettings].join(" "),
        "data-tooltip": "bottom",
        "aria-label": level.title,
        "data-level": level.number,
        innerHTML: level.html,
        events: {
          click: (event) => {
            this._changeLevel(level);
            this._toggleActiveSettings(event.target, level);
          },
        },
      };

      // set active level
      if (this.currentLevel.number === level.number) {
        levelOptions.class += " " + this.api.styles.settingsButtonActive;
      }

      settingsOptions.contents.push(levelOptions);
    });

    // add modifiers options
    this.config.modifiers.forEach((modifier) => {
      const modifierClasses = [this.api.styles.settingsButton, this._selectors.modifierSettings];

      // hide modifier button if not specified for the current level
      if (!modifier.levels.includes(this.currentLevel.number)) {
        modifierClasses.push(this.api.styles.settingsButton + "--hidden");
      }

      const modifierOptions = {
        tag: "span",
        class: modifierClasses.join(" "),
        "data-tooltip": "bottom",
        "aria-label": modifier.title,
        "data-modifier": modifier.class,
        innerHTML: modifier.html,
        events: {
          click: (event) => {
            this._toggleModifier(event.target, modifier);
          },
        },
      };

      if (this.currentModifiers.includes(modifier.class)) {
        modifierOptions.class += " " + this.api.styles.settingsButtonActive;
      }

      settingsOptions.contents.push(modifierOptions);
    });

    const params = {
      block: TitleBlock,
      classname: this._selectors.clipboardSettings,
      styles: this.api.styles,
      copyText: `#${slugify(this._blockElem.textContent)}`,
    };

    // add copy options
    const copyOptions = {
      tag: "span",
      class: [this.api.styles.settingsButton, this._selectors.clipboardSettings].join(" "),
      "data-tooltip": "bottom",
      "aria-label": "copier l'ancre",
      innerHTML: TitleBlock.icons.anchor,
      events: {
        click: (e) => {
          params.e = e;
          copyAnchor(params);
        },
      },
    };
    settingsOptions.contents.push(copyOptions);

    // create settingsElem
    return Bliss.create(settingsOptions);
  }

  _levelFromNumber(number) {
    if (_isNil(number)) {
      return;
    }

    return _find(this.config.levels, ["number", number]);
  }

  _levelFromTag(tag) {
    if (_isNil(tag)) {
      return;
    }

    return _find(this.config.levels, ["tag", tag.toLowerCase()]);
  }

  _changeLevel(level) {
    this._replaceBlockElem({
      level: level.number,
      title: this._blockElem.innerHTML,
    });
  }

  _toggleActiveSettings(target, level) {
    const settingsElem = target.closest("." + this._selectors.settings);
    const settingsArray = Bliss.$("." + this._selectors.levelSettings, settingsElem);

    settingsArray.forEach((settingElem) => {
      const isLevel = parseInt(settingElem.dataset.level, 10) === level.number;
      settingElem.classList.toggle(this.api.styles.settingsButtonActive, isLevel);
    });

    // toggle modifier setting according to level
    const modifierSettingElem = settingsElem.querySelector("." + this._selectors.modifierSettings);
    modifierSettingElem.classList.toggle(
      this.api.styles.settingsButton + "--hidden",
      !this.inTocModifier.levels.includes(level.number)
    );

    // default level 2 block with active modifier
    if (level.number === 2) {
      modifierSettingElem.classList.add(this.api.styles.settingsButtonActive);
    }
  }

  _toggleModifier(target, modifier) {
    const settingElem = target.closest("." + this._selectors.modifierSettings);
    settingElem.classList.toggle(this.api.styles.settingsButtonActive);
    this._blockElem.classList.toggle(modifier.class);
  }
}
