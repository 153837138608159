/* global Bliss */

import _assign from "lodash/assign";
import _has from "lodash/has";
import _pick from "lodash/pick";

import "./quiz_block.scss";

export default class QuizBlock {
  // QuizBlock data schema
  // {
  //   id: < integer, required >,
  //   title: < string >
  // }

  static get icons() {
    return {
      gift: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/gift.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "quiz",
      icon: QuizBlock.icons.gift,
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
      endpoint: "/bo/quizzes.json?query=",
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      id: true,
      title: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-quiz",
      header: "pe-quiz-header",
      quizWrapper: "pe-quiz-wrapper",
      input: "pe-quiz-input",
      autocomplete: "pe-quiz-autocomplete",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    let blockData = {};

    const quizElem = blockContent.querySelector(`.${this._selectors.quizWrapper}`);
    if (quizElem) {
      try {
        blockData = JSON.parse(quizElem.dataset.autocompleteParams);
        blockData = _pick(blockData, ["id", "title"]);
      } catch (err) {
        console.error(err);
      }
    }

    // missing quiz id then block is in error
    if (!_has(blockData, "id")) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["quiz manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_blockData) {
    return true;
  }

  // GETTERS & SETTERS

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["id", "title"]);

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, QuizBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "div",
      className: this._selectors.block,
      "data-controller": "autocomplete",
      "data-autocomplete-endpoint-value": this.config.endpoint,
      "data-autocomplete-options-value": JSON.stringify({
        hint: false,
        cssClasses: {
          root: this._selectors.autocomplete,
          prefix: "peqa", // paris editor quiz autocomplete
        },
        suggestionTemplate: "#${id} - <em>${title}</em>",
      }),
      contents: [
        {
          tag: "div",
          className: this._selectors.header,
          innerText: this.api.i18n.t("title"),
        },
      ],
    };

    // init quiz options
    const quizOptions = {
      tag: "div",
      className: this._selectors.quizWrapper,
      "data-autocomplete-params": JSON.stringify(blockData),
      "data-autocomplete-target": "selection",
    };

    if (_has(blockData, "id")) {
      quizOptions.innerHTML = `#${blockData.id} - <em>${blockData.title}</em>`;
    }

    // init input options
    const inputOptions = {
      tag: "input",
      type: "text",
      placeholder: this.config.placeholder,
      className: [this._selectors.input, this.api.styles.input].join(" "),
      "data-autocomplete-target": "input",
      events: {
        keydown: this._disableEnter.bind(this),
      },
    };

    // insert quiz and input options
    if (this.readOnly) {
      blockElemOptions.contents.push(quizOptions);
      delete blockElemOptions["data-controller"];
    } else {
      blockElemOptions.contents.push(quizOptions, inputOptions);
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
