import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "content",
    "row",
    "import",
    "body",
    "recordId",
    "sowprogStatus",
    "recordLink",
    "importDraftBtn",
    "importAndPublishBtn",
    "updateLink",
  ];

  static values = {
    fetch: String,
  };

  // ACTIONS
  openModal(e) {
    e.preventDefault();
    const btnPreview = e.target.closest("td").querySelector(".btn-preview");
    this._fetchPreviewTpl(btnPreview.getAttribute("href"), btnPreview.dataset.recordId);
    btnPreview.click();
  }

  closeModal() {
    [this.bodyTarget, this.sowprogStatusTarget, this.recordIdTarget].forEach((item) => (item.innerHTML = ""));

    [this.recordLinkTarget, this.importDraftBtnTarget, this.importAndPublishBtnTarget].forEach(
      (item) => (item.href = "#")
    );

    [this.recordLinkTarget, this.importDraftBtnTarget, this.importAndPublishBtnTarget, this.updateLinkTarget].forEach(
      (item) => item.classList.add("d-none")
    );

    this.updateLinkTarget.classList.add("disabled");
  }

  fetch(e) {
    e.preventDefault();

    window
      .fetch(this.fetchValue)
      .then((response) => response.json())
      .then((_) => {
        window.location.reload();
      })
      .catch((err) => console.error(err));
  }

  updateSowprogPage(e) {
    e.preventDefault();
    const sowprogController = this._getSowprogController();
    sowprogController.updateBtnTarget.click();
  }

  _getSowprogController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("sowprog-preview-wrapper"),
      "sowprog"
    );
  }

  _fetchPreviewTpl(url, recordId) {
    return new Promise((resolve, reject) => {
      window
        .fetch(url)
        .then((response) => response.json())
        .then((body) => {
          this.bodyTarget.innerHTML = body.html;
          this.recordIdTarget.innerHTML = `#${recordId}`;

          const icons = [];

          if (body.imported) {
            this.recordLinkTarget.href = body.page_url;
            icons.push('<span class="badge bg-warning">Importé</span>');
          } else {
            this.importDraftBtnTarget.href = body.import_url;
            this.importAndPublishBtnTarget.href = `${body.import_url}?publish=true`;
          }
          if (body.modified) icons.push('<span class="badge bg-success">Mis à jour</span>');
          if (body.canceled) icons.push('<span class="badge bg-danger">Annulé</span>');

          this.sowprogStatusTarget.innerHTML = icons.join(" ");
          this.recordLinkTarget.classList.toggle("d-none", !body.imported);

          this.updateLinkTarget.classList.toggle("d-none", !body.imported);
          this.importDraftBtnTarget.classList.toggle("d-none", body.imported);
          this.importAndPublishBtnTarget.classList.toggle("d-none", body.imported);

          resolve();
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }
}
