// STYLES
import "normalize.css";

import "stylesheets/application";

// SCRIPTS
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "channels";
import debounced from "debounced";
import "blissfuljs/bliss.shy.js";

// TODO - remove
import "@fortawesome/fontawesome-free/js/all.js";

Rails.start();
Turbolinks.start();
debounced.initialize({ input: { wait: 500 } });

import "controllers";
