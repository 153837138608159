import _find from "lodash/find";
import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["current", "select", "input", "alert"];

  // LIFECYCLE

  initialize() {
    this.modifiers = {
      editing: "editing",
    };
  }

  connect() {
    if (this.hasSelectTarget) {
      this.originalValue = this._selectedValue(_get(this.selectTarget, ["options", this.selectTarget.selectedIndex]));
    }
  }

  // ACTIONS

  edit() {
    this.element.classList.add(this.modifiers.editing);

    if (this.hasSelectTarget) {
      this.selectedOption = _find(this.selectTarget.options, "selected");
    }
  }

  cancel() {
    this.element.classList.remove(this.modifiers.editing);

    this._resetSelect();
  }

  confirm() {
    if (!this.hasSelectTarget) {
      return;
    }

    const selected = _get(this.selectTarget, ["options", this.selectTarget.selectedIndex]);

    if (this.hasCurrentTarget && selected) {
      this.currentTarget.innerText = this._selectedText(selected);

      // clear href if currentTarget is an a tag
      if (this.currentTarget.tagName?.toLowerCase() === "a") {
        this.currentTarget.href = "";
      }

      if (this.hasInputTarget) {
        this.inputTarget.value = this._selectedValue(selected);
      }
    }

    if (this.hasAlertTarget) {
      const selectedValue = this._selectedValue(selected);
      const alertTarget = _find(this.alertTargets, ["dataset.infoSelectorAlertId", selectedValue]) || this.alertTarget;

      this.alertTargets.forEach((target) => {
        target.hidden = !(target == alertTarget && this.originalValue !== selectedValue);
      });
    }

    this.element.classList.remove(this.modifiers.editing);
  }

  // PRIVATE

  _resetSelect() {
    if (!this.selectedOption) {
      return;
    }

    this.selectTarget.value = this.selectedOption.value;

    // tag selector
    const tagSelectorController = this.application.getControllerForElementAndIdentifier(
      this.selectTarget,
      "tag-selector"
    );

    if (tagSelectorController) {
      tagSelectorController.resync();
    }
  }

  _selectedValue(selected) {
    if (selected?.value) {
      return selected.value;
    }

    if (this.data.has("empty-value")) {
      return this.data.get("empty-value");
    }

    return "";
  }

  _selectedText(selected) {
    if (selected.text) {
      return selected.text;
    }

    return "aucun·e";
  }
}
