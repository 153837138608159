import _assign from "lodash/assign";
import _has from "lodash/has";
import _pick from "lodash/pick";
import _uniqueId from "lodash/uniqueId";
import _isEmpty from "lodash/isEmpty";

import "./reference_block.scss";

export default class ReferenceBlock {
  // ReferenceBlock data schema
  // {
  //   global_id: < string >, # https://github.com/rails/globalid
  //   href: < string: url >,
  //   title: < string, required >,
  //   text: < string >,
  //   kind: < string >,
  //   label: < string, required >,
  //   image_path: < string: path to media (without CDN hostname), required >,
  //   address: < string >,
  //   date: < string >,
  // }

  static get icons() {
    return {
      arrow: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/level-up-alt.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "reference",
      icon: ReferenceBlock.icons.arrow,
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
      endpoint: "/bo/references?query=",
      parisEndpoint: "/bo/references/paris?query=",
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      global_id: true,
      href: true,
      title: true,
      text: true,
      label: true,
      kind: true,
      image_path: true,
      address: true,
      date: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-reference",
      header: "pe-reference-header",
      referenceWrapper: "pe-reference-wrapper",
      searchBlock: "pe-reference-search-block",
      input: "pe-reference-input",
      autocomplete: "pe-reference-autocomplete",
      autocompleteData: "pe-reference-autocomplete-data",
      radios: "pe-reference-radios",
      btnsWrapper: "buttons-wrapper",
      title: "pe-reference-title",
      text: "pe-reference-text",
    };
    this.defaultText = "Extrait...";

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);
    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    let blockData = {};

    const referenceElem = blockContent;
    if (referenceElem) {
      try {
        blockData = JSON.parse(referenceElem.dataset.autocompleteParams);
        blockData = _pick(blockData, [
          "global_id",
          "href",
          "title",
          "text",
          "label",
          "image_path",
          "address",
          "date",
          "kind",
        ]);
      } catch (err) {
        console.error(err);
      }
    }

    // missing reference title then block is in error
    if (!_has(blockData, "title")) {
      blockData._errors = {
        title: `composant ${this.api.i18n.t("title")}`,
        messages: ["titre manquant"],
      };
    }

    if (!_has(blockData, "href")) {
      blockData._errors = {
        title: `composant ${this.api.i18n.t("title")}`,
        messages: ["lien manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_blockData) {
    return true;
  }

  // GETTERS & SETTERS

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["global_id", "href", "title", "text", "label", "image_path", "address", "date", "kind"]);
    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, ReferenceBlock.defaultConfig, config);
    return assignedConfig;
  }

  _getBlockElem(target) {
    return target.closest(`.${this._selectors.block}`);
  }

  _buildBtnsWrapper() {
    const btnsWrapper = document.createElement("div");
    btnsWrapper.classList.add(this._selectors.btnsWrapper, "btn-group", "col-12");

    const okBtn = document.createElement("button");
    okBtn.setAttribute("button", "button");
    okBtn.classList.add("btn", "btn-outline-success", "col-6");
    okBtn.innerText = "OK";
    okBtn.addEventListener("click", this._saveChanges.bind(this));

    const cancelBtn = document.createElement("button");
    cancelBtn.setAttribute("button", "button");
    cancelBtn.innerText = "Annuler";
    cancelBtn.classList.add("btn", "btn-outline-danger", "col-6");
    cancelBtn.addEventListener("click", this._hideUpdateBlock.bind(this));

    btnsWrapper.appendChild(okBtn);
    btnsWrapper.appendChild(cancelBtn);
    return btnsWrapper;
  }

  _buildUpdateBtn() {
    const updateBtn = document.createElement("button");
    updateBtn.setAttribute("button", "button");
    updateBtn.innerText = "Modifier";
    updateBtn.classList.add("btn", "btn-link", "blocks--reference-update-btn");
    updateBtn.addEventListener("click", this._showSearchBlock.bind(this));
    return updateBtn;
  }

  _buildBlockElem(blockData) {
    const blockElem = document.createElement("div");
    blockElem.classList.add(this._selectors.block, "blocks--reference", "has-default-format");

    blockElem.dataset.controller = "autocomplete";
    blockElem.dataset.autocompleteEndpointValue = this._fromQfap(blockData)
      ? this.config.endpoint
      : this.config.parisEndpoint;
    blockElem.dataset.autocompleteOptionsValue = JSON.stringify({
      hint: false,
      cssClasses: {
        root: this._selectors.autocomplete,
        prefix: "pera", // paris editor reference autocomplete
      },
    });

    blockElem.addEventListener("autocomplete:selected", this._autocompleteSelected.bind(this));
    blockElem.dataset.autocompleteParams = JSON.stringify(blockData);

    const headerElem = document.createElement("div");
    headerElem.classList.add(this._selectors.header, "blocks--reference-header");
    headerElem.innerText = this.api.i18n.t("title");

    const headingElem = document.createElement("div");
    headingElem.classList.add("blocks--reference-heading");

    const referenceElem = document.createElement("div");
    referenceElem.classList.add(this._selectors.title, "blocks--reference-title", "blocks--reference-link");
    referenceElem.dataset.autocompleteParams = JSON.stringify(blockData);

    if (_has(blockData, "title")) {
      referenceElem.innerHTML = blockData.title;
    }

    referenceElem.addEventListener("click", this._renderEditable.bind(this));
    headingElem.appendChild(referenceElem);

    headerElem.appendChild(this._buildUpdateBtn());
    headerElem.appendChild(headingElem);

    const contentElem = document.createElement("div");
    contentElem.classList.add("blocks--reference-content");

    const textElem = document.createElement("div");
    textElem.classList.add(this._selectors.text, "blocks--reference-text");
    textElem.innerHTML = blockData.text || this.defaultText;
    textElem.addEventListener("click", this._renderEditable.bind(this));

    contentElem.appendChild(textElem);

    blockElem.appendChild(headerElem);
    blockElem.appendChild(contentElem);

    blockElem.appendChild(this._buildSearchBlock(blockData));

    return blockElem;
  }

  _buildSearchBlock(blockData) {
    const isPersisted = !_isEmpty(blockData);

    const searchBlock = document.createElement("div");
    searchBlock.classList.add(this._selectors.searchBlock);

    const inputElem = document.createElement("input");
    inputElem.setAttribute("type", "text");
    inputElem.setAttribute("placeholder", this.config.placeholder);
    inputElem.dataset.autocompleteTarget = "input";
    inputElem.addEventListener("keydown", this._disableEnter.bind(this));
    inputElem.classList.add(...this._selectors.input.split(" "), this.api.styles.input);

    const radiosQFAPId = _uniqueId("pera-qfap-radios-");
    const radiosParisId = _uniqueId("pera-paris-radios-");
    const radiosNameId = _uniqueId("pera-radios-");

    const radioQfapElem = document.createElement("div");
    radioQfapElem.classList.add(
      "d-inline-flex",
      "align-items-center",
      "form-check",
      "form-check-inline",
      "mt-3",
      this._selectors.radios
    );

    const radioQfapInput = document.createElement("input");
    radioQfapInput.setAttribute("type", "radio");
    radioQfapInput.setAttribute("id", radiosQFAPId);
    radioQfapInput.setAttribute("name", radiosNameId);
    radioQfapInput.checked = this._fromQfap(blockData);
    radioQfapInput.dataset.action = "click->autocomplete#reInit";
    radioQfapInput.classList.add("form-check-input", "cursor-pointer");
    radioQfapInput.addEventListener("change", this._selectSource.bind(this));

    const radioQfapLabel = document.createElement("label");
    radioQfapLabel.setAttribute("for", radiosQFAPId);
    radioQfapLabel.classList.add("form-check-label", "ms-2");

    const radioQfapSpan = document.createElement("span");
    radioQfapSpan.innerText = this.api.i18n.t("radioQFAP");

    radioQfapLabel.appendChild(radioQfapSpan);
    radioQfapElem.appendChild(radioQfapInput);
    radioQfapElem.appendChild(radioQfapLabel);

    const radioParisElem = document.createElement("div");
    radioParisElem.classList.add(
      "d-inline-flex",
      "align-items-center",
      "form-check",
      "form-check-inline",
      this._selectors.radios
    );

    const radioParisInput = document.createElement("input");
    radioParisInput.setAttribute("type", "radio");
    radioParisInput.setAttribute("id", radiosParisId);
    radioParisInput.setAttribute("name", radiosNameId);
    radioParisInput.checked = !this._fromQfap(blockData);
    radioParisInput.dataset.action = "click->autocomplete#reInit";
    radioParisInput.classList.add("form-check-input", "cursor-pointer");
    radioParisInput.addEventListener("change", this._selectSource.bind(this));

    const radioParisLabel = document.createElement("label");
    radioParisLabel.setAttribute("for", radiosParisId);
    radioParisLabel.classList.add("form-check-label", "ms-2");

    const radioParisSpan = document.createElement("span");
    radioParisSpan.innerText = this.api.i18n.t("radioParis");

    radioParisLabel.appendChild(radioParisSpan);
    radioParisElem.appendChild(radioParisInput);
    radioParisElem.appendChild(radioParisLabel);

    // insert reference and/or input, switch options
    if (this.readOnly) {
      delete searchBlock.removeAttribute("data-controller");
    } else {
      searchBlock.appendChild(inputElem);
      searchBlock.appendChild(radioQfapElem);
      searchBlock.appendChild(radioParisElem);
    }

    searchBlock.classList.toggle("d-none", isPersisted);

    return searchBlock;
  }

  // HELPERS

  _fromQfap(blockData) {
    return ["Event", "Activity"].includes(blockData.kind) || _isEmpty(blockData);
  }

  // LISTENERS
  _selectSource(event) {
    this._blockElem.dataset.autocompleteEndpointValue = event.target.id.includes("paris")
      ? this.config.parisEndpoint
      : this.config.endpoint;
  }

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  _renderEditable(event) {
    const target = event.target;
    if (target.getAttribute("contentEditable")) return;

    target.setAttribute("contentEditable", true);
    target.classList.add("editable");
    target.dataset.value = target.innerHTML;

    if (target.innerHTML == this.defaultText) {
      target.innerHTML = "";
    }

    target.after(this._buildBtnsWrapper());

    this._getBlockElem(target).querySelector(`.${this._selectors.searchBlock}`).classList.add("d-none");
  }

  _hideUpdateBlock(event, saveChanges) {
    this._stopEverything(event);

    const btnsWrapper = event.target.closest(`.${this._selectors.btnsWrapper}`);
    const editingNode = btnsWrapper.parentNode.querySelector(".editable");
    const blockElem = this._getBlockElem(btnsWrapper);

    const field = editingNode.classList.contains(this._selectors.title) ? "title" : "text";
    const oldValue = editingNode.dataset.value;

    if (saveChanges) {
      const referenceWrapper = blockElem;
      let autocompleteParams = JSON.parse(referenceWrapper.dataset.autocompleteParams);
      autocompleteParams[field] = editingNode.innerHTML;
      referenceWrapper.dataset.autocompleteParams = JSON.stringify(autocompleteParams);
      blockElem.querySelector(`.${this._selectors.searchBlock}`).classList.add("d-none");
    } else {
      editingNode.innerHTML = oldValue;
    }

    editingNode.classList.remove("editable");
    editingNode.removeAttribute("contentEditable");

    btnsWrapper.remove();
  }

  _showSearchBlock(event) {
    event.preventDefault();
    this._getBlockElem(event.target).querySelector(`.${this._selectors.searchBlock}`).classList.remove("d-none");
  }

  _hideSearchBlock(target) {
    this._getBlockElem(target).querySelector(`.${this._selectors.searchBlock}`).classList.add("d-none");
    this._getBlockElem(target).querySelector(`.${this._selectors.input}`).value = "";
  }

  _saveChanges(event) {
    this._hideUpdateBlock(event, true);
  }

  _autocompleteSelected(event) {
    const blockElem = this._getBlockElem(event.target);
    blockElem.querySelector(`.${this._selectors.title}`).innerHTML = JSON.parse(
      blockElem.dataset.autocompleteParams
    ).title;
    blockElem.querySelector(`.${this._selectors.text}`).innerHTML = this.defaultText;
    blockElem.querySelector(`.${this._selectors.searchBlock}`).classList.add("d-none");
    event.target.value = "";
  }

  _stopEverything(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
