import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["radioButton"];

  // LIFECYCLE

  connect() {}

  // ACTIONS

  reset() {
    this.radioButtonTargets.forEach((radioButton) => (radioButton.checked = radioButton.value == ""));
  }

  // PRIVATE
}
