/* global gon */

import _find from "lodash/find";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["item", "versionable"];

  // LIFECYCLE

  connect() {}

  // GETTERS & SETTERS

  get versions() {
    return _get(gon, "versions", []);
  }

  // ACTIONS

  revert(_event) {
    this._changeVersionables();
    this._activateItem();
  }

  load(event) {
    const versionId = parseInt(_get(event, "target.dataset.versionId"), 10);
    const version = _find(this.versions, ["id", versionId]);

    this._changeVersionables(version.object);
    this._activateItem(event.target);
  }

  // PRIVATE

  _changeVersionables(versionData) {
    if (!this.hasVersionableTarget) {
      return;
    }

    this.versionableTargets.forEach((versionable) => {
      this._changeVersionable(versionable, versionData);
    });
  }

  _changeVersionable(versionable, versionData) {
    // input
    if (["text", "textarea"].includes(versionable.type)) {
      const originalValue = this.getData(versionable, "originalValue");

      // version selected
      if (versionData) {
        const attr = this._attrFromName(versionable.name);

        // no diff from current value
        if (versionable.value === versionData[attr]) {
          return;
        }

        // save original value
        if (!originalValue) {
          this.setData(versionable, "originalValue", versionable.value);
        } else {
          // reset original value as it's the same as version value
          if (originalValue == versionData[attr]) {
            versionable.value = originalValue;
            versionable.readOnly = false;
            versionable.classList.remove("is-version");
            this.deleteData(versionable, "originalValue");
            return;
          }
        }

        // set version value
        if (versionData[attr]) {
          versionable.value = versionData[attr];
          versionable.readOnly = true;
          versionable.classList.add("is-version");
        }
      }
      // reset to original value
      else {
        if (originalValue) {
          versionable.value = originalValue;
          versionable.readOnly = false;
          versionable.classList.remove("is-version");
          this.deleteData(versionable, "originalValue");
        }
      }
    }

    // editor
    const editorController = this.application.getControllerForElementAndIdentifier(versionable, "editor");
    if (editorController) {
      const originalValue = this.getData(versionable, "originalValue");

      // version selected
      if (versionData) {
        const attr = this._attrFromName(editorController.blocksTarget.name);

        // editor original value already generated
        if (originalValue) {
          // reset original value as it's the same as version value
          if (_isEqual(originalValue.blocks, versionData[attr].blocks)) {
            editorController.render(originalValue, false);
            editorController.holderTarget.classList.remove("is-version");
            this.deleteData(versionable, "originalValue");
            return;
          }

          // set version value
          if (versionData[attr]) {
            editorController.render(versionData[attr], true);
            editorController.holderTarget.classList.add("is-version");
          }
        } else {
          editorController
            .save()
            .then((editorData) => {
              // no diff from current value
              if (_isEqual(editorData.blocks, versionData[attr].blocks)) {
                return;
              }

              // save original value
              this.setData(versionable, "originalValue", editorData);

              // set version value
              if (versionData[attr]) {
                editorController.render(versionData[attr], true);
                editorController.holderTarget.classList.add("is-version");
              }
            })
            .catch((error) => {
              if (error.data) {
                const errorData = _get(error, "data", []);
                this.feedback.editorAlert(errorData);
              }

              console.error("Editor's saving failed: ", error);
            });
        }
      }
      // reset to original value
      else {
        if (originalValue) {
          editorController.render(originalValue, false);
          editorController.holderTarget.classList.remove("is-version");
          this.deleteData(versionable, "originalValue");
        }
      }
    }
  }

  _attrFromName(name) {
    return name.match(/page\[(.*)\]/)[1];
  }

  _activateItem(activeItem) {
    this.itemTargets.forEach((item) => {
      item.classList.toggle("active", item == activeItem);
    });
  }
}
