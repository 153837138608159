import _find from "lodash/find";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["feeTypeRadio", "feeDetailsTextArea"];
  static values = { toggableDetails: Boolean };

  connect() {
    this.updateDetailsPlaceholder();

    if (this.hasToggableDetailsValue && this.toggableDetailsValue) {
      this.toggleDetailsVisibility();
    }
  }

  updateDetailsPlaceholder() {
    if (!this.hasFeeDetailsTextAreaTarget) {
      return;
    }

    const checkedFeeTypeRadio = _find(this.feeTypeRadioTargets, "checked");

    if (checkedFeeTypeRadio && checkedFeeTypeRadio.dataset.placeholder) {
      this.feeDetailsTextAreaTarget.placeholder = checkedFeeTypeRadio.dataset.placeholder;
    } else {
      this.feeDetailsTextAreaTarget.placeholder = "";
    }
  }

  toggleDetailsVisibility() {
    if (!this.hasFeeDetailsTextAreaTarget) {
      return;
    }

    const checkedFeeTypeRadio = _find(this.feeTypeRadioTargets, "checked");
    const feeDetailsContainer = this.feeDetailsTextAreaTarget.closest(".form-group");

    if (feeDetailsContainer && checkedFeeTypeRadio && checkedFeeTypeRadio.dataset.showDetails === "true") {
      feeDetailsContainer.hidden = false;
    } else {
      feeDetailsContainer.hidden = true;
      this.feeDetailsTextAreaTarget.value = "";
    }
  }
}
