import _assign from "lodash/assign";
import _has from "lodash/has";
import _pick from "lodash/pick";

import "./form_block.scss";

export default class FormBlock {
  // FormBlock data schema
  // {
  //   id: < integer, required >,
  //   title: < string >
  // }

  static get icons() {
    return {
      checkSquare: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/check-square.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "form",
      icon: FormBlock.icons.checkSquare,
    };
  }

  static get defaultConfig() {
    return {
      readOnly: true,
      placeholder: "...",
      endpoint: "/bo/forms.json?query=",
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      id: true,
      title: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = this._parseConfig(config);
    this.readOnly = readOnly || this.config.readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-form",
      header: "pe-form-header",
      formWrapper: "pe-form-wrapper",
      input: "pe-form-input",
      autocomplete: "pe-form-autocomplete",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    // make sure not to modify on readOnly
    if (this.readOnly) {
      return this.data;
    }

    let blockData = {};

    const formElem = blockContent.querySelector(`.${this._selectors.formWrapper}`);
    if (formElem) {
      try {
        blockData = JSON.parse(formElem.dataset.autocompleteParams);
        blockData = _pick(blockData, ["id", "title"]);
      } catch (err) {
        console.error(err);
      }
    }

    // missing form id then block is in error
    if (!_has(blockData, "id")) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["formulaire manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  // GETTERS & SETTERS

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["id", "title"]);

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, FormBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    const blockElem = document.createElement("div");
    blockElem.classList.add(this._selectors.block);

    blockElem.dataset.autocompleteEndpointValue = this.config.endpoint;
    blockElem.dataset.autocompleteOptionsValue = JSON.stringify({
      hint: false,
      cssClasses: {
        root: this._selectors.autocomplete,
        prefix: "pefa", // paris editor form autocomplete
      },
      suggestionTemplate: "#${id} - <em>${title}</em>",
    });

    const headerElem = document.createElement("div");
    headerElem.classList.add(this._selectors.header);
    headerElem.innerText = this.api.i18n.t("title");

    const formElem = document.createElement("div");
    formElem.classList.add(this._selectors.formWrapper);
    formElem.dataset.autocompleteParams = JSON.stringify(blockData);
    formElem.dataset.autocompleteTarget = "selection";

    if (_has(blockData, "id")) formElem.innerHTML = `#${blockData.id} - <em>${blockData.title}</em>`;

    blockElem.appendChild(headerElem);
    blockElem.appendChild(formElem);

    // insert form and input options
    if (!this.readOnly) {
      blockElem.dataset.controller = "autocomplete";
      blockElem.appendChild(this._buildInputElem());
    }

    return blockElem;
  }

  _buildInputElem() {
    const inputElem = document.createElement("input");
    inputElem.setAttribute("type", "text");
    inputElem.setAttribute("placeholder", this.config.placeholder);
    inputElem.classList.add(this._selectors.input, this.api.styles.input);
    inputElem.dataset.autocompleteTarget = "input";
    inputElem.addEventListener("keydown", this._disableEnter.bind(this));
    return inputElem;
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
