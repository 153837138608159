import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.listenForm();
  }

  listenForm() {
    const mediaController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("body"),
      "media"
    );

    this.element.addEventListener("ajax:error", (event) => {
      mediaController.bodyTarget.innerHTML = event.detail[0].html;
    });

    this.element.addEventListener("ajax:success", (event) => {
      mediaController.bodyTarget.innerHTML = event.detail[0].html;

      if (event.detail[0].insert) mediaController.insert();
    });
  }
}
