import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { timeout: Number, removable: Boolean };

  connect() {
    if (this.hasTimeoutValue && this.timeoutValue > 0) {
      this.timeoutID = setTimeout(() => this.close(), this.timeoutValue);
    }
  }

  close() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
      delete this.timeoutID;
    }

    if (this.removableValue) {
      // this.element.classList.add("fade");
      // setTimeout(() => this.remove(), 500);
      this.remove();
    } else {
      this.hide();
    }
  }

  remove() {
    this.element.parentNode.removeChild(this.element);
  }

  hide() {
    this.element.hidden = true;
  }
}
