/* global Bliss */

import _get from "lodash/get";
import _isArray from "lodash/isArray";
// import _isEmpty from "lodash/isEmpty";
import _pick from "lodash/pick";

import "./frame_block.scss";

export default class FrameBlock {
  // FrameBlock data schema
  // {
  //   title: < string: inline HTML, optional >,
  //   text: < string: inline HTML, required >,
  //   modifiers: [< string: "frame--alert">, ...]
  // }

  static get icons() {
    return {
      alert: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/exclamation-triangle.svg"),
      square: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/regular/square.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "frame",
      icon: FrameBlock.icons.square,
    };
  }

  static get conversionConfig() {
    return {
      export: (data) => {
        return [data.title, data.text].filter(Boolean).join("<br>");
      },
      import: "text",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      title: {
        br: true,
      },
      text: {
        br: true,
      },
      modifiers: true,
    };
  }

  constructor({ data, _config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-frame",
      title: "pe-frame-title",
      text: "pe-frame-text",
      settings: "pe-frame-settings",
      modifierSettings: "pe-frame-settings-modifier",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    // add edit listener to links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    });

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const titleElem = blockContent.querySelector("." + this._selectors.title);
    const textElem = blockContent.querySelector("." + this._selectors.text);

    const blockData = {
      title: _get(titleElem, "innerHTML", ""),
      text: _get(textElem, "innerHTML", ""),
      modifiers: this.currentModifiers,
    };

    // empty text then block is in error
    if (_get(textElem, "textContent", "").trim() === "") {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["texte manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  // N.B.: no setting needed as default view is alert
  // renderSettings() {
  //   return this._buildSettingsElem();
  // }

  destroy() {
    // remove edit listeners on links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.off(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  // GETTERS & SETTERS

  // get current modifiers according to block element classes
  get currentModifiers() {
    return this.modifiers.reduce((result, modifier) => {
      if (this._blockElem.classList.contains(modifier.class)) {
        result.push(modifier.class);
      }

      return result;
    }, []);
  }

  get modifiers() {
    return [
      {
        class: "frame--alert",
        html: FrameBlock.icons.alert,
        title: "affichage alerte",
      },
    ];
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["title", "text", "modifiers", "variant"]);

    data.title = this._forceInlineHTML(data.title);
    data.text = this._forceInlineHTML(data.text);

    if (!_isArray(data.modifiers)) {
      data.modifiers = [];
    }

    // N.B.: force alert format
    const alertModifier = this.modifiers[0];
    if (!data.modifiers.includes(alertModifier.class)) {
      data.modifiers.push(alertModifier.class);
    }

    return data;
  }

  _forceInlineHTML(string) {
    if (!string) {
      return "";
    }

    // replace div and p by br
    return String(string)
      .replace(/<(div|p)[^>]*><br><\/?(div|p)[^>]?>/g, "<br>")
      .replace(/(<\/?(div|p)[^>]*>)+/g, "<br>");
  }

  _buildBlockElem(blockData) {
    // N.B.: as enableLineBreaks is enabled for this plugin, we have to make sure that contenteditable tags are p
    // Then specific style is applied for line breaks to insert br to keep only inline tags in contenteditable
    // see discussion in issue #1212 (https://github.com/parisnumerique/paris-fr-v3/issues/1212)
    const blockElemOptions = {
      tag: "div",
      class: this._selectors.block,
      contents: [
        {
          tag: "p",
          class: this._selectors.title,
          "data-placeholder": "titre... (optionel)",
          contenteditable: !this.readOnly,
          innerHTML: blockData.title,
        },
        {
          tag: "p",
          class: this._selectors.text,
          "data-placeholder": "texte...",
          contenteditable: !this.readOnly,
          innerHTML: blockData.text,
        },
      ],
    };

    // update according to modifiers
    if (_isArray(blockData.modifiers)) {
      blockElemOptions.class += " " + blockData.modifiers.join(" ");
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  // _buildSettingsElem() {
  //   const settingsOptions = {
  //     tag: "div",
  //     class: this._selectors.settings,
  //     contents: []
  //   };

  //   // add default modifier options
  //   const defaultModifierOptions = {
  //     tag: "span",
  //     class: this.api.styles.settingsButton + " " + this._selectors.modifierSettings,
  //     "data-tooltip": "bottom",
  //     "aria-label": "affichage encadré",
  //     innerHTML: FrameBlock.icons.square,
  //     events: {
  //       click: event => {
  //         this._toggleModifier(event.target);
  //       }
  //     }
  //   };

  //   // set active modifier
  //   if (_isEmpty(this.currentModifiers)) {
  //     defaultModifierOptions.class += " " + this.api.styles.settingsButtonActive;
  //   }

  //   settingsOptions.contents.push(defaultModifierOptions);

  //   // add modifiers options
  //   this.modifiers.forEach(modifier => {
  //     const modifierOptions = {
  //       tag: "span",
  //       class: this.api.styles.settingsButton + " " + this._selectors.modifierSettings,
  //       "data-tooltip": "bottom",
  //       "aria-label": modifier.title,
  //       "data-modifier": modifier.class,
  //       innerHTML: modifier.html,
  //       events: {
  //         click: event => {
  //           this._toggleModifier(event.target, modifier);
  //         }
  //       }
  //     };

  //     // set active modifier
  //     if (this.currentModifiers.includes(modifier.class)) {
  //       modifierOptions.class += " " + this.api.styles.settingsButtonActive;
  //     }

  //     settingsOptions.contents.push(modifierOptions);
  //   });

  //   // create settingsElem
  //   return Bliss.create(settingsOptions);
  // }

  // _toggleModifier(target, modifier) {
  //   if (!modifier) {
  //     const cls = this.modifiers.map(m => m.class);
  //     this._blockElem.classList.remove(...cls);
  //   }

  //   const settingsElem = target.closest("." + this._selectors.settings);
  //   const settingsArray = Bliss.$("." + this.api.styles.settingsButton, settingsElem);

  //   settingsArray.forEach(settingElem => {
  //     let isModifier;

  //     if (modifier) {
  //       isModifier = settingElem.dataset.modifier === modifier.class;
  //       this._blockElem.classList.toggle(modifier.class, isModifier);
  //     } else {
  //       isModifier = !settingElem.dataset.modifier;
  //     }

  //     settingElem.classList.toggle(this.api.styles.settingsButtonActive, isModifier);
  //   });
  // }

  // LISTENERS

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }
}
