import _find from "lodash/find";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["networkField", "networkSelect"];

  // LIFECYCLE

  connect() {
    //
  }

  // ACTIONS

  addSocialField(event) {
    const selectedNetworkId = event.target.value;

    if (this.hasNetworkSelectTarget) {
      this.networkSelectTarget.value = "";

      const selectedOption = this.networkSelectTarget.querySelector('option[value="' + selectedNetworkId + '"]');

      if (selectedOption) {
        selectedOption.hidden = true;
      }
    }

    if (this.hasNetworkFieldTarget) {
      const selectedNetworkField = _find(this.networkFieldTargets, ["dataset.socialNetworkId", selectedNetworkId]);

      if (selectedNetworkField) {
        selectedNetworkField.hidden = false;
      }
    }
  }
}
