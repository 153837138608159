/* global Bliss */

import _assign from "lodash/assign";
import _get from "lodash/get";
import _has from "lodash/has";
import _isArray from "lodash/isArray";
import _map from "lodash/map";
import _pick from "lodash/pick";
import _set from "lodash/set";
import _uniqueId from "lodash/uniqueId";

import { fogUrl } from "helpers/gon_helper";

import "./image_block.scss";

export default class ImageBlock {
  // ImageBlock data schema
  // {
  //   figures: [
  //     {
  //       id: < integer, required >,
  //       path: < string: path to media (missing CDN hostname), required >,
  //       alt: < string >,
  //       alt_en: < string >,
  //       credit: < string >,
  //       caption: < string >
  //     },
  //     ...
  //   ]
  // }

  static get icons() {
    return {
      images: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/images.svg"),
      trash: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/trash.svg"),
      add: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/plus-square.svg"),
      left: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/arrow-left.svg"),
      right: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/arrow-right.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "image",
      icon: ImageBlock.icons.images,
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
      // initialize img tag src with smallest image
      // cf: https://stackoverflow.com/questions/19126185/setting-an-image-src-to-empty
      emptySrc: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D",
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      // N.B.: skip sanitizing as this should not be HTML
      caption: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = this._parseConfig(config);
    this.readOnly = readOnly || this.config.readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-image",
      figure: "pe-image-figure",
      settings: "pe-image-settings",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const blockData = {};

    // map every figure params and caption
    blockData.figures = _map(Bliss.$("." + this._selectors.figure, blockContent), (figure) => {
      const caption = figure.querySelector("." + this._selectors.figure + "__caption");
      let parsedParams = {};

      try {
        parsedParams = JSON.parse(figure.getAttribute("data-medium-params"));
        parsedParams = _pick(parsedParams, ["id", "path", "alt", "alt_en", "credit", "title", "caption"]);
      } catch (err) {
        console.error(err);
      }

      // missing figure id then block is in error
      if (!_has(parsedParams, "id")) {
        blockData._errors = blockData._errors || { title: "composant " + this.api.i18n.t("title") };
        blockData._errors.messages = ["image manquante"];
      }

      // overwrite caption
      return _assign(parsedParams, {
        caption: _get(caption, "value", ""),
      });
    });

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // remove block if no figure
  validate(savedData) {
    return _isArray(savedData.figures) && savedData.figures.length > 0;
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["figures"]);

    if (!_isArray(data.figures)) {
      data.figures = [];
    }

    // TODO remove objects in the figures array which are invalid

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, ImageBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "div",
      class: this._selectors.block,
      contents: [],
    };

    const figures = blockData.figures;

    // add figures options
    figures.forEach((figureData) => {
      blockElemOptions.contents.push(this._figureOptions(figureData));
    });

    // add empty figure to init
    if (figures.length === 0) {
      blockElemOptions.contents.push(this._figureOptions());
    }

    // add modifier for gallery
    // if (figures.length > 1) {
    blockElemOptions.class += " " + this._selectors.block + "--gallery";
    // }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _buildSettingsElem() {
    const settingsOptions = {
      tag: "div",
      class: this._selectors.settings,
      contents: [],
    };

    // add button options
    settingsOptions.contents.push({
      tag: "span",
      class: this.api.styles.settingsButton,
      "data-tooltip": "bottom",
      "aria-label": "ajouter une nouvelle image",
      innerHTML: ImageBlock.icons.add,
      events: { click: this._addFigure.bind(this) },
    });

    // create settingsElem
    return Bliss.create(settingsOptions);
  }

  _figureOptions(figureData) {
    figureData = figureData || {};
    const uniqueId = `figure-${_uniqueId(Date.now())}`;

    const figureOptions = {
      tag: "div",
      class: this._selectors.figure,
      "data-medium-params": JSON.stringify(figureData),
      contents: [
        {
          tag: "div",
          class: this._selectors.figure + "__img-wrapper",
          contents: [
            {
              tag: "img",
              alt: _get(figureData, "alt", ""),
              id: uniqueId,
            },
          ],
        },
        {
          tag: "input",
          type: "text",
          placeholder: this.config.placeholder,
          class: this._selectors.figure + "__caption " + this.api.styles.input,
          events: { keydown: this._disableEnter.bind(this) },
        },
      ],
    };

    const actionsOptions = {
      tag: "div",
      class: this._selectors.figure + "__actions btn-toolbar",
      role: "toolbar",
      contents: [
        {
          tag: "div",
          class: "btn-group mr-2",
          role: "group",
          contents: [
            {
              tag: "button",
              type: "button",
              class: "actions__left btn btn-sm btn-primary",
              innerHTML: ImageBlock.icons.left,
              events: { click: this._moveFigurePrev.bind(this) },
            },
            {
              tag: "button",
              type: "button",
              class: "actions__right btn btn-sm btn-primary",
              innerHTML: ImageBlock.icons.right,
              events: { click: this._moveFigureNext.bind(this) },
            },
          ],
        },
        {
          tag: "div",
          class: "btn-group",
          role: "group",
          contents: [
            {
              tag: "button",
              type: "button",
              class: "actions__edit btn btn-sm btn-primary",
              innerHTML: ImageBlock.icons.images,
              "data-action": "media#openModal",
              "data-bs-toggle": "modal",
              "data-bs-target": "#media_modal",
              "data-origin": "editor",
              "data-preview-target-id": uniqueId,
            },
            {
              tag: "button",
              type: "button",
              class: "actions__remove btn btn-sm btn-danger",
              innerHTML: ImageBlock.icons.trash,
              events: { click: this._removeFigure.bind(this) },
            },
          ],
        },
      ],
    };

    // set img src
    const path = _get(figureData, "path");
    const src = path ? fogUrl(path) : this.config.emptySrc;
    _set(figureOptions, "contents[0].contents[0].src", src);

    // set figure caption
    const caption = _get(figureData, "caption");
    if (caption) {
      _set(figureOptions, "contents[1].value", caption);
    }

    if (this.readOnly) {
      _set(figureOptions, "contents[1].readonly", true);
    } else {
      figureOptions.contents.push(actionsOptions);
    }

    return figureOptions;
  }

  _toggleGallery() {
    this._blockElem.classList.toggle(
      this._selectors.block + "--gallery",
      this._blockElem.querySelectorAll("." + this._selectors.figure).length > 1
    );
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  _addFigure(_) {
    // create and insert an empty figure in the DOM
    this._blockElem.appendChild(Bliss.create(this._figureOptions()));

    // check gallery display
    // this._toggleGallery();
  }

  _removeFigure(event) {
    event.stopPropagation();

    // remove figure from the DOM
    event.target.closest("." + this._selectors.figure).remove();

    // check gallery display
    // this._toggleGallery();
  }

  _moveFigurePrev(event) {
    const currentFigure = event.target.closest("." + this._selectors.figure);
    this._swapFigures(currentFigure.previousSibling, currentFigure);
  }

  _moveFigureNext(event) {
    const currentFigure = event.target.closest("." + this._selectors.figure);
    this._swapFigures(currentFigure, currentFigure.nextSibling);
  }

  _swapFigures(prev, next) {
    const parent = next.parentNode;
    const sibling = next.nextSibling;

    parent.insertBefore(next, prev);
    parent.insertBefore(prev, sibling);
  }
}
