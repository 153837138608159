import _isPlainObject from "lodash/isPlainObject";
import _isString from "lodash/isString";
import _map from "lodash/map";

export function isValidURL(url) {
  // source: http://urlregex.com/
  // let re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
  let re = /^(https?:\/\/|#|mailto:).+/;

  return re.test(url);
}

export function isExternalUrl(url) {
  const regexp = new RegExp("^(www.)?" + window.location.hostname + "$");
  return !regexp.test(parseUrl(url).hostname);
}

export function parseUrl(url) {
  // The easy way to parse an URL, is to create <a> element.
  // @see: https://gist.github.com/jlong/2428561
  const urlParser = document.createElement("a");
  urlParser.href = url;
  return urlParser;
}

// /bo/pages?kind=article&order_by%5Battribute%5D=id&order_by%5Bdirection%5D=asc&array[]=one&array[]=two#hash
// to
// { kind: "article", order_by: { attribute: "id", direction: "asc" }, array: ["one", "two"] }
export function parseUrlSearch(url) {
  if (!_isString(url) || url.length == 0) {
    return {};
  }

  const searchIndex = url.indexOf("?") + 1;
  const hashIndex = url.indexOf("#") > searchIndex ? url.indexOf("#") : url.length;
  const queries = url.slice(searchIndex, hashIndex).split("&");

  return queries.reduce((params, query) => {
    let [key, value] = query.split("=").map((str) => {
      return decodeURIComponent(str);
    });
    const arrayQuery = key.match(/\[.*\]$/g);

    if (arrayQuery) {
      key = key.replace(arrayQuery, "");
      const valueKey = arrayQuery[0].slice(1, -1);

      if (valueKey) {
        params[key] = params[key] || {};
        params[key][valueKey] = value;
      } else {
        params[key] = params[key] || [];
        params[key].push(value);
      }
    } else {
      params[key] = value;
    }

    return params;
  }, {});
}

// { kind: "article", order_by: { attribute: "id", direction: "asc" }, array: ["one", "two"] }
// to
// kind=article&order_by%5Battribute%5D=id&order_by%5Bdirection%5D=asc&array%5B%5D=one&array%5B%5D=two
export function stringifyUrlSearch(params) {
  return _map(params, (value, key) => {
    if (Array.isArray(value)) {
      return value
        .map((v) => {
          return encodeURIComponent(key + "[]") + "=" + encodeURIComponent(v);
        })
        .join("&");
    } else if (_isPlainObject(value)) {
      return _map(value, (v, k) => {
        return encodeURIComponent(key + "[" + k + "]") + "=" + encodeURIComponent(v);
      }).join("&");
    }

    return encodeURIComponent(key) + "=" + encodeURIComponent(value);
  }).join("&");
}
