/* global Bliss */

import _assign from "lodash/assign";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _get from "lodash/get";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";
import _pick from "lodash/pick";
import { isValidURL, isExternalUrl } from "helpers/url_helper";
import { formatMailTo } from "../../helpers/string_helper";

import "./button_block.scss";

export default class ButtonBlock {
  // ButtonBlock data schema
  // {
  //   text: < string, required >,
  //   href: < string: url, required >,
  //   modifiers: [< string: "is-red", default: "is-red">, ...],
  //   icon: < string: "arrow-right" >,
  //   // l'attribut suivant pourrait être géré côté component
  //   target: < string: "_blank" >
  // }

  static get icons() {
    return {
      arrowRight: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/arrow-right.svg"),
      toggleOn: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/toggle-on.svg"),
      alignCenter: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/align-center.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "button",
      icon: ButtonBlock.icons.toggleOn,
    };
  }

  static get defaultConfig() {
    return {
      textPlaceholder: "...",
      hrefPlaceholder: "...",
      defaultModifiers: [],
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      text: true,
      href: true,
      modifiers: true,
      icon: true,
      target: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-button",
      button: "pe-button-button",
      text: "pe-button-text",
      icon: "pe-button-icon",
      href: "pe-button-href",
      settings: "pe-button-settings",
      modifierSettings: "pe-button-settings-modifier",
    };

    this._blockElem = undefined;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const buttonElem = blockContent.querySelector("." + this._selectors.button);
    const textElem = blockContent.querySelector("." + this._selectors.text);
    const hrefElem = blockContent.querySelector("." + this._selectors.href);

    // format href
    let hrefValue = _get(hrefElem, "value", "");
    hrefValue = formatMailTo(hrefValue);

    // fetch modifiers
    const currentModifiers = this.modifiers.reduce((result, modifier) => {
      if (modifier.type !== "icon" && buttonElem.classList.contains(modifier.class)) {
        result.push(modifier.class);
      }

      return result;
    }, []);

    const blockData = {
      text: _get(textElem, "value", ""),
      href: hrefValue,
      modifiers: currentModifiers,
    };

    if (buttonElem.classList.contains(this.iconModifier)) {
      blockData.icon = "arrow-right";
    }

    if (isExternalUrl(blockData.href)) {
      blockData.target = "_blank";
    }

    // empty text or href then block is in error
    const errorMessages = [];
    if (blockData.text.trim() === "") {
      errorMessages.push("texte manquant");
    }

    if (blockData.href.trim() === "") {
      errorMessages.push("url manquante");
    }

    if (!isValidURL(blockData.href)) {
      errorMessages.push("url invalide");
    }

    if (errorMessages.length > 0) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: errorMessages,
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  // GETTERS & SETTERS

  // get current modifiers according to button element classes
  get currentModifiers() {
    const buttonElem = this._blockElem.querySelector("." + this._selectors.button);

    return this.modifiers.reduce((result, modifier) => {
      if (buttonElem && buttonElem.classList.contains(modifier.class)) {
        result.push(modifier.class);
      }

      return result;
    }, []);
  }

  get iconModifier() {
    return _get(_find(this.modifiers, ["type", "icon"]), "class");
  }

  get modifiers() {
    return [
      {
        type: "style",
        class: "is-red",
        html: "<span></span>",
        title: "rouge",
      },
      {
        type: "style",
        class: "has-border-left",
        html: "<span></span>",
        title: "bordure gauche",
      },
      {
        type: "position",
        class: "is-centered",
        html: ButtonBlock.icons.alignCenter,
        title: "centré",
      },
      {
        type: "icon",
        class: "has-icon",
        html: ButtonBlock.icons.arrowRight,
        title: "avec flèche",
      },
    ];
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["text", "href", "modifiers", "icon", "target"]);

    data.text = _isString(data.text) ? data.text : "";
    data.href = _isString(data.href) ? data.href : "";
    data.icon = _isString(data.icon) ? data.icon : "";
    data.target = _isString(data.target) ? data.target : "";

    if (!_isArray(data.modifiers)) {
      data.modifiers = this.config.defaultModifiers;
    }

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, ButtonBlock.defaultConfig, config);

    // filter defaultModifiers if known modifier
    const modifierClasses = this.modifiers.map((m) => m.class);
    assignedConfig.defaultModifiers = assignedConfig.defaultModifiers.filter((modifier) => {
      return modifierClasses.includes(modifier);
    });

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "div",
      className: this._selectors.block,
      contents: [],
    };

    // init button div options
    const buttonOptions = {
      tag: "div",
      class: "paris-button " + this._selectors.button,
      contents: [
        {
          tag: "span",
          class: "paris-icon " + this._selectors.icon,
          innerHTML: ButtonBlock.icons.arrowRight,
        },
        {
          tag: "input",
          type: "text",
          value: blockData.text,
          placeholder: this.config.textPlaceholder,
          className: "paris-button-text " + this._selectors.text,
          events: {
            keydown: this._disableEnter.bind(this),
          },
        },
      ],
    };

    // update according to modifiers
    if (_isArray(blockData.modifiers)) {
      buttonOptions.class += " " + blockData.modifiers.join(" ");
    }

    // update according to icon
    if (_isString(blockData.icon) && blockData.icon !== "") {
      buttonOptions.class += " has-icon";
    }

    // init href input options
    const hrefInputClasses = [this.api.styles.input, this._selectors.href];

    // if external url add specific class
    if (isExternalUrl(blockData.href)) {
      hrefInputClasses.push(this._selectors.href + "--external");
    }

    const hrefInputOptions = {
      tag: "input",
      type: "text",
      value: blockData.href,
      placeholder: this.config.hrefPlaceholder,
      className: hrefInputClasses.join(" "),
      events: {
        keydown: this._disableEnter.bind(this),
        input: _debounce(this._toggleExternal.bind(this), 300),
      },
    };

    if (this.readOnly) {
      buttonOptions.contents[1].readonly = true;
      hrefInputOptions.readonly = true;
    }

    blockElemOptions.contents.push(buttonOptions);
    blockElemOptions.contents.push(hrefInputOptions);

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _buildSettingsElem() {
    const settingsOptions = {
      tag: "div",
      class: this._selectors.settings,
      contents: [],
    };

    // add modifier options
    this.modifiers.forEach((modifier) => {
      const modifierClasses = [this.api.styles.settingsButton, this._selectors.modifierSettings];

      if (modifier.type === "style") {
        modifierClasses.push(this._selectors.modifierSettings + "--style");
      }

      const modifierOptions = {
        tag: "span",
        class: modifierClasses.join(" "),
        "data-tooltip": "bottom",
        "aria-label": modifier.title,
        "data-modifier": modifier.class,
        innerHTML: modifier.html,
        events: {
          click: (event) => {
            this._toggleModifier(event.target, modifier);
          },
        },
      };

      if (this.currentModifiers.includes(modifier.class)) {
        modifierOptions.class += " " + this.api.styles.settingsButtonActive;
      }

      settingsOptions.contents.push(modifierOptions);
    });

    return Bliss.create(settingsOptions);
  }

  _toggleModifier(target, modifier) {
    if (!modifier) {
      return;
    }

    const buttonElem = this._blockElem.querySelector("." + this._selectors.button);

    if (modifier.type === "style") {
      const settingsElem = target.closest("." + this._selectors.settings);
      const settingsArray = Bliss.$("." + this._selectors.modifierSettings + "--style", settingsElem);

      settingsArray.forEach((settingElem) => {
        const isFormat = settingElem.dataset.modifier === modifier.class;
        settingElem.classList.toggle(this.api.styles.settingsButtonActive, isFormat);
        buttonElem.classList.toggle(settingElem.dataset.modifier, isFormat);
      });
    } else {
      const settingElem = target.closest("." + this._selectors.modifierSettings);

      settingElem.classList.toggle(this.api.styles.settingsButtonActive);
      buttonElem.classList.toggle(modifier.class);
    }
  }

  _toggleExternal(event) {
    event.target.classList.toggle(this._selectors.href + "--external", isExternalUrl(event.target.value));
  }

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
