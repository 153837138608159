module.exports = [
  "air",
  "ami",
  "art",
  "aux",
  "axe",
  "axé",
  "bar",
  "bas",
  "bat",
  "bel",
  "bon",
  "bus",
  "but",
  "cap",
  "car",
  "cas",
  "ces",
  "cet",
  "clé",
  "col",
  "com",
  "cou",
  "cri",
  "cru",
  "del",
  "den",
  "der",
  "des",
  "dés",
  "dis",
  "dit",
  "dix",
  "don",
  "dos",
  "due",
  "duo",
  "dur",
  "dus",
  "eau",
  "écu",
  "élu",
  "est",
  "etc",
  "été",
  "eut",
  "eux",
  "fax",
  "fer",
  "feu",
  "fil",
  "fin",
  "fit",
  "foi",
  "for",
  "fou",
  "fur",
  "fut",
  "gaz",
  "gel",
  "gré",
  "hoc",
  "ici",
  "ils",
  "ira",
  "jet",
  "jeu",
  "job",
  "jus",
  "kit",
  "lac",
  "les",
  "lie",
  "lié",
  "lin",
  "lit",
  "loi",
  "lot",
  "lui",
  "mai",
  "mal",
  "mer",
  "mes",
  "met",
  "min",
  "mis",
  "moi",
  "mon",
  "mot",
  "mur",
  "née",
  "nés",
  "net",
  "nez",
  "nie",
  "nom",
  "non",
  "nos",
  "nul",
  "ont",
  "ose",
  "osé",
  "oui",
  "out",
  "par",
  "pas",
  "peu",
  "pis",
  "pop",
  "pot",
  "pro",
  "pub",
  "pur",
  "put",
  "que",
  "qui",
  "quo",
  "ris",
  "riz",
  "roi",
  "rue",
  "sac",
  "sec",
  "sel",
  "ses",
  "six",
  "ski",
  "soi",
  "sol",
  "son",
  "sud",
  "sur",
  "sus",
  "tas",
  "tel",
  "tél",
  "the",
  "thé",
  "tir",
  "ton",
  "top",
  "tri",
  "une",
  "uns",
  "van",
  "via",
  "vie",
  "vif",
  "vin",
  "vis",
  "vit",
  "vol",
  "von",
  "vos",
  "vue",
  "yen",
  "abus",
  "aces",
  "acte",
  "afin",
  "agio",
  "agir",
  "agis",
  "agit",
  "aida",
  "aide",
  "aidé",
  "aies",
  "aigu",
  "aile",
  "aima",
  "aime",
  "aimé",
  "aine",
  "aire",
  "airs",
  "aise",
  "aisé",
  "alfa",
  "alla",
  "allé",
  "aloi",
  "alto",
  "amas",
  "amer",
  "amie",
  "amis",
  "ange",
  "anis",
  "anse",
  "apte",
  "arcs",
  "ardu",
  "ares",
  "aria",
  "arma",
  "arme",
  "arts",
  "asbl",
  "aube",
  "auge",
  "aune",
  "aura",
  "auto",
  "aval",
  "avec",
  "aveu",
  "avez",
  "avis",
  "axée",
  "axer",
  "axes",
  "axez",
  "ayez",
  "azur",
  "baba",
  "bacs",
  "baie",
  "bail",
  "bain",
  "bais",
  "bals",
  "banc",
  "bang",
  "bans",
  "bars",
  "basa",
  "base",
  "basé",
  "bats",
  "baux",
  "bava",
  "bave",
  "beau",
  "bébé",
  "becs",
  "bien",
  "bile",
  "bise",
  "bits",
  "blet",
  "bleu",
  "bloc",
  "boas",
  "bobs",
  "bock",
  "bois",
  "boit",
  "bols",
  "bond",
  "boni",
  "bons",
  "boom",
  "bord",
  "bots",
  "bouc",
  "boue",
  "bous",
  "bout",
  "boxa",
  "boxe",
  "boys",
  "bras",
  "bref",
  "bric",
  "brie",
  "brin",
  "brio",
  "bris",
  "broc",
  "brou",
  "brun",
  "brus",
  "brut",
  "bues",
  "buis",
  "bure",
  "buse",
  "buta",
  "bute",
  "buts",
  "cade",
  "cadi",
  "café",
  "cage",
  "cake",
  "cala",
  "cale",
  "call",
  "cals",
  "came",
  "camp",
  "cane",
  "cape",
  "caps",
  "cars",
  "casa",
  "case",
  "cash",
  "cave",
  "ceci",
  "cédé",
  "cela",
  "cens",
  "cent",
  "ceps",
  "cerf",
  "ceux",
  "chah",
  "chai",
  "char",
  "chas",
  "chat",
  "chef",
  "cher",
  "chez",
  "chic",
  "choc",
  "chou",
  "chut",
  "ciel",
  "cils",
  "cime",
  "cinq",
  "cira",
  "cire",
  "cita",
  "cite",
  "cité",
  "clam",
  "clan",
  "clef",
  "clés",
  "clin",
  "clip",
  "clos",
  "clou",
  "club",
  "coco",
  "coda",
  "code",
  "coin",
  "cois",
  "cola",
  "cols",
  "colt",
  "coma",
  "coqs",
  "cors",
  "cosy",
  "cota",
  "cote",
  "coté",
  "coud",
  "coup",
  "cour",
  "cous",
  "cran",
  "crée",
  "créé",
  "cria",
  "cric",
  "crie",
  "crin",
  "cris",
  "croc",
  "crue",
  "crus",
  "crut",
  "cuba",
  "cube",
  "cuir",
  "cuis",
  "cuit",
  "cure",
  "cuti",
  "cuva",
  "cuve",
  "dada",
  "daim",
  "dais",
  "dame",
  "dans",
  "dard",
  "data",
  "date",
  "déco",
  "défi",
  "demi",
  "dent",
  "deux",
  "dira",
  "dire",
  "dise",
  "dite",
  "dito",
  "dits",
  "dock",
  "dodu",
  "dois",
  "doit",
  "donc",
  "dons",
  "dont",
  "dopa",
  "dope",
  "dora",
  "dore",
  "doré",
  "dors",
  "dort",
  "dosa",
  "dose",
  "dota",
  "dote",
  "doté",
  "dots",
  "doux",
  "drap",
  "drop",
  "drue",
  "drus",
  "ducs",
  "duel",
  "dues",
  "dune",
  "duos",
  "dupa",
  "dupe",
  "dura",
  "dure",
  "duré",
  "durs",
  "eaux",
  "égal",
  "élan",
  "elfe",
  "elle",
  "émet",
  "émis",
  "ergs",
  "erra",
  "erre",
  "esse",
  "état",
  "eues",
  "euro",
  "exil",
  "face",
  "fade",
  "faim",
  "fais",
  "fait",
  "fana",
  "fane",
  "fans",
  "faon",
  "fard",
  "fars",
  "fart",
  "fats",
  "faut",
  "faux",
  "fend",
  "fera",
  "fers",
  "feue",
  "feux",
  "fief",
  "fiel",
  "fier",
  "fies",
  "fiez",
  "fige",
  "fila",
  "file",
  "film",
  "fils",
  "fine",
  "fini",
  "fins",
  "fisc",
  "fixa",
  "fixe",
  "fixé",
  "flan",
  "flot",
  "flou",
  "flux",
  "focs",
  "foie",
  "foin",
  "fois",
  "fond",
  "font",
  "foot",
  "fora",
  "fore",
  "fors",
  "fort",
  "four",
  "fous",
  "frac",
  "frai",
  "fret",
  "fris",
  "frit",
  "fuel",
  "fuie",
  "fuir",
  "fuis",
  "fuit",
  "full",
  "fuma",
  "fume",
  "fumé",
  "fusa",
  "fuse",
  "gage",
  "gags",
  "gaie",
  "gain",
  "gais",
  "gala",
  "gale",
  "gang",
  "gant",
  "gara",
  "gare",
  "gars",
  "gava",
  "gave",
  "gaze",
  "geai",
  "gela",
  "gels",
  "gens",
  "géré",
  "gins",
  "girl",
  "glas",
  "glus",
  "gnon",
  "gnou",
  "goal",
  "goba",
  "gobe",
  "golf",
  "gond",
  "gong",
  "gras",
  "grec",
  "gril",
  "gris",
  "grog",
  "gros",
  "grue",
  "guet",
  "guis",
  "haie",
  "hais",
  "hait",
  "hall",
  "halo",
  "haro",
  "hase",
  "haut",
  "hein",
  "heur",
  "hier",
  "hies",
  "home",
  "hors",
  "houe",
  "houx",
  "http",
  "huer",
  "hues",
  "huez",
  "huis",
  "huit",
  "huma",
  "hume",
  "hune",
  "ibis",
  "idée",
  "idem",
  "ides",
  "imbu",
  "inca",
  "indu",
  "inox",
  "insu",
  "iode",
  "ions",
  "iota",
  "irai",
  "iras",
  "irez",
  "iris",
  "isba",
  "issu",
  "item",
  "ivre",
  "jade",
  "jais",
  "jars",
  "jasa",
  "jase",
  "java",
  "jazz",
  "jean",
  "jerk",
  "jeta",
  "jeté",
  "jets",
  "jeun",
  "jeux",
  "jobs",
  "joie",
  "joli",
  "jonc",
  "joua",
  "joue",
  "joué",
  "joug",
  "jour",
  "judo",
  "juge",
  "jugé",
  "juin",
  "jupe",
  "jura",
  "jure",
  "jury",
  "jute",
  "kaki",
  "kart",
  "kilo",
  "kilt",
  "kits",
  "kiwi",
  "kola",
  "krak",
  "kvas",
  "kwas",
  "lace",
  "lacs",
  "lads",
  "laid",
  "laie",
  "lait",
  "lama",
  "lame",
  "land",
  "laps",
  "lard",
  "lava",
  "lave",
  "legs",
  "lent",
  "lest",
  "leur",
  "leva",
  "levé",
  "lice",
  "lied",
  "liée",
  "lien",
  "lier",
  "lies",
  "liés",
  "lieu",
  "liez",
  "lift",
  "lima",
  "lime",
  "lino",
  "lion",
  "lira",
  "lire",
  "lise",
  "lits",
  "lobe",
  "lobs",
  "loge",
  "logo",
  "loin",
  "loir",
  "lois",
  "long",
  "look",
  "lors",
  "lote",
  "loti",
  "loto",
  "lots",
  "loua",
  "loue",
  "loué",
  "loup",
  "lues",
  "luge",
  "luit",
  "lump",
  "lune",
  "luth",
  "luxa",
  "luxe",
  "lynx",
  "lyre",
  "lyse",
  "mach",
  "made",
  "mage",
  "main",
  "mais",
  "malt",
  "marc",
  "mare",
  "mari",
  "mark",
  "mars",
  "mate",
  "math",
  "mats",
  "maux",
  "maxi",
  "maya",
  "mena",
  "mené",
  "mens",
  "ment",
  "menu",
  "mers",
  "mess",
  "mets",
  "meus",
  "meut",
  "mica",
  "midi",
  "miel",
  "mien",
  "mile",
  "mima",
  "mime",
  "mina",
  "mine",
  "mira",
  "mire",
  "misa",
  "mise",
  "miss",
  "mita",
  "mite",
  "mixa",
  "mixe",
  "mode",
  "modo",
  "mois",
  "moka",
  "mont",
  "mord",
  "mors",
  "mort",
  "moto",
  "mots",
  "moud",
  "moue",
  "mous",
  "muer",
  "mues",
  "muet",
  "muez",
  "mugi",
  "mule",
  "muni",
  "mura",
  "mure",
  "murs",
  "musc",
  "muse",
  "must",
  "muta",
  "mute",
  "nage",
  "nain",
  "nais",
  "naja",
  "ndlr",
  "nefs",
  "nerf",
  "nets",
  "neuf",
  "nids",
  "nier",
  "nies",
  "niez",
  "noce",
  "noie",
  "noix",
  "noms",
  "nord",
  "nota",
  "note",
  "noua",
  "noue",
  "nous",
  "noya",
  "nues",
  "nuis",
  "nuit",
  "nuls",
  "obus",
  "ocre",
  "odes",
  "oeil",
  "oeuf",
  "ogre",
  "ohms",
  "oies",
  "omet",
  "omis",
  "omit",
  "once",
  "onde",
  "onyx",
  "onze",
  "opta",
  "opte",
  "opté",
  "opus",
  "oral",
  "orcs",
  "ores",
  "orge",
  "orme",
  "orna",
  "orne",
  "oser",
  "oses",
  "osez",
  "oued",
  "ours",
  "ovni",
  "pack",
  "page",
  "paie",
  "pain",
  "pair",
  "paix",
  "pale",
  "pals",
  "pans",
  "paon",
  "papa",
  "para",
  "parc",
  "pare",
  "pari",
  "pars",
  "part",
  "paru",
  "pava",
  "pave",
  "pavé",
  "paya",
  "paye",
  "payé",
  "pays",
  "peau",
  "pela",
  "pend",
  "perd",
  "pers",
  "pesa",
  "pesé",
  "peso",
  "peur",
  "peut",
  "peux",
  "pics",
  "pied",
  "pies",
  "pieu",
  "pige",
  "pila",
  "pile",
  "pins",
  "pion",
  "pire",
  "plan",
  "plat",
  "plia",
  "plie",
  "plis",
  "plot",
  "plus",
  "plut",
  "pneu",
  "poil",
  "pois",
  "poix",
  "poli",
  "polo",
  "pond",
  "pont",
  "pool",
  "pope",
  "porc",
  "pore",
  "port",
  "posa",
  "pose",
  "posé",
  "pote",
  "pots",
  "pouf",
  "pour",
  "poux",
  "pria",
  "prie",
  "pris",
  "prit",
  "prix",
  "pubs",
  "puce",
  "puer",
  "puis",
  "pull",
  "puma",
  "puni",
  "pure",
  "purs",
  "puts",
  "qqun",
  "quai",
  "quel",
  "quoi",
  "race",
  "rade",
  "rage",
  "raid",
  "raie",
  "rail",
  "rama",
  "rame",
  "rami",
  "rang",
  "rare",
  "rasa",
  "rase",
  "rata",
  "rate",
  "raté",
  "rats",
  "rave",
  "ravi",
  "raya",
  "raye",
  "réel",
  "regs",
  "rein",
  "relu",
  "rend",
  "repu",
  "revu",
  "rhum",
  "rida",
  "ride",
  "rien",
  "ries",
  "riez",
  "rima",
  "rime",
  "ring",
  "rira",
  "rire",
  "rite",
  "riva",
  "rive",
  "rixe",
  "robe",
  "rock",
  "rocs",
  "roda",
  "rode",
  "rois",
  "roll",
  "rond",
  "rose",
  "rosé",
  "rosi",
  "rots",
  "roue",
  "roux",
  "rude",
  "ruer",
  "rues",
  "ruez",
  "rugi",
  "rusa",
  "ruse",
  "rush",
  "ruts",
  "sacs",
  "saga",
  "sage",
  "sain",
  "sais",
  "sait",
  "sala",
  "sale",
  "sali",
  "sana",
  "sang",
  "sans",
  "sapa",
  "sape",
  "sari",
  "sauf",
  "saur",
  "saut",
  "saxo",
  "scia",
  "scie",
  "seau",
  "secs",
  "self",
  "sels",
  "sema",
  "sens",
  "sent",
  "sept",
  "sera",
  "serf",
  "sers",
  "sert",
  "sets",
  "seul",
  "shah",
  "show",
  "sied",
  "sien",
  "silo",
  "sire",
  "sise",
  "site",
  "skia",
  "skie",
  "skis",
  "slip",
  "slow",
  "snob",
  "socs",
  "soda",
  "sofa",
  "soft",
  "soie",
  "soif",
  "soin",
  "soir",
  "sois",
  "soit",
  "soja",
  "sole",
  "soli",
  "solo",
  "sols",
  "sono",
  "sons",
  "sont",
  "sors",
  "sort",
  "sots",
  "souk",
  "sous",
  "soya",
  "spot",
  "star",
  "stem",
  "stop",
  "stuc",
  "subi",
  "sucs",
  "suer",
  "sues",
  "suez",
  "suie",
  "suif",
  "suis",
  "suit",
  "sure",
  "surf",
  "surs",
  "swap",
  "tact",
  "taie",
  "tain",
  "tais",
  "tait",
  "talc",
  "tank",
  "tant",
  "taon",
  "tapa",
  "tape",
  "tapi",
  "tard",
  "tare",
  "tari",
  "taux",
  "taxa",
  "taxe",
  "taxi",
  "teck",
  "teks",
  "télé",
  "tels",
  "tend",
  "tenu",
  "test",
  "thon",
  "thym",
  "tics",
  "tien",
  "tige",
  "tilt",
  "tint",
  "tira",
  "tire",
  "tiré",
  "tirs",
  "tocs",
  "toge",
  "toit",
  "tome",
  "tond",
  "tons",
  "tops",
  "tord",
  "tors",
  "tort",
  "tour",
  "tous",
  "tout",
  "toux",
  "trac",
  "tram",
  "tria",
  "trie",
  "trim",
  "trio",
  "tris",
  "troc",
  "trop",
  "trot",
  "trou",
  "truc",
  "tsar",
  "tuba",
  "tube",
  "tues",
  "tufs",
  "turc",
  "turf",
  "type",
  "typo",
  "ubac",
  "unes",
  "unie",
  "unir",
  "unis",
  "unit",
  "urge",
  "urne",
  "user",
  "uses",
  "usez",
  "vain",
  "vais",
  "vals",
  "valu",
  "vamp",
  "vans",
  "vase",
  "vaut",
  "vaux",
  "veau",
  "vécu",
  "vélo",
  "velu",
  "vend",
  "vent",
  "venu",
  "vers",
  "vert",
  "veto",
  "veuf",
  "veut",
  "veux",
  "vexa",
  "vexe",
  "vice",
  "vida",
  "vide",
  "vies",
  "vifs",
  "vile",
  "vils",
  "vins",
  "vint",
  "vira",
  "vire",
  "visa",
  "vise",
  "visé",
  "vite",
  "vive",
  "voeu",
  "voie",
  "voir",
  "vois",
  "voit",
  "voix",
  "vola",
  "vole",
  "vols",
  "volt",
  "vont",
  "voor",
  "vota",
  "vote",
  "voté",
  "voua",
  "voue",
  "vous",
  "vrac",
  "vrai",
  "vues",
  "watt",
  "yack",
  "yaks",
  "yard",
  "yens",
  "yeux",
  "yoga",
  "zéro",
  "zinc",
  "zona",
  "zone",
  "zoom",
  "zoos",
  "abusa",
  "abuse",
  "accru",
  "achat",
  "acide",
  "acier",
  "actes",
  "actif",
  "adage",
  "adieu",
  "admet",
  "admis",
  "admit",
  "adora",
  "adore",
  "aequo",
  "agace",
  "agate",
  "agent",
  "agile",
  "agios",
  "agira",
  "agita",
  "agite",
  "agréé",
  "ahuri",
  "aider",
  "aides",
  "aidez",
  "aient",
  "aigle",
  "aigre",
  "aigri",
  "aigus",
  "ailes",
  "aille",
  "aimer",
  "aimes",
  "aimez",
  "aines",
  "ainsi",
  "airer",
  "aires",
  "aisée",
  "aises",
  "ajonc",
  "ajout",
  "album",
  "alfas",
  "algue",
  "alias",
  "alibi",
  "aller",
  "allez",
  "allia",
  "allie",
  "alors",
  "alpin",
  "alter",
  "altos",
  "amant",
  "amble",
  "ambre",
  "amena",
  "amené",
  "amers",
  "amibe",
  "amies",
  "amont",
  "amour",
  "amphi",
  "ample",
  "ampli",
  "amusa",
  "amuse",
  "anche",
  "ancra",
  "ancre",
  "anges",
  "angle",
  "anima",
  "anime",
  "animé",
  "année",
  "anode",
  "anses",
  "antan",
  "antre",
  "aorte",
  "aphte",
  "appas",
  "appel",
  "appui",
  "aptes",
  "apura",
  "apure",
  "arbre",
  "arche",
  "ardue",
  "ardus",
  "argot",
  "arias",
  "aride",
  "armée",
  "armer",
  "armes",
  "armez",
  "asile",
  "aspic",
  "assez",
  "assis",
  "assit",
  "astre",
  "atlas",
  "atoll",
  "atome",
  "atone",
  "atout",
  "aucun",
  "audio",
  "audit",
  "auges",
  "aunes",
  "aurai",
  "auras",
  "aurez",
  "aussi",
  "autel",
  "autos",
  "autre",
  "avais",
  "avait",
  "avala",
  "avale",
  "avals",
  "avant",
  "avare",
  "avenu",
  "avéré",
  "aveux",
  "avide",
  "aviez",
  "avili",
  "avion",
  "avisa",
  "avise",
  "aviso",
  "aviva",
  "avive",
  "avoir",
  "avons",
  "avoua",
  "avoue",
  "avoué",
  "avril",
  "axais",
  "axait",
  "axant",
  "axent",
  "axera",
  "axiez",
  "axons",
  "ayant",
  "ayons",
  "azote",
  "azyme",
  "babas",
  "babil",
  "bacon",
  "badge",
  "badin",
  "bagne",
  "bagou",
  "bague",
  "bahut",
  "baies",
  "bains",
  "balai",
  "balle",
  "balsa",
  "banal",
  "banco",
  "bancs",
  "bande",
  "bangs",
  "banjo",
  "banni",
  "barbe",
  "barda",
  "barde",
  "barge",
  "baril",
  "baron",
  "barra",
  "barre",
  "basée",
  "baser",
  "bases",
  "basés",
  "basez",
  "basse",
  "batte",
  "battu",
  "bauge",
  "baume",
  "baver",
  "baves",
  "bavez",
  "bayer",
  "bazar",
  "beaux",
  "bébés",
  "beige",
  "belge",
  "belle",
  "belon",
  "benne",
  "berce",
  "berge",
  "berna",
  "berne",
  "béton",
  "bette",
  "biais",
  "biche",
  "bidet",
  "bidon",
  "biens",
  "biffa",
  "biffe",
  "bijou",
  "bilan",
  "biler",
  "bille",
  "biner",
  "biser",
  "bises",
  "bison",
  "blets",
  "bleue",
  "bleui",
  "bleus",
  "blocs",
  "blond",
  "blues",
  "bluff",
  "bocal",
  "bocks",
  "boeuf",
  "bogue",
  "boira",
  "boire",
  "boita",
  "boite",
  "boive",
  "bolet",
  "bombe",
  "bonde",
  "bondi",
  "bonds",
  "bonis",
  "bonne",
  "bonus",
  "bonze",
  "boots",
  "borda",
  "borde",
  "bords",
  "borna",
  "borne",
  "bosse",
  "bossu",
  "botte",
  "boucs",
  "bouda",
  "boude",
  "boues",
  "bouge",
  "boule",
  "bourg",
  "bouse",
  "bouts",
  "bovin",
  "boxer",
  "boxes",
  "boxez",
  "boyau",
  "brada",
  "brade",
  "brame",
  "brava",
  "brave",
  "bravo",
  "break",
  "brefs",
  "brick",
  "brida",
  "bride",
  "brima",
  "brime",
  "brins",
  "brisa",
  "brise",
  "brocs",
  "broda",
  "brode",
  "broie",
  "broya",
  "bruir",
  "bruit",
  "brume",
  "brune",
  "bruni",
  "bruns",
  "brute",
  "bruts",
  "buggy",
  "bulbe",
  "bulle",
  "burin",
  "buses",
  "buste",
  "buter",
  "butes",
  "butez",
  "butin",
  "butor",
  "butte",
  "buvez",
  "caban",
  "cabas",
  "cabra",
  "cabre",
  "cabri",
  "cacao",
  "cacha",
  "cache",
  "caché",
  "cades",
  "cadet",
  "cadis",
  "cadra",
  "cadre",
  "caduc",
  "cafés",
  "cages",
  "cahot",
  "cajou",
  "cajun",
  "cakes",
  "caler",
  "cales",
  "calez",
  "calls",
  "calma",
  "calme",
  "calot",
  "calva",
  "camer",
  "cames",
  "campa",
  "campe",
  "camps",
  "camus",
  "canal",
  "candi",
  "caner",
  "canes",
  "canif",
  "canna",
  "canne",
  "canon",
  "canot",
  "caper",
  "capes",
  "capot",
  "capta",
  "capte",
  "carat",
  "carde",
  "cargo",
  "caria",
  "carie",
  "carpe",
  "carre",
  "carré",
  "carte",
  "caser",
  "cases",
  "casez",
  "cassa",
  "casse",
  "caste",
  "catch",
  "catir",
  "causa",
  "cause",
  "causé",
  "caver",
  "caves",
  "céder",
  "cedex",
  "ceint",
  "celer",
  "celle",
  "celui",
  "censé",
  "cents",
  "cerfs",
  "cerna",
  "cerne",
  "cessa",
  "cesse",
  "cessé",
  "cette",
  "chahs",
  "chair",
  "chais",
  "champ",
  "chant",
  "chaos",
  "chape",
  "chars",
  "chats",
  "chaud",
  "chaux",
  "check",
  "chefs",
  "chers",
  "chien",
  "chiot",
  "chips",
  "chocs",
  "choie",
  "choir",
  "choix",
  "chope",
  "chose",
  "choux",
  "choya",
  "chuta",
  "chute",
  "chuté",
  "cible",
  "cidre",
  "cieux",
  "cimes",
  "cirer",
  "cires",
  "cirez",
  "citer",
  "cites",
  "cités",
  "citez",
  "civet",
  "civil",
  "claie",
  "clair",
  "clama",
  "clame",
  "clams",
  "clans",
  "clefs",
  "clerc",
  "clins",
  "clips",
  "clone",
  "clora",
  "clore",
  "close",
  "cloua",
  "cloue",
  "clous",
  "clown",
  "clubs",
  "cobol",
  "cobra",
  "cocha",
  "coche",
  "cocon",
  "codas",
  "coder",
  "codes",
  "codez",
  "coeur",
  "cogna",
  "cogne",
  "cohue",
  "coing",
  "coins",
  "coite",
  "colin",
  "colis",
  "colla",
  "colle",
  "colts",
  "colza",
  "comas",
  "comme",
  "comte",
  "congé",
  "connu",
  "conte",
  "copia",
  "copie",
  "coque",
  "corde",
  "corne",
  "cornu",
  "coron",
  "corps",
  "corsa",
  "corse",
  "cosse",
  "cossu",
  "cosys",
  "cotée",
  "coter",
  "cotes",
  "cotés",
  "cotez",
  "cotir",
  "coton",
  "cotte",
  "couac",
  "coude",
  "couds",
  "coula",
  "coule",
  "coupa",
  "coupe",
  "coupé",
  "coups",
  "coure",
  "cours",
  "court",
  "couru",
  "couse",
  "cousu",
  "couva",
  "couve",
  "crabe",
  "craie",
  "crans",
  "crawl",
  "credo",
  "créée",
  "créer",
  "créés",
  "creux",
  "creva",
  "crics",
  "crier",
  "cries",
  "criez",
  "crime",
  "crins",
  "crise",
  "crocs",
  "croie",
  "crois",
  "croit",
  "croix",
  "cross",
  "cruel",
  "crues",
  "cuber",
  "cubes",
  "cuira",
  "cuire",
  "cuirs",
  "cuise",
  "cuite",
  "cuits",
  "culer",
  "culot",
  "culte",
  "cumin",
  "cumul",
  "curer",
  "cures",
  "curry",
  "cuvée",
  "cuver",
  "cuves",
  "cuvez",
  "cycle",
  "cygne",
  "dadas",
  "dague",
  "daims",
  "dalla",
  "dalle",
  "damer",
  "dames",
  "damna",
  "damne",
  "dandy",
  "dansa",
  "danse",
  "darce",
  "dards",
  "darne",
  "darse",
  "dater",
  "dates",
  "datez",
  "datif",
  "datte",
  "daube",
  "débat",
  "débit",
  "début",
  "décor",
  "dédié",
  "défis",
  "degré",
  "délai",
  "délit",
  "delta",
  "demie",
  "demis",
  "dense",
  "dents",
  "dépit",
  "derby",
  "derme",
  "désir",
  "dette",
  "deuil",
  "devez",
  "devin",
  "devis",
  "devra",
  "diapo",
  "dicta",
  "dicte",
  "digit",
  "digne",
  "digue",
  "dilua",
  "dilue",
  "dinde",
  "dingo",
  "diode",
  "dirai",
  "diras",
  "dires",
  "direz",
  "disco",
  "dises",
  "dites",
  "divan",
  "docks",
  "docte",
  "dodue",
  "dodus",
  "dogme",
  "dogue",
  "doigt",
  "doive",
  "donna",
  "donne",
  "donné",
  "doper",
  "dopes",
  "dopez",
  "dorer",
  "dorme",
  "dormi",
  "doser",
  "doses",
  "dosez",
  "dotée",
  "doter",
  "dotes",
  "dotés",
  "dotez",
  "douce",
  "douer",
  "douta",
  "doute",
  "douve",
  "douze",
  "doyen",
  "drain",
  "drame",
  "drapa",
  "drape",
  "draps",
  "droit",
  "drops",
  "drues",
  "ducal",
  "duels",
  "dunes",
  "duper",
  "dupes",
  "dupez",
  "durci",
  "durée",
  "durer",
  "dures",
  "duvet",
  "écart",
  "échec",
  "éclat",
  "école",
  "écran",
  "écrin",
  "écrit",
  "édité",
  "effet",
  "égale",
  "égard",
  "eider",
  "élevé",
  "elfes",
  "elles",
  "émane",
  "émise",
  "empan",
  "empli",
  "encan",
  "encas",
  "encra",
  "encre",
  "endos",
  "enfer",
  "enfin",
  "enfla",
  "enfle",
  "enfui",
  "engin",
  "enjeu",
  "ennui",
  "enter",
  "entra",
  "entre",
  "entré",
  "envia",
  "envie",
  "envoi",
  "envol",
  "épais",
  "époux",
  "ergot",
  "errer",
  "erres",
  "errez",
  "esche",
  "essai",
  "esses",
  "essor",
  "ester",
  "estoc",
  "étage",
  "était",
  "étant",
  "étape",
  "états",
  "étude",
  "euros",
  "évite",
  "exact",
  "exclu",
  "exige",
  "exigé",
  "exigu",
  "exila",
  "exile",
  "exils",
  "exode",
  "expia",
  "expie",
  "extra",
  "fable",
  "faces",
  "facto",
  "fades",
  "fagot",
  "faire",
  "faite",
  "faits",
  "fakir",
  "fallu",
  "falot",
  "fanal",
  "faner",
  "fanes",
  "fange",
  "fanon",
  "faons",
  "farce",
  "farci",
  "farda",
  "farde",
  "fards",
  "farts",
  "fasse",
  "faste",
  "fatal",
  "faune",
  "faute",
  "fauve",
  "faxer",
  "feins",
  "feint",
  "femme",
  "fende",
  "fends",
  "fendu",
  "fente",
  "ferai",
  "feras",
  "ferez",
  "ferma",
  "ferme",
  "fermé",
  "feues",
  "fiais",
  "fiait",
  "fiant",
  "fibre",
  "ficha",
  "fiche",
  "fichu",
  "fiefs",
  "fient",
  "fiera",
  "fiers",
  "fifre",
  "figea",
  "figer",
  "figes",
  "figez",
  "figue",
  "fiiez",
  "filer",
  "files",
  "filet",
  "filez",
  "filin",
  "fille",
  "filma",
  "filme",
  "films",
  "filon",
  "filou",
  "final",
  "fines",
  "finie",
  "finir",
  "finis",
  "finit",
  "fiole",
  "firme",
  "fiscs",
  "fixée",
  "fixer",
  "fixes",
  "fixés",
  "fixez",
  "fjord",
  "flair",
  "flanc",
  "flans",
  "flapi",
  "flash",
  "fleur",
  "flirt",
  "flood",
  "flore",
  "flots",
  "floua",
  "floue",
  "flous",
  "fluer",
  "fluet",
  "fluor",
  "focal",
  "focus",
  "foehn",
  "foies",
  "foins",
  "foire",
  "folie",
  "folio",
  "folle",
  "fonce",
  "fonda",
  "fonde",
  "fondé",
  "fonds",
  "fondu",
  "fonte",
  "fonts",
  "force",
  "forcé",
  "forer",
  "fores",
  "foret",
  "forez",
  "forge",
  "forgé",
  "forma",
  "forme",
  "formé",
  "forte",
  "forts",
  "forum",
  "fosse",
  "fossé",
  "fouet",
  "fouir",
  "foula",
  "foule",
  "fours",
  "foutu",
  "foyer",
  "fracs",
  "fraie",
  "frais",
  "franc",
  "fraya",
  "fraye",
  "frein",
  "frets",
  "frigo",
  "frime",
  "fripa",
  "fripe",
  "frira",
  "frire",
  "frisa",
  "frise",
  "frite",
  "frits",
  "froid",
  "front",
  "fruit",
  "fucus",
  "fuels",
  "fugue",
  "fuies",
  "fuira",
  "fuite",
  "fulls",
  "fumée",
  "fumer",
  "fumes",
  "fumet",
  "fumez",
  "furet",
  "furia",
  "furie",
  "fuser",
  "fusil",
  "futur",
  "fuyez",
  "gaffe",
  "gagea",
  "gager",
  "gages",
  "gagez",
  "gagna",
  "gagne",
  "gagné",
  "gaies",
  "gaine",
  "gains",
  "galas",
  "galbe",
  "gales",
  "galet",
  "galon",
  "galop",
  "gamba",
  "gamet",
  "gamin",
  "gamme",
  "gangs",
  "ganse",
  "gants",
  "garce",
  "garda",
  "garde",
  "gardé",
  "garer",
  "gares",
  "garez",
  "garni",
  "gaule",
  "gaver",
  "gaves",
  "gavez",
  "gazer",
  "gazes",
  "gazon",
  "geais",
  "géant",
  "geins",
  "geint",
  "geler",
  "gelez",
  "gemme",
  "genet",
  "génie",
  "genou",
  "genre",
  "gerce",
  "gérée",
  "gérer",
  "gérés",
  "germa",
  "germe",
  "geste",
  "gibet",
  "gicla",
  "gicle",
  "gifla",
  "gifle",
  "gigot",
  "gigue",
  "gilet",
  "girls",
  "giron",
  "gitan",
  "givra",
  "givre",
  "glace",
  "glana",
  "gland",
  "glane",
  "globe",
  "glose",
  "gnome",
  "gnons",
  "gnous",
  "goals",
  "gober",
  "gobes",
  "gobez",
  "goder",
  "godet",
  "golfe",
  "golfs",
  "gomma",
  "gomme",
  "gonds",
  "gongs",
  "goret",
  "gorge",
  "gosse",
  "gouge",
  "goulu",
  "gourd",
  "grade",
  "grain",
  "grand",
  "grava",
  "grave",
  "gravi",
  "grecs",
  "grenu",
  "greva",
  "grief",
  "grill",
  "grils",
  "griot",
  "grisa",
  "grise",
  "grive",
  "grogs",
  "groin",
  "groom",
  "gruau",
  "grues",
  "grume",
  "guano",
  "guida",
  "guide",
  "guise",
  "gypse",
  "habit",
  "hacha",
  "hache",
  "haies",
  "haine",
  "haler",
  "halle",
  "halls",
  "halos",
  "halte",
  "hamac",
  "hampe",
  "hanta",
  "hante",
  "happa",
  "happe",
  "haras",
  "hardi",
  "harem",
  "haros",
  "harpe",
  "hases",
  "haute",
  "hauts",
  "haver",
  "havre",
  "hayon",
  "hélas",
  "herbe",
  "héros",
  "herse",
  "hertz",
  "heure",
  "heurt",
  "hibou",
  "hindi",
  "hippy",
  "hissa",
  "hisse",
  "hiver",
  "hobby",
  "hocha",
  "hoche",
  "homme",
  "honni",
  "honte",
  "horde",
  "hotte",
  "houer",
  "houes",
  "houle",
  "huais",
  "huait",
  "huant",
  "huche",
  "huent",
  "huera",
  "huiez",
  "huila",
  "huile",
  "humer",
  "humes",
  "humez",
  "humus",
  "hunes",
  "huons",
  "huppe",
  "hurla",
  "hurle",
  "hutte",
  "hydre",
  "hymen",
  "hymne",
  "iambe",
  "ictus",
  "idéal",
  "idées",
  "idiot",
  "idole",
  "igloo",
  "ilote",
  "image",
  "imbue",
  "imbus",
  "imita",
  "imite",
  "imper",
  "impie",
  "impur",
  "incas",
  "index",
  "indue",
  "indus",
  "input",
  "inter",
  "ioder",
  "iodes",
  "irais",
  "irait",
  "iriez",
  "irons",
  "iront",
  "isard",
  "isbas",
  "isola",
  "isole",
  "isolé",
  "issue",
  "issus",
  "ivres",
  "jabot",
  "jades",
  "jadis",
  "jalon",
  "jambe",
  "jante",
  "jarre",
  "jaser",
  "jatte",
  "jauge",
  "jauni",
  "javas",
  "jeans",
  "jerez",
  "jeter",
  "jetez",
  "jeton",
  "jette",
  "jeudi",
  "jeune",
  "joies",
  "joins",
  "joint",
  "joker",
  "jolie",
  "jolis",
  "joncs",
  "joual",
  "jouer",
  "joues",
  "jouet",
  "jouez",
  "jougs",
  "joule",
  "jours",
  "joute",
  "joyau",
  "judas",
  "jugea",
  "jugée",
  "juger",
  "juges",
  "jugés",
  "jugez",
  "junte",
  "jupes",
  "jupon",
  "jurer",
  "jures",
  "jurez",
  "juron",
  "jurys",
  "jusqu",
  "juste",
  "juter",
  "kakis",
  "kapok",
  "karma",
  "karts",
  "kayak",
  "kilos",
  "kilts",
  "kiwis",
  "kolas",
  "krach",
  "kraft",
  "kraks",
  "kyste",
  "label",
  "lacer",
  "laces",
  "lacet",
  "lacez",
  "lacis",
  "ladre",
  "lagon",
  "laide",
  "laids",
  "laies",
  "laine",
  "laits",
  "lamas",
  "lamer",
  "lames",
  "lampe",
  "lance",
  "lancé",
  "lande",
  "lange",
  "laper",
  "lapin",
  "lapis",
  "laque",
  "large",
  "larme",
  "larve",
  "laser",
  "lassa",
  "lasse",
  "lasso",
  "latex",
  "latin",
  "latte",
  "laver",
  "laves",
  "lavez",
  "lavis",
  "layer",
  "ledit",
  "légal",
  "léger",
  "lente",
  "lents",
  "lesta",
  "leste",
  "lests",
  "leurs",
  "levée",
  "lever",
  "levez",
  "liais",
  "liait",
  "liane",
  "liant",
  "libre",
  "licol",
  "licou",
  "lieds",
  "liées",
  "liens",
  "lient",
  "liera",
  "lieue",
  "lieux",
  "lifts",
  "ligne",
  "ligua",
  "ligue",
  "liiez",
  "lilas",
  "limer",
  "limes",
  "limez",
  "limon",
  "linge",
  "linos",
  "lions",
  "lippe",
  "lippu",
  "lirai",
  "liras",
  "lires",
  "lirez",
  "lises",
  "lisez",
  "lissa",
  "lisse",
  "lista",
  "liste",
  "liter",
  "litre",
  "livra",
  "livre",
  "livré",
  "lobby",
  "lober",
  "lobes",
  "local",
  "loden",
  "loess",
  "lofer",
  "logea",
  "loger",
  "loges",
  "logez",
  "logis",
  "logos",
  "loirs",
  "longe",
  "longs",
  "lopin",
  "loque",
  "lotes",
  "lotie",
  "lotir",
  "lotis",
  "lotit",
  "lotte",
  "lotus",
  "louer",
  "loues",
  "louez",
  "loupe",
  "loups",
  "lourd",
  "louve",
  "lover",
  "loyal",
  "loyer",
  "lubie",
  "lucre",
  "lueur",
  "luger",
  "luges",
  "luira",
  "luire",
  "luise",
  "lumps",
  "lunch",
  "lundi",
  "lunes",
  "lupin",
  "luron",
  "luter",
  "luths",
  "lutin",
  "lutta",
  "lutte",
  "luxer",
  "luxes",
  "luxez",
  "lyres",
  "lyses",
  "mafia",
  "mages",
  "magie",
  "magma",
  "magot",
  "mains",
  "maint",
  "maire",
  "major",
  "malin",
  "malle",
  "malus",
  "maman",
  "manda",
  "mande",
  "manga",
  "mange",
  "mania",
  "manie",
  "manne",
  "mante",
  "mardi",
  "mares",
  "marge",
  "maria",
  "marie",
  "marié",
  "marin",
  "maris",
  "marks",
  "marne",
  "marra",
  "marre",
  "marri",
  "marte",
  "massa",
  "masse",
  "match",
  "mater",
  "mates",
  "maths",
  "matin",
  "matir",
  "mauve",
  "mayas",
  "mazer",
  "média",
  "melon",
  "menée",
  "mener",
  "menez",
  "mente",
  "menti",
  "menue",
  "menus",
  "merci",
  "merle",
  "merlu",
  "métal",
  "métro",
  "mette",
  "meule",
  "meure",
  "meurs",
  "meurt",
  "meute",
  "meuve",
  "micas",
  "micro",
  "midis",
  "miels",
  "miens",
  "mieux",
  "migre",
  "milan",
  "miles",
  "mille",
  "mimer",
  "mimes",
  "mimez",
  "mince",
  "minci",
  "miner",
  "mines",
  "minet",
  "minez",
  "minus",
  "mirer",
  "mires",
  "mirez",
  "miser",
  "mises",
  "misez",
  "miter",
  "mites",
  "mitre",
  "mixer",
  "mixes",
  "mixez",
  "mixte",
  "moche",
  "modal",
  "modes",
  "modus",
  "moine",
  "moins",
  "moire",
  "moisi",
  "moite",
  "mokas",
  "molle",
  "molli",
  "momie",
  "monde",
  "money",
  "monta",
  "monte",
  "monté",
  "monts",
  "moqua",
  "moque",
  "moral",
  "morde",
  "mords",
  "mordu",
  "morne",
  "morse",
  "morte",
  "morts",
  "morue",
  "morve",
  "motel",
  "motif",
  "motos",
  "motte",
  "mouds",
  "moues",
  "moula",
  "moule",
  "moulu",
  "moyen",
  "moyeu",
  "muais",
  "muait",
  "muant",
  "muent",
  "muera",
  "muets",
  "mufle",
  "mugir",
  "mugis",
  "mugit",
  "muiez",
  "mules",
  "mulet",
  "mulot",
  "munie",
  "munir",
  "munis",
  "munit",
  "muons",
  "mural",
  "murer",
  "mures",
  "muret",
  "murez",
  "muscs",
  "musée",
  "muser",
  "muses",
  "muter",
  "mutes",
  "mutez",
  "mutin",
  "myope",
  "mythe",
  "nabab",
  "nabot",
  "nacre",
  "nagea",
  "nager",
  "nages",
  "nagez",
  "naine",
  "nains",
  "najas",
  "nanan",
  "nanti",
  "nappe",
  "narra",
  "narre",
  "nasse",
  "natal",
  "natif",
  "natte",
  "naval",
  "navet",
  "navra",
  "navre",
  "neige",
  "nenni",
  "nerfs",
  "nervi",
  "nette",
  "neufs",
  "neuve",
  "neveu",
  "niais",
  "niait",
  "niant",
  "nicha",
  "niche",
  "nient",
  "niera",
  "niiez",
  "ninja",
  "nions",
  "nippe",
  "noble",
  "noces",
  "nocif",
  "noeud",
  "noies",
  "noise",
  "nomma",
  "nomme",
  "nommé",
  "nonce",
  "nonne",
  "norme",
  "noter",
  "notes",
  "notez",
  "notre",
  "nouer",
  "noues",
  "nouez",
  "nover",
  "noyau",
  "noyer",
  "noyez",
  "nuage",
  "nuira",
  "nuire",
  "nuise",
  "nuits",
  "nulle",
  "nuque",
  "nurse",
  "nylon",
  "oasis",
  "obier",
  "objet",
  "obole",
  "obtus",
  "ocrer",
  "ocres",
  "octal",
  "octet",
  "odeur",
  "oeufs",
  "offre",
  "ogive",
  "ogres",
  "oisif",
  "oison",
  "okapi",
  "olive",
  "ombre",
  "omets",
  "omise",
  "onces",
  "oncle",
  "ondes",
  "ongle",
  "opale",
  "opéré",
  "opina",
  "opine",
  "opium",
  "opter",
  "optes",
  "optez",
  "orage",
  "orale",
  "oraux",
  "ordre",
  "orges",
  "orgue",
  "ormes",
  "orner",
  "ornes",
  "ornez",
  "orque",
  "ortie",
  "orvet",
  "osais",
  "osait",
  "osant",
  "oscar",
  "osent",
  "osera",
  "osier",
  "osiez",
  "osons",
  "otage",
  "otite",
  "ouate",
  "oubli",
  "oueds",
  "ouest",
  "ourdi",
  "ourse",
  "outil",
  "outra",
  "outre",
  "ouvre",
  "ovale",
  "ovine",
  "ovnis",
  "ovule",
  "oxyda",
  "oxyde",
  "ozone",
  "pacha",
  "packs",
  "pacte",
  "pages",
  "pagne",
  "paies",
  "pains",
  "paire",
  "pairs",
  "palan",
  "pales",
  "palet",
  "palme",
  "palot",
  "palpa",
  "palpe",
  "pampa",
  "panda",
  "panel",
  "paner",
  "panne",
  "pansa",
  "panse",
  "paons",
  "papal",
  "papas",
  "paras",
  "parce",
  "parcs",
  "parer",
  "pares",
  "parez",
  "paria",
  "parie",
  "paris",
  "parla",
  "parle",
  "parlé",
  "parmi",
  "paroi",
  "parte",
  "parti",
  "parts",
  "parue",
  "parus",
  "parut",
  "passa",
  "passe",
  "passé",
  "patin",
  "patio",
  "patte",
  "paume",
  "pause",
  "paver",
  "paves",
  "pavez",
  "pavot",
  "payée",
  "payer",
  "payes",
  "payés",
  "payez",
  "payse",
  "peaux",
  "peina",
  "peine",
  "peins",
  "peint",
  "peler",
  "pelez",
  "pelle",
  "pénal",
  "pence",
  "pende",
  "pends",
  "pendu",
  "pensa",
  "pense",
  "pensé",
  "pente",
  "perce",
  "perde",
  "perds",
  "perdu",
  "péril",
  "perla",
  "perle",
  "perse",
  "perte",
  "peser",
  "pesez",
  "pesta",
  "peste",
  "petit",
  "peton",
  "peurs",
  "phare",
  "phase",
  "phone",
  "phono",
  "photo",
  "piano",
  "pieds",
  "pieux",
  "piger",
  "piges",
  "pigne",
  "piler",
  "piles",
  "pilez",
  "pilla",
  "pille",
  "pilon",
  "pilou",
  "pince",
  "pinte",
  "pions",
  "piper",
  "piqua",
  "pique",
  "pires",
  "pista",
  "piste",
  "piton",
  "pitre",
  "pivot",
  "pixel",
  "pizza",
  "place",
  "placé",
  "plage",
  "plaid",
  "plaie",
  "plais",
  "plana",
  "plane",
  "plans",
  "plant",
  "plate",
  "plats",
  "plein",
  "pleur",
  "pleut",
  "plier",
  "plies",
  "pliez",
  "ploie",
  "plomb",
  "plots",
  "ploya",
  "pluie",
  "plume",
  "pneus",
  "poche",
  "poids",
  "poils",
  "poilu",
  "poing",
  "point",
  "poire",
  "poker",
  "polie",
  "polio",
  "polir",
  "polis",
  "polit",
  "polka",
  "polos",
  "pomme",
  "pompa",
  "pompe",
  "ponce",
  "ponde",
  "ponds",
  "pondu",
  "poney",
  "ponte",
  "ponts",
  "pools",
  "popes",
  "porcs",
  "pores",
  "porta",
  "porte",
  "porté",
  "porto",
  "ports",
  "posée",
  "poser",
  "poses",
  "posez",
  "posta",
  "poste",
  "potes",
  "potin",
  "pouce",
  "poufs",
  "poule",
  "pouls",
  "poupe",
  "prend",
  "prévu",
  "prier",
  "pries",
  "priez",
  "prima",
  "prime",
  "prisa",
  "prise",
  "priva",
  "prive",
  "privé",
  "probe",
  "proie",
  "promu",
  "prose",
  "proue",
  "prude",
  "prune",
  "puait",
  "puant",
  "puces",
  "puent",
  "puera",
  "puisa",
  "puise",
  "puits",
  "pulls",
  "pulpe",
  "pumas",
  "punch",
  "punie",
  "punir",
  "punis",
  "punit",
  "purée",
  "pures",
  "purge",
  "purin",
  "pyrex",
  "quais",
  "quand",
  "quant",
  "quart",
  "quasi",
  "quels",
  "queue",
  "queux",
  "quota",
  "rabat",
  "rabot",
  "races",
  "racla",
  "racle",
  "radar",
  "rader",
  "rades",
  "radia",
  "radie",
  "radio",
  "radis",
  "rafla",
  "rafle",
  "ragea",
  "rager",
  "rages",
  "ragez",
  "ragot",
  "raide",
  "raidi",
  "raids",
  "raies",
  "rails",
  "raire",
  "ramer",
  "rames",
  "ramez",
  "ramis",
  "rampa",
  "rampe",
  "rance",
  "ranch",
  "ranci",
  "range",
  "rangs",
  "rapts",
  "rares",
  "raser",
  "rases",
  "rasez",
  "rater",
  "rates",
  "ratez",
  "ratio",
  "raton",
  "raves",
  "ravie",
  "ravin",
  "ravir",
  "ravis",
  "ravit",
  "rayer",
  "rayes",
  "rayez",
  "rayon",
  "réagi",
  "rebat",
  "rebut",
  "recel",
  "récit",
  "recru",
  "recta",
  "recto",
  "recul",
  "redis",
  "redit",
  "réels",
  "refit",
  "refus",
  "régie",
  "réglé",
  "reine",
  "reins",
  "rejet",
  "relia",
  "relie",
  "relié",
  "relis",
  "relit",
  "relue",
  "relui",
  "relus",
  "relut",
  "remet",
  "remis",
  "remit",
  "remua",
  "remue",
  "rende",
  "rends",
  "rendu",
  "renia",
  "renie",
  "renne",
  "renom",
  "rente",
  "repas",
  "repli",
  "repos",
  "repue",
  "repus",
  "resta",
  "reste",
  "resté",
  "réuni",
  "revis",
  "revit",
  "revue",
  "revus",
  "rhume",
  "rhums",
  "riais",
  "riait",
  "riant",
  "riche",
  "ricin",
  "rider",
  "rides",
  "riens",
  "rient",
  "rieur",
  "rifle",
  "riiez",
  "rimer",
  "rimes",
  "rince",
  "rings",
  "rions",
  "riper",
  "rirai",
  "riras",
  "rires",
  "rirez",
  "riser",
  "rites",
  "rival",
  "river",
  "rives",
  "rivet",
  "rivez",
  "rixes",
  "rober",
  "robes",
  "robot",
  "roche",
  "rocks",
  "roder",
  "rodes",
  "rodez",
  "rogna",
  "rogne",
  "rogue",
  "roman",
  "rompe",
  "romps",
  "rompt",
  "rompu",
  "ronce",
  "ronde",
  "ronds",
  "ronge",
  "roque",
  "roser",
  "roses",
  "rosie",
  "rosir",
  "rosis",
  "rosit",
  "rosse",
  "roter",
  "rotin",
  "rotor",
  "rouer",
  "roues",
  "rouge",
  "rougi",
  "rouir",
  "roula",
  "roule",
  "round",
  "routa",
  "route",
  "royal",
  "ruade",
  "ruais",
  "ruait",
  "ruant",
  "ruban",
  "rubis",
  "ruche",
  "rudes",
  "ruent",
  "ruera",
  "rugby",
  "rugir",
  "rugis",
  "rugit",
  "ruiez",
  "ruina",
  "ruine",
  "ruons",
  "rural",
  "ruser",
  "ruses",
  "rusez",
  "rushs",
  "russe",
  "sabir",
  "sable",
  "sabot",
  "sabra",
  "sabre",
  "sache",
  "sacre",
  "sacré",
  "sagas",
  "sages",
  "saine",
  "sains",
  "saint",
  "saisi",
  "salée",
  "saler",
  "sales",
  "salez",
  "salie",
  "salin",
  "salir",
  "salis",
  "salit",
  "salle",
  "salon",
  "salua",
  "salue",
  "salut",
  "salve",
  "samba",
  "sanas",
  "sangs",
  "santé",
  "saoul",
  "saper",
  "sapes",
  "sapin",
  "saris",
  "satin",
  "sauce",
  "saufs",
  "sauge",
  "saule",
  "sauna",
  "saura",
  "saurs",
  "sauta",
  "saute",
  "sauts",
  "sauva",
  "sauve",
  "sauvé",
  "savez",
  "savon",
  "saxon",
  "saxos",
  "sbire",
  "scalp",
  "sceau",
  "scier",
  "scies",
  "sciez",
  "scion",
  "scoop",
  "score",
  "scout",
  "seaux",
  "secte",
  "seing",
  "seize",
  "selfs",
  "selle",
  "selon",
  "semer",
  "semez",
  "semis",
  "sente",
  "senti",
  "seoir",
  "serai",
  "seras",
  "serez",
  "serfs",
  "serge",
  "série",
  "serin",
  "serpe",
  "serra",
  "serre",
  "serti",
  "serve",
  "servi",
  "seuil",
  "seule",
  "seuls",
  "sevra",
  "shahs",
  "shako",
  "shoot",
  "short",
  "shows",
  "sicav",
  "siens",
  "sieur",
  "sigle",
  "signa",
  "signe",
  "signé",
  "silex",
  "silos",
  "singe",
  "sinon",
  "sinus",
  "sirop",
  "sisal",
  "sises",
  "sites",
  "situa",
  "situe",
  "situé",
  "skate",
  "skier",
  "skies",
  "skiez",
  "skiff",
  "slave",
  "slips",
  "slows",
  "small",
  "smash",
  "snack",
  "snobs",
  "sobre",
  "socle",
  "sodas",
  "soeur",
  "sofas",
  "soies",
  "soifs",
  "soins",
  "soirs",
  "sojas",
  "solda",
  "solde",
  "solen",
  "soles",
  "solos",
  "somma",
  "somme",
  "sonar",
  "sonda",
  "sonde",
  "songe",
  "sonna",
  "sonne",
  "sonos",
  "sorbe",
  "sorte",
  "sorti",
  "sorts",
  "sosie",
  "sotte",
  "souci",
  "souda",
  "soude",
  "souks",
  "soupa",
  "soupe",
  "sourd",
  "souri",
  "soute",
  "soyas",
  "soyez",
  "spire",
  "sport",
  "spots",
  "squaw",
  "stade",
  "staff",
  "stage",
  "stand",
  "stars",
  "statu",
  "steak",
  "stemm",
  "stems",
  "stick",
  "stock",
  "stops",
  "store",
  "strie",
  "stucs",
  "style",
  "stylo",
  "suais",
  "suait",
  "suant",
  "suave",
  "subie",
  "subir",
  "subis",
  "subit",
  "sucra",
  "sucre",
  "suent",
  "suera",
  "sueur",
  "suffi",
  "suiez",
  "suint",
  "suite",
  "suive",
  "suivi",
  "sujet",
  "sulky",
  "suons",
  "super",
  "supin",
  "sures",
  "surfa",
  "surfe",
  "surfs",
  "surgi",
  "surir",
  "swaps",
  "swing",
  "sympa",
  "tabac",
  "tabla",
  "table",
  "tabou",
  "tacha",
  "tache",
  "tacts",
  "taies",
  "taira",
  "taire",
  "taise",
  "talcs",
  "taler",
  "talon",
  "talus",
  "tamis",
  "tango",
  "tanin",
  "tanks",
  "tante",
  "taons",
  "taper",
  "tapes",
  "tapez",
  "tapie",
  "tapir",
  "tapis",
  "tapit",
  "tarda",
  "tarde",
  "tarer",
  "tares",
  "tarie",
  "tarif",
  "tarir",
  "taris",
  "tarit",
  "tarot",
  "tarte",
  "tassa",
  "tasse",
  "taule",
  "taupe",
  "taxer",
  "taxes",
  "taxez",
  "taxis",
  "teins",
  "teint",
  "telle",
  "tempe",
  "tempo",
  "temps",
  "tende",
  "tends",
  "tendu",
  "tenez",
  "tenir",
  "tenon",
  "tenta",
  "tente",
  "tenté",
  "tenue",
  "tenus",
  "terme",
  "terne",
  "terni",
  "terra",
  "terre",
  "terri",
  "testa",
  "teste",
  "testé",
  "tests",
  "texan",
  "texte",
  "thons",
  "thuya",
  "tiare",
  "tibia",
  "tiens",
  "tient",
  "tiers",
  "tiges",
  "tigre",
  "tilde",
  "tilts",
  "timon",
  "tinta",
  "tinte",
  "tique",
  "tirer",
  "tires",
  "tirés",
  "tiret",
  "tirez",
  "tison",
  "tissa",
  "tisse",
  "tissu",
  "titan",
  "titra",
  "titre",
  "toast",
  "toges",
  "toile",
  "toisa",
  "toise",
  "toits",
  "tomba",
  "tombe",
  "tombé",
  "tomes",
  "tomme",
  "tonal",
  "tonde",
  "tonds",
  "tondu",
  "tonna",
  "tonne",
  "tonte",
  "tonus",
  "toper",
  "toque",
  "torde",
  "tords",
  "tordu",
  "toril",
  "toron",
  "torse",
  "torts",
  "torve",
  "total",
  "totem",
  "touer",
  "tours",
  "toute",
  "trace",
  "tracé",
  "tract",
  "trahi",
  "traie",
  "train",
  "trais",
  "trait",
  "trama",
  "trame",
  "trams",
  "trapu",
  "tribu",
  "trier",
  "tries",
  "triez",
  "trios",
  "tripe",
  "trocs",
  "trois",
  "troll",
  "tronc",
  "trope",
  "trots",
  "troua",
  "troue",
  "trous",
  "trucs",
  "truie",
  "trust",
  "tsars",
  "tuais",
  "tuait",
  "tuant",
  "tubas",
  "tuber",
  "tubes",
  "tuent",
  "tuiez",
  "tuile",
  "tulle",
  "tuner",
  "tuons",
  "turbo",
  "turcs",
  "turfs",
  "tuyau",
  "tweed",
  "typer",
  "types",
  "typon",
  "typos",
  "ubacs",
  "ukase",
  "unies",
  "union",
  "unira",
  "unité",
  "urgea",
  "urger",
  "urnes",
  "usage",
  "usais",
  "usait",
  "usant",
  "usées",
  "usent",
  "usera",
  "usiez",
  "usina",
  "usine",
  "usons",
  "usuel",
  "usure",
  "utile",
  "vache",
  "vagir",
  "vague",
  "vainc",
  "vaine",
  "vains",
  "valet",
  "valez",
  "valsa",
  "valse",
  "value",
  "valus",
  "valut",
  "valve",
  "vamps",
  "vanne",
  "vanta",
  "vante",
  "vaqua",
  "vaque",
  "varan",
  "varia",
  "varie",
  "varus",
  "vases",
  "vaste",
  "veaux",
  "veine",
  "velue",
  "velus",
  "vende",
  "vends",
  "vendu",
  "venez",
  "venge",
  "venin",
  "venir",
  "venta",
  "vente",
  "vents",
  "venue",
  "venus",
  "verbe",
  "verdi",
  "verni",
  "verra",
  "verre",
  "versa",
  "verse",
  "versé",
  "verso",
  "verte",
  "verts",
  "vertu",
  "verve",
  "veste",
  "veufs",
  "veule",
  "veuve",
  "vexer",
  "vexes",
  "vexez",
  "vibra",
  "vibre",
  "vices",
  "vichy",
  "vicia",
  "vicie",
  "vidéo",
  "vider",
  "vides",
  "videz",
  "vieil",
  "viens",
  "vient",
  "vieux",
  "vigie",
  "vigne",
  "viles",
  "villa",
  "ville",
  "viner",
  "vingt",
  "viral",
  "virer",
  "vires",
  "virez",
  "viril",
  "virus",
  "visas",
  "viser",
  "vises",
  "visés",
  "visez",
  "vison",
  "vissa",
  "visse",
  "vitae",
  "vital",
  "vitre",
  "vivat",
  "vives",
  "vivez",
  "vivra",
  "vivre",
  "vocal",
  "vodka",
  "voeux",
  "vogua",
  "vogue",
  "voici",
  "voies",
  "voila",
  "voile",
  "voire",
  "voler",
  "voles",
  "volés",
  "volet",
  "volez",
  "volts",
  "voter",
  "votes",
  "votez",
  "votre",
  "vouer",
  "voues",
  "vouez",
  "voulu",
  "voyez",
  "voyou",
  "vraie",
  "vrais",
  "wagon",
  "watts",
  "yacht",
  "yacks",
  "yards",
  "zeste",
  "zincs",
  "zonas",
  "zones",
  "zooms",
  "aborde",
  "abouti",
  "abrite",
  "abroge",
  "abrupt",
  "absent",
  "absolu",
  "acajou",
  "accola",
  "accord",
  "accroc",
  "accrue",
  "accule",
  "accusa",
  "accuse",
  "accusé",
  "acerbe",
  "achats",
  "acheta",
  "acheté",
  "acheva",
  "achevé",
  "acquis",
  "acquit",
  "acteur",
  "actifs",
  "action",
  "active",
  "actuel",
  "adapta",
  "adapte",
  "adapté",
  "adepte",
  "adjura",
  "adjure",
  "admira",
  "admise",
  "adonne",
  "adopta",
  "adopte",
  "adopté",
  "adorer",
  "adores",
  "aduler",
  "adulte",
  "advenu",
  "aérien",
  "affine",
  "affixe",
  "afflua",
  "afflue",
  "afflux",
  "affola",
  "affole",
  "affres",
  "agaces",
  "agacez",
  "agates",
  "agence",
  "agenda",
  "agents",
  "agiote",
  "agirai",
  "agisse",
  "agiter",
  "agonie",
  "agonir",
  "agrafe",
  "ahurir",
  "aidais",
  "aidant",
  "aidiez",
  "aidons",
  "aigles",
  "aigres",
  "aigrie",
  "aigris",
  "aigrit",
  "aimant",
  "aiment",
  "aimera",
  "ajouta",
  "ajoute",
  "ajouté",
  "ajuste",
  "alarme",
  "albums",
  "alcool",
  "alerta",
  "alevin",
  "alezan",
  "algues",
  "aliter",
  "allait",
  "allant",
  "allier",
  "allies",
  "alliés",
  "allons",
  "alloue",
  "allume",
  "allure",
  "alpaga",
  "alpage",
  "alpins",
  "aluner",
  "amants",
  "amarre",
  "ambler",
  "ambrer",
  "amenda",
  "amende",
  "amener",
  "ameuta",
  "amical",
  "amours",
  "amples",
  "amputa",
  "amurer",
  "amuser",
  "amusez",
  "anches",
  "ancien",
  "ancrer",
  "ancres",
  "ancrez",
  "angine",
  "angles",
  "angora",
  "animal",
  "animés",
  "animez",
  "aniser",
  "anneau",
  "années",
  "annexa",
  "annota",
  "annote",
  "annuel",
  "anodes",
  "antres",
  "aplani",
  "apparu",
  "appelé",
  "appels",
  "apport",
  "apposa",
  "appose",
  "appris",
  "appuie",
  "appuis",
  "apurer",
  "apures",
  "araser",
  "arbora",
  "arbres",
  "arcade",
  "archet",
  "ardues",
  "argent",
  "argile",
  "argots",
  "arides",
  "armais",
  "armait",
  "armant",
  "arment",
  "arpent",
  "arrime",
  "arriva",
  "arrive",
  "arrivé",
  "arroge",
  "arrosa",
  "aspect",
  "assagi",
  "assise",
  "assoie",
  "assume",
  "assure",
  "assuré",
  "astral",
  "astres",
  "astuce",
  "atomes",
  "atones",
  "atours",
  "atouts",
  "attend",
  "attire",
  "attiré",
  "aubier",
  "aucune",
  "audits",
  "augura",
  "auquel",
  "aurait",
  "auriez",
  "auront",
  "autant",
  "auteur",
  "autour",
  "autres",
  "auvent",
  "avales",
  "avalez",
  "avance",
  "avancé",
  "avants",
  "avarie",
  "avatar",
  "avenir",
  "avenue",
  "avenus",
  "averse",
  "averti",
  "aveuli",
  "avides",
  "avilir",
  "avilis",
  "avilit",
  "avions",
  "avises",
  "avisos",
  "avives",
  "avocat",
  "avoirs",
  "avouer",
  "avouez",
  "axiome",
  "badaud",
  "badges",
  "badine",
  "bafoua",
  "baguer",
  "bahuts",
  "baigna",
  "baissa",
  "baisse",
  "baissé",
  "balade",
  "balais",
  "balaya",
  "balisa",
  "balise",
  "baller",
  "balles",
  "ballon",
  "bambin",
  "bambou",
  "banale",
  "banals",
  "banane",
  "bancal",
  "bancos",
  "bandes",
  "bannit",
  "banque",
  "bantou",
  "baquet",
  "barbet",
  "bardas",
  "bardes",
  "barman",
  "barres",
  "barrez",
  "barrir",
  "basait",
  "basées",
  "basent",
  "basque",
  "bassin",
  "basson",
  "bateau",
  "battez",
  "battit",
  "battre",
  "battus",
  "baudet",
  "bavait",
  "baviez",
  "beauté",
  "becter",
  "beiges",
  "belges",
  "belles",
  "belons",
  "bennes",
  "bercer",
  "bercez",
  "berger",
  "berlue",
  "bernez",
  "besoin",
  "bettes",
  "beurre",
  "biaisa",
  "biceps",
  "bicher",
  "bigler",
  "bijoux",
  "bilans",
  "biller",
  "billes",
  "billet",
  "billot",
  "binage",
  "biseau",
  "bisons",
  "bistro",
  "bitume",
  "blague",
  "blaser",
  "blesse",
  "bleuie",
  "bleuir",
  "blinde",
  "blonde",
  "bloque",
  "blouse",
  "bobine",
  "bocaux",
  "boirai",
  "boirez",
  "boitez",
  "boives",
  "bolide",
  "bomber",
  "bombes",
  "bonbon",
  "bondes",
  "bondir",
  "bondit",
  "bonnes",
  "bonzes",
  "border",
  "bordes",
  "bordez",
  "borgne",
  "borner",
  "bornez",
  "bosser",
  "bosses",
  "bossez",
  "bouche",
  "bouder",
  "boudes",
  "boudez",
  "boudin",
  "bouffe",
  "bouffi",
  "bouger",
  "bouges",
  "bougre",
  "boules",
  "boulon",
  "boulot",
  "boumer",
  "bourre",
  "bourse",
  "bouter",
  "bouton",
  "bovins",
  "boxera",
  "boxers",
  "boxeur",
  "boxiez",
  "boxons",
  "brader",
  "brades",
  "bradez",
  "braire",
  "braise",
  "bramer",
  "braque",
  "brassa",
  "braver",
  "braves",
  "bravez",
  "bravos",
  "breaks",
  "brebis",
  "brevet",
  "bricks",
  "bridez",
  "bridge",
  "brilla",
  "brille",
  "brimer",
  "brimes",
  "brique",
  "briser",
  "brises",
  "broker",
  "bronza",
  "bronze",
  "brosse",
  "brouta",
  "bruine",
  "bruire",
  "bruits",
  "brumer",
  "brunie",
  "brunir",
  "brunit",
  "buccal",
  "budget",
  "buffle",
  "buggys",
  "bulles",
  "bureau",
  "burent",
  "busard",
  "butait",
  "butane",
  "butant",
  "butera",
  "buteur",
  "butiez",
  "butins",
  "butors",
  "buvait",
  "buvard",
  "buveur",
  "cabine",
  "cabres",
  "cabrez",
  "cacher",
  "caches",
  "cachés",
  "cachet",
  "cachou",
  "cactus",
  "caddie",
  "cadeau",
  "cadrer",
  "cadres",
  "cadrez",
  "cafard",
  "cagibi",
  "cahier",
  "cahota",
  "cahote",
  "cahots",
  "caisse",
  "cajous",
  "calage",
  "calais",
  "calant",
  "calcul",
  "calent",
  "calmar",
  "calmer",
  "caméra",
  "camion",
  "camper",
  "campes",
  "campez",
  "canard",
  "canari",
  "canaux",
  "cancer",
  "candir",
  "canots",
  "canule",
  "capots",
  "capter",
  "captes",
  "captez",
  "captif",
  "caquer",
  "carafe",
  "carcan",
  "cargos",
  "caries",
  "carmin",
  "carnet",
  "carrer",
  "carres",
  "carrés",
  "carter",
  "cartes",
  "carton",
  "casait",
  "casant",
  "casent",
  "casera",
  "casino",
  "casque",
  "casser",
  "cassez",
  "cassis",
  "caudal",
  "causer",
  "causes",
  "cavale",
  "caveau",
  "caviar",
  "ceints",
  "celles",
  "center",
  "centra",
  "centre",
  "cercla",
  "cercle",
  "cerner",
  "cernez",
  "certes",
  "cesser",
  "chacun",
  "chaire",
  "chaise",
  "champs",
  "chance",
  "change",
  "changé",
  "chanta",
  "chante",
  "chaque",
  "charge",
  "chargé",
  "charma",
  "charme",
  "charte",
  "chasse",
  "chaton",
  "chaude",
  "chauds",
  "chaume",
  "chauve",
  "chelem",
  "chemin",
  "chenet",
  "chenil",
  "cheval",
  "chevet",
  "chiens",
  "chimie",
  "chiper",
  "chique",
  "chlore",
  "choeur",
  "choies",
  "choisi",
  "chopes",
  "choqua",
  "choque",
  "choral",
  "choses",
  "choyer",
  "chrome",
  "chromo",
  "chrono",
  "chuter",
  "chutes",
  "chutez",
  "cibler",
  "cibles",
  "cidres",
  "cierge",
  "ciller",
  "ciment",
  "cinéma",
  "cintra",
  "cintre",
  "cirais",
  "cirant",
  "cirent",
  "cirera",
  "cireur",
  "cirque",
  "cirrus",
  "citées",
  "citron",
  "civets",
  "civile",
  "civils",
  "claire",
  "clairs",
  "clamer",
  "clames",
  "clamez",
  "clapir",
  "claque",
  "clarté",
  "classe",
  "classé",
  "clause",
  "claver",
  "clercs",
  "client",
  "climat",
  "clique",
  "cloche",
  "cloras",
  "cloues",
  "cochez",
  "cochon",
  "cocons",
  "codage",
  "codais",
  "codait",
  "codent",
  "codera",
  "codeur",
  "codons",
  "coeurs",
  "coffre",
  "cognac",
  "cogner",
  "cognez",
  "coiffe",
  "coince",
  "coings",
  "coites",
  "colite",
  "colles",
  "collez",
  "colore",
  "combat",
  "combla",
  "comble",
  "comité",
  "commet",
  "commis",
  "commun",
  "compta",
  "compte",
  "compté",
  "comtes",
  "conclu",
  "confia",
  "confie",
  "confié",
  "confus",
  "connue",
  "connus",
  "connut",
  "conque",
  "contes",
  "contre",
  "convie",
  "convoi",
  "copier",
  "copies",
  "copine",
  "coquet",
  "corail",
  "corder",
  "cordes",
  "corner",
  "cornet",
  "cornus",
  "corses",
  "corset",
  "corsez",
  "cortex",
  "coryza",
  "cosmos",
  "cossue",
  "cossus",
  "cotais",
  "cotait",
  "coteau",
  "cotées",
  "cotent",
  "cotons",
  "couche",
  "coucou",
  "couder",
  "couler",
  "coulez",
  "couper",
  "coupes",
  "coupez",
  "coupla",
  "couple",
  "coupon",
  "courbe",
  "coures",
  "courir",
  "courre",
  "course",
  "courte",
  "courts",
  "courue",
  "courus",
  "couses",
  "cousez",
  "cousus",
  "coutil",
  "couvez",
  "couvre",
  "craies",
  "crains",
  "craint",
  "crampe",
  "crasse",
  "crayon",
  "créant",
  "crédit",
  "créées",
  "créent",
  "creuse",
  "crevez",
  "criais",
  "criait",
  "criard",
  "crible",
  "cricri",
  "criera",
  "crieur",
  "crimes",
  "crions",
  "crises",
  "crissa",
  "croira",
  "croire",
  "croisa",
  "croise",
  "croqua",
  "croque",
  "crosse",
  "crotte",
  "croula",
  "cruels",
  "cubait",
  "cubant",
  "cubera",
  "cuirai",
  "cuises",
  "cuisez",
  "cuisse",
  "cuiter",
  "cuivre",
  "cultes",
  "cumula",
  "cupide",
  "cupule",
  "curage",
  "cuvait",
  "cuviez",
  "cycles",
  "cygnes",
  "dagues",
  "daigne",
  "damnez",
  "dandys",
  "danger",
  "danois",
  "danser",
  "dansez",
  "darces",
  "darder",
  "darnes",
  "dartre",
  "datait",
  "datant",
  "datent",
  "dateur",
  "dation",
  "dattes",
  "dauber",
  "débats",
  "debout",
  "débute",
  "débuté",
  "débuts",
  "décédé",
  "décide",
  "décidé",
  "déclin",
  "décors",
  "décote",
  "décret",
  "décrit",
  "dedans",
  "défaut",
  "défend",
  "défini",
  "défunt",
  "dégage",
  "dégagé",
  "degrés",
  "dehors",
  "délais",
  "demain",
  "denier",
  "denses",
  "départ",
  "dépend",
  "déposé",
  "depuis",
  "député",
  "derbys",
  "dérive",
  "désert",
  "design",
  "désire",
  "désirs",
  "dessin",
  "dessus",
  "destin",
  "détail",
  "détenu",
  "détour",
  "dettes",
  "deuils",
  "devais",
  "devait",
  "devant",
  "devenu",
  "devina",
  "devins",
  "devint",
  "devise",
  "devoir",
  "dévolu",
  "devons",
  "devras",
  "devrez",
  "diable",
  "dictez",
  "dicton",
  "diesel",
  "diffus",
  "digits",
  "dignes",
  "dindon",
  "dinghy",
  "dingos",
  "dirait",
  "direct",
  "dirige",
  "dirigé",
  "dirons",
  "disait",
  "disant",
  "discal",
  "disent",
  "disert",
  "disiez",
  "dispos",
  "disque",
  "divers",
  "divisa",
  "divise",
  "divisé",
  "docile",
  "doctes",
  "dodues",
  "dogmes",
  "dogues",
  "doigts",
  "doives",
  "dollar",
  "dolmen",
  "domina",
  "domine",
  "dominé",
  "dompta",
  "donjon",
  "donnée",
  "donner",
  "donnes",
  "donnés",
  "donnez",
  "dopait",
  "dopant",
  "dopons",
  "dorade",
  "dorait",
  "dorant",
  "dorent",
  "doreur",
  "dormir",
  "dorure",
  "dosage",
  "dosais",
  "dosant",
  "dosent",
  "dosons",
  "dotent",
  "dotons",
  "douane",
  "douant",
  "doubla",
  "double",
  "doublé",
  "douces",
  "douche",
  "douter",
  "doutes",
  "doutez",
  "douves",
  "dragon",
  "draine",
  "drapes",
  "drapez",
  "dressa",
  "dresse",
  "drille",
  "drisse",
  "driver",
  "drogua",
  "drogue",
  "droite",
  "droits",
  "ducale",
  "dupais",
  "dupait",
  "dupant",
  "dupera",
  "dupons",
  "duquel",
  "durait",
  "durant",
  "durcit",
  "durées",
  "durent",
  "durera",
  "duvets",
  "dynamo",
  "écarts",
  "échecs",
  "échoué",
  "écoles",
  "écoulé",
  "écoute",
  "écrans",
  "écrire",
  "écrite",
  "écrits",
  "efface",
  "effara",
  "effare",
  "effets",
  "effila",
  "effile",
  "effort",
  "effroi",
  "égales",
  "église",
  "élargi",
  "élevée",
  "élevés",
  "embuer",
  "émises",
  "emmena",
  "empara",
  "empire",
  "emplis",
  "emplit",
  "emploi",
  "empois",
  "encore",
  "encres",
  "encrez",
  "enduis",
  "endure",
  "enfant",
  "enfila",
  "enfile",
  "enfler",
  "enflez",
  "enfoui",
  "enfuit",
  "enfuma",
  "engagé",
  "enivre",
  "enjeux",
  "enlisa",
  "ennemi",
  "ennuie",
  "ennuis",
  "ennuya",
  "énorme",
  "enquit",
  "enraya",
  "enraye",
  "enroba",
  "ensuit",
  "entama",
  "entame",
  "entamé",
  "entend",
  "entier",
  "entité",
  "entrée",
  "entrer",
  "entrez",
  "envers",
  "envier",
  "envies",
  "enviez",
  "envoie",
  "envois",
  "envola",
  "envols",
  "envoyé",
  "enzyme",
  "épices",
  "époque",
  "épouse",
  "équipe",
  "équipé",
  "ergots",
  "ermite",
  "errera",
  "erreur",
  "erriez",
  "ersatz",
  "escale",
  "escher",
  "esches",
  "espace",
  "espion",
  "espoir",
  "esprit",
  "essaie",
  "essaim",
  "essais",
  "essaya",
  "essayé",
  "essore",
  "essuya",
  "estima",
  "estime",
  "estimé",
  "établi",
  "étages",
  "étapes",
  "étendu",
  "ethnie",
  "étions",
  "étoile",
  "étonné",
  "étroit",
  "études",
  "étudie",
  "étudié",
  "eurent",
  "évalue",
  "évalué",
  "éviter",
  "évolue",
  "évolué",
  "évoque",
  "évoqué",
  "exacte",
  "exacts",
  "exalte",
  "examen",
  "exauce",
  "except",
  "excite",
  "exclue",
  "exclus",
  "excusa",
  "excuse",
  "exerce",
  "exercé",
  "exhala",
  "exiger",
  "exiges",
  "exiles",
  "exista",
  "existe",
  "exocet",
  "expert",
  "expier",
  "expira",
  "expire",
  "exposa",
  "expose",
  "exposé",
  "exulte",
  "fabula",
  "facial",
  "facile",
  "factor",
  "fadeur",
  "faible",
  "faibli",
  "faille",
  "failli",
  "faites",
  "falote",
  "falots",
  "fameux",
  "fanent",
  "fanion",
  "fanons",
  "faraud",
  "farces",
  "farcir",
  "fardes",
  "fardez",
  "farine",
  "fasses",
  "fauche",
  "faudra",
  "fausse",
  "fautes",
  "faveur",
  "favori",
  "feinte",
  "feints",
  "femmes",
  "fendes",
  "fendra",
  "fendre",
  "ferais",
  "ferait",
  "fériés",
  "feriez",
  "fermée",
  "fermer",
  "fermes",
  "fermés",
  "fermez",
  "feront",
  "ferrer",
  "fesser",
  "festif",
  "feutra",
  "fiable",
  "fiacre",
  "fiance",
  "fiasco",
  "fibres",
  "fibule",
  "ficela",
  "fiches",
  "fichez",
  "fictif",
  "fierai",
  "fieras",
  "fierté",
  "figura",
  "figure",
  "fiions",
  "filait",
  "filant",
  "filent",
  "filera",
  "filets",
  "filles",
  "filmes",
  "filons",
  "filtra",
  "filtre",
  "finale",
  "finals",
  "finaud",
  "finies",
  "finira",
  "fioles",
  "firent",
  "firmes",
  "fiscal",
  "fixais",
  "fixait",
  "fixant",
  "fixées",
  "fjords",
  "flacon",
  "flairs",
  "flambe",
  "flamme",
  "flatta",
  "flatte",
  "fléchi",
  "flegme",
  "flemme",
  "fleura",
  "fleuri",
  "fleurs",
  "fleuve",
  "flippe",
  "flirts",
  "flores",
  "florin",
  "flotte",
  "fluide",
  "fluors",
  "focale",
  "foetus",
  "foires",
  "foison",
  "folies",
  "folios",
  "foliot",
  "folles",
  "foncer",
  "fonces",
  "foncez",
  "fondée",
  "fonder",
  "fondez",
  "fondre",
  "fondue",
  "fondus",
  "fontes",
  "forage",
  "forain",
  "forais",
  "forait",
  "forcer",
  "forces",
  "forent",
  "forera",
  "forger",
  "forges",
  "forgez",
  "foriez",
  "format",
  "formel",
  "former",
  "formes",
  "formés",
  "formez",
  "formol",
  "fortes",
  "forums",
  "fosses",
  "fouine",
  "foulée",
  "foules",
  "fourni",
  "foutez",
  "foutus",
  "foyers",
  "fracas",
  "fraise",
  "francs",
  "frappe",
  "frappé",
  "fraser",
  "fraude",
  "frayer",
  "frayes",
  "frayez",
  "freina",
  "freins",
  "friche",
  "frigos",
  "frimer",
  "friper",
  "frirai",
  "friras",
  "frirez",
  "frisez",
  "frites",
  "froide",
  "fronts",
  "frugal",
  "fruits",
  "fugace",
  "fuguer",
  "fuirai",
  "fuirez",
  "fuites",
  "fumage",
  "fumait",
  "fumets",
  "fumeur",
  "fumier",
  "fumoir",
  "furent",
  "furets",
  "fureur",
  "furies",
  "fusain",
  "fusait",
  "fuseau",
  "fusera",
  "fusion",
  "future",
  "futurs",
  "fuyais",
  "fuyant",
  "fuyiez",
  "fuyons",
  "gaffer",
  "gaffes",
  "gagner",
  "gagnes",
  "gagnez",
  "gainer",
  "gaines",
  "galant",
  "galber",
  "galbes",
  "galeux",
  "gambas",
  "gamets",
  "gamine",
  "gamins",
  "gammes",
  "gangue",
  "ganses",
  "garage",
  "garant",
  "garder",
  "gardes",
  "gardez",
  "garera",
  "gariez",
  "garnie",
  "garnit",
  "gauche",
  "gavais",
  "gavant",
  "gavial",
  "gaviez",
  "gazage",
  "gazeux",
  "gazons",
  "géants",
  "geigne",
  "gelais",
  "gelait",
  "gelant",
  "geliez",
  "gelons",
  "gemmer",
  "gendre",
  "généré",
  "genets",
  "genres",
  "gentes",
  "gentil",
  "gérant",
  "gestes",
  "gibbon",
  "gibets",
  "gibier",
  "gifler",
  "giflez",
  "gilets",
  "gisait",
  "gisant",
  "gitane",
  "gitans",
  "givrer",
  "glabre",
  "glacer",
  "glaces",
  "glaner",
  "glanes",
  "glatir",
  "glisse",
  "global",
  "globes",
  "gloire",
  "gloser",
  "gloses",
  "gluant",
  "gobait",
  "gobons",
  "goitre",
  "golden",
  "gommez",
  "gonfle",
  "gorger",
  "gorges",
  "gosier",
  "gosses",
  "gouges",
  "goulot",
  "goulue",
  "gourde",
  "gourds",
  "gourou",
  "gousse",
  "gracia",
  "gradin",
  "grains",
  "grande",
  "grandi",
  "grands",
  "grappe",
  "grasse",
  "gratin",
  "gratis",
  "gratta",
  "graver",
  "graves",
  "gravie",
  "gravis",
  "gravit",
  "greffe",
  "grenat",
  "grener",
  "grevez",
  "griefs",
  "griffe",
  "griffu",
  "grille",
  "grimer",
  "grimpa",
  "grimpe",
  "grimpé",
  "grippe",
  "grisbi",
  "grises",
  "grives",
  "grogna",
  "gronda",
  "grosse",
  "grotte",
  "groupa",
  "groupe",
  "grouse",
  "gruaux",
  "gruger",
  "guerre",
  "guette",
  "gueuse",
  "guider",
  "guides",
  "guidez",
  "guigne",
  "guimpe",
  "habile",
  "habita",
  "habite",
  "habits",
  "hachez",
  "hachis",
  "hacker",
  "haleta",
  "haltes",
  "hampes",
  "hantez",
  "harder",
  "harems",
  "hargne",
  "harpes",
  "harpie",
  "hasard",
  "haussa",
  "hausse",
  "hautes",
  "havane",
  "havres",
  "hayons",
  "heaume",
  "hennir",
  "herber",
  "herbes",
  "hérité",
  "hernie",
  "hésité",
  "heures",
  "heurta",
  "heurte",
  "heurts",
  "hiatus",
  "hideux",
  "hilare",
  "hippie",
  "hisser",
  "hocher",
  "hochet",
  "hockey",
  "homard",
  "hommes",
  "honnie",
  "honnis",
  "honora",
  "horion",
  "hormis",
  "hostie",
  "hotter",
  "hourra",
  "housse",
  "hucher",
  "huerez",
  "huiles",
  "huilez",
  "humain",
  "humait",
  "humant",
  "hument",
  "humeur",
  "humons",
  "humour",
  "huotte",
  "hurrah",
  "huttes",
  "hydres",
  "hymens",
  "idéale",
  "idiome",
  "idoine",
  "idoles",
  "idylle",
  "igloos",
  "igname",
  "ignare",
  "ignore",
  "iguane",
  "images",
  "imbibe",
  "imitez",
  "impact",
  "impies",
  "impoli",
  "impose",
  "imposé",
  "impure",
  "impurs",
  "imputa",
  "impute",
  "inapte",
  "incite",
  "incité",
  "inclus",
  "inclut",
  "indexa",
  "indexe",
  "indexé",
  "indice",
  "induis",
  "inédit",
  "inepte",
  "inerte",
  "infime",
  "infini",
  "influa",
  "influx",
  "infusa",
  "infuse",
  "ingrat",
  "inhuma",
  "inique",
  "initie",
  "injure",
  "innove",
  "instar",
  "intima",
  "intime",
  "invita",
  "invite",
  "invité",
  "iouler",
  "irions",
  "ironie",
  "irrita",
  "irrite",
  "isoler",
  "isolés",
  "issues",
  "ivraie",
  "jabler",
  "jabots",
  "jaguar",
  "jalons",
  "jamais",
  "jambes",
  "jambon",
  "jantes",
  "jardin",
  "jargon",
  "jarret",
  "jasait",
  "jasant",
  "jasent",
  "jasera",
  "jasper",
  "jattes",
  "jauger",
  "jauges",
  "jaugez",
  "jaunis",
  "jerker",
  "jetiez",
  "jetons",
  "jeunes",
  "jobard",
  "jockey",
  "jointe",
  "jokers",
  "jolies",
  "jongla",
  "jongle",
  "jonque",
  "jouait",
  "jouant",
  "jouent",
  "jouera",
  "jouets",
  "joueur",
  "joules",
  "joutes",
  "jouxte",
  "joyaux",
  "joyeux",
  "jubila",
  "jubile",
  "judoka",
  "jugées",
  "jugent",
  "jugiez",
  "jumeau",
  "jumela",
  "junior",
  "juntes",
  "jurais",
  "jurait",
  "jurant",
  "jurons",
  "jusque",
  "juteux",
  "kaolin",
  "kasher",
  "kimono",
  "kystes",
  "labial",
  "lacets",
  "laciez",
  "lacune",
  "ladres",
  "lagons",
  "lagune",
  "lainer",
  "laisse",
  "laissé",
  "lamine",
  "lampes",
  "lancée",
  "lancer",
  "lances",
  "lancés",
  "landau",
  "landes",
  "langue",
  "lapine",
  "lapsus",
  "larder",
  "lardon",
  "larges",
  "largua",
  "largue",
  "larmes",
  "larron",
  "lascar",
  "lascif",
  "lasses",
  "lassez",
  "lassos",
  "latent",
  "latine",
  "latins",
  "lattes",
  "lavage",
  "lavait",
  "lavoir",
  "leader",
  "légale",
  "légers",
  "légion",
  "lequel",
  "lestes",
  "lettre",
  "levage",
  "levain",
  "levais",
  "levait",
  "levant",
  "levers",
  "levier",
  "levons",
  "levure",
  "lianes",
  "liants",
  "libéré",
  "libres",
  "licher",
  "licite",
  "licols",
  "licous",
  "lierai",
  "lieuse",
  "lifter",
  "ligner",
  "lignes",
  "ligote",
  "liguer",
  "liguez",
  "liions",
  "limais",
  "limait",
  "limera",
  "limier",
  "limite",
  "limité",
  "limoge",
  "limons",
  "lionne",
  "lipide",
  "lippue",
  "liriez",
  "lisait",
  "lisant",
  "lisent",
  "lisses",
  "listes",
  "listez",
  "litige",
  "litres",
  "livrer",
  "livres",
  "livrés",
  "livret",
  "locale",
  "locaux",
  "locher",
  "logent",
  "logeur",
  "logiez",
  "loisir",
  "longer",
  "longes",
  "longue",
  "lopins",
  "loties",
  "lotira",
  "lottes",
  "louage",
  "loueur",
  "louiez",
  "louper",
  "lourde",
  "lourds",
  "loutre",
  "louves",
  "loyale",
  "loyaux",
  "loyers",
  "lucide",
  "lupins",
  "lurent",
  "lurons",
  "lustra",
  "lustre",
  "lutter",
  "luxant",
  "luxent",
  "luxiez",
  "luxure",
  "madone",
  "magner",
  "maigre",
  "maille",
  "mainte",
  "maints",
  "maires",
  "mairie",
  "maison",
  "majeur",
  "majoré",
  "malade",
  "malaxa",
  "malgré",
  "malice",
  "malins",
  "malles",
  "malter",
  "mamans",
  "manche",
  "mandat",
  "mander",
  "mandez",
  "manger",
  "maniez",
  "manioc",
  "manqua",
  "manque",
  "manqué",
  "mantes",
  "manuel",
  "maquer",
  "maquis",
  "marbre",
  "marche",
  "marché",
  "marges",
  "mariée",
  "marier",
  "maries",
  "mariés",
  "mariez",
  "marina",
  "marine",
  "market",
  "marner",
  "marque",
  "marqué",
  "marres",
  "marrie",
  "marris",
  "martel",
  "martes",
  "martre",
  "masqua",
  "masque",
  "masser",
  "massif",
  "massue",
  "mastic",
  "mastoc",
  "masure",
  "matchs",
  "mature",
  "mauves",
  "mauvis",
  "maxima",
  "mazout",
  "médias",
  "membre",
  "menace",
  "menacé",
  "ménage",
  "menait",
  "menant",
  "mendia",
  "mendie",
  "menées",
  "meneur",
  "menons",
  "mentez",
  "menton",
  "mentor",
  "menues",
  "menuet",
  "mépris",
  "mercis",
  "merise",
  "mérite",
  "merlan",
  "mesure",
  "métaux",
  "métier",
  "mettes",
  "mettra",
  "mettre",
  "meubla",
  "meuble",
  "meugle",
  "meutes",
  "miasme",
  "micmac",
  "micron",
  "micros",
  "mienne",
  "mignon",
  "migrer",
  "migrez",
  "mijota",
  "milieu",
  "milite",
  "milles",
  "millet",
  "mimait",
  "mimant",
  "minais",
  "mincie",
  "mincis",
  "minent",
  "mineur",
  "minime",
  "minois",
  "minons",
  "minora",
  "minore",
  "minuit",
  "minute",
  "mioche",
  "mirage",
  "mirait",
  "mirant",
  "mirent",
  "mirera",
  "miroir",
  "misait",
  "misant",
  "misera",
  "misiez",
  "misons",
  "missel",
  "misses",
  "mitent",
  "mitera",
  "mitron",
  "mixage",
  "mixais",
  "mixait",
  "mixant",
  "mixent",
  "mixtes",
  "mobile",
  "modale",
  "modela",
  "modéré",
  "modula",
  "module",
  "moelle",
  "moeurs",
  "moines",
  "moisie",
  "moisir",
  "moites",
  "moitié",
  "moitir",
  "mollet",
  "moment",
  "momies",
  "monder",
  "mondes",
  "montée",
  "monter",
  "montra",
  "montre",
  "montré",
  "moquez",
  "morale",
  "mordre",
  "mordue",
  "morgue",
  "mornes",
  "morses",
  "mortel",
  "mortes",
  "moteur",
  "motifs",
  "motiva",
  "moucha",
  "mouche",
  "moudra",
  "moudre",
  "moules",
  "moulin",
  "mourez",
  "moussa",
  "mousse",
  "mouvez",
  "moyens",
  "moyeux",
  "muerez",
  "munira",
  "murais",
  "murale",
  "murals",
  "murent",
  "muriez",
  "murons",
  "muscat",
  "muscle",
  "museau",
  "musées",
  "musser",
  "mutant",
  "mutera",
  "mutiez",
  "mutile",
  "mutina",
  "myrrhe",
  "mythes",
  "nababs",
  "nabote",
  "nacres",
  "nantir",
  "nantis",
  "nantit",
  "napalm",
  "nappes",
  "naquit",
  "narres",
  "narrez",
  "naseau",
  "natale",
  "nation",
  "natter",
  "nattes",
  "nature",
  "navals",
  "navets",
  "navire",
  "navrer",
  "navrez",
  "nectar",
  "négoce",
  "neiger",
  "nervis",
  "nettes",
  "neutre",
  "neuves",
  "niable",
  "nicher",
  "niches",
  "nickel",
  "nierai",
  "nigaud",
  "nimber",
  "ninjas",
  "nipper",
  "nippon",
  "niveau",
  "nivela",
  "nobles",
  "nocifs",
  "nodule",
  "noiera",
  "noises",
  "nomade",
  "nombre",
  "nommer",
  "normal",
  "normes",
  "notais",
  "notait",
  "notent",
  "notera",
  "notice",
  "notion",
  "notons",
  "notule",
  "nouera",
  "noueux",
  "nouiez",
  "nouons",
  "nourri",
  "nouvel",
  "noyaux",
  "noyiez",
  "noyons",
  "nuages",
  "nuance",
  "nubile",
  "nuiras",
  "nuirez",
  "nulles",
  "numéro",
  "objets",
  "oblige",
  "obligé",
  "obtenu",
  "obture",
  "obvier",
  "occupe",
  "occupé",
  "octane",
  "octave",
  "octroi",
  "odeurs",
  "odieux",
  "oeuvre",
  "offert",
  "office",
  "offres",
  "offrez",
  "offrir",
  "ogives",
  "oignon",
  "oisifs",
  "oisons",
  "olives",
  "omette",
  "onagre",
  "oncles",
  "ondula",
  "ongles",
  "opales",
  "opaque",
  "opérer",
  "opiner",
  "opines",
  "oppose",
  "opposé",
  "optent",
  "optera",
  "optima",
  "option",
  "ordres",
  "ordure",
  "organe",
  "ormeau",
  "ornais",
  "ornant",
  "ornent",
  "orniez",
  "ornons",
  "oronge",
  "orteil",
  "oscars",
  "osiers",
  "osions",
  "osmose",
  "otages",
  "otarie",
  "otites",
  "ouater",
  "oublie",
  "oublié",
  "oublis",
  "ourdie",
  "ourdis",
  "ourler",
  "outils",
  "outrez",
  "ouvert",
  "ouvrer",
  "ouvrez",
  "ouvrir",
  "ovaire",
  "ovales",
  "ovuler",
  "oxyder",
  "ozones",
  "pagina",
  "pagine",
  "pagode",
  "paient",
  "paille",
  "paires",
  "palace",
  "palais",
  "palier",
  "palmes",
  "palpes",
  "palpez",
  "pampas",
  "panels",
  "panier",
  "pannes",
  "panser",
  "pansez",
  "pantin",
  "paonne",
  "papale",
  "papaux",
  "papier",
  "paquet",
  "parada",
  "parade",
  "parafe",
  "pareil",
  "parent",
  "parfum",
  "parias",
  "parier",
  "parité",
  "parler",
  "parles",
  "parole",
  "partie",
  "partir",
  "partis",
  "partit",
  "parure",
  "pascal",
  "passée",
  "passer",
  "passes",
  "passés",
  "passez",
  "passif",
  "patent",
  "patine",
  "patron",
  "paumes",
  "pauses",
  "pauvre",
  "pavait",
  "pavant",
  "pavent",
  "paveur",
  "pavois",
  "pavons",
  "payant",
  "payées",
  "payent",
  "payera",
  "payeur",
  "payiez",
  "peiner",
  "peines",
  "peinez",
  "peints",
  "pelage",
  "peliez",
  "pelles",
  "pelons",
  "pelote",
  "pénale",
  "pencha",
  "penche",
  "pendes",
  "pendra",
  "pendue",
  "pensée",
  "penser",
  "penses",
  "pensez",
  "pensum",
  "percée",
  "percer",
  "perdre",
  "perdue",
  "perdus",
  "perles",
  "permet",
  "permis",
  "permit",
  "perron",
  "perses",
  "persil",
  "pertes",
  "pesage",
  "pesais",
  "pesant",
  "peseta",
  "pesons",
  "pestes",
  "petite",
  "petits",
  "petons",
  "peupla",
  "peuple",
  "phares",
  "phases",
  "phobie",
  "phoque",
  "photos",
  "phrase",
  "piaffe",
  "pierre",
  "pieuse",
  "pignon",
  "pilait",
  "pileux",
  "piliez",
  "pillez",
  "pilote",
  "pilous",
  "pilule",
  "piolet",
  "pipeau",
  "piquer",
  "piques",
  "pister",
  "pistes",
  "pistez",
  "pistil",
  "piteux",
  "pitons",
  "pivote",
  "pivots",
  "pixels",
  "pizzas",
  "placée",
  "placer",
  "places",
  "placés",
  "placet",
  "placez",
  "plages",
  "plagia",
  "plagie",
  "plaide",
  "plaids",
  "plaies",
  "plaine",
  "plains",
  "plaire",
  "planes",
  "planez",
  "plante",
  "plaqua",
  "plaque",
  "plasma",
  "plates",
  "pleine",
  "pleins",
  "pleura",
  "pleure",
  "pliage",
  "pliiez",
  "plions",
  "pliure",
  "plombe",
  "plonge",
  "plongé",
  "ployer",
  "ployez",
  "plumer",
  "plumes",
  "pocher",
  "poches",
  "podium",
  "poésie",
  "pogrom",
  "poilue",
  "poilus",
  "poings",
  "pointa",
  "pointe",
  "points",
  "pointu",
  "poisse",
  "poivre",
  "pokers",
  "police",
  "polios",
  "pollen",
  "pommer",
  "pommes",
  "pompes",
  "pompez",
  "pompon",
  "poncez",
  "pondes",
  "pondre",
  "pondue",
  "ponter",
  "popote",
  "portée",
  "porter",
  "portes",
  "portés",
  "posées",
  "posent",
  "posons",
  "postal",
  "postes",
  "postez",
  "potage",
  "potion",
  "pouces",
  "poudra",
  "poudre",
  "poules",
  "poulet",
  "poumon",
  "poupes",
  "poupin",
  "poupon",
  "pourra",
  "pourri",
  "pourvu",
  "pousse",
  "poussé",
  "pouvez",
  "précis",
  "prédit",
  "prenez",
  "prenne",
  "prénom",
  "presse",
  "pressé",
  "preuve",
  "prévue",
  "prévus",
  "priait",
  "priera",
  "primat",
  "primer",
  "primes",
  "prince",
  "prions",
  "priori",
  "prises",
  "prison",
  "privée",
  "priver",
  "privés",
  "proche",
  "profil",
  "profit",
  "proies",
  "projet",
  "promet",
  "promis",
  "promit",
  "promue",
  "promus",
  "promut",
  "pronom",
  "propos",
  "propre",
  "proues",
  "prouve",
  "prouvé",
  "public",
  "publie",
  "publié",
  "puceau",
  "puiser",
  "puises",
  "puisse",
  "punira",
  "purent",
  "pureté",
  "purgea",
  "purger",
  "putain",
  "putois",
  "puzzle",
  "pyjama",
  "quarts",
  "quartz",
  "quatre",
  "quelle",
  "quidam",
  "quille",
  "quinte",
  "quinze",
  "quitte",
  "quitté",
  "quitus",
  "quotas",
  "rabane",
  "rabbin",
  "rabiot",
  "rabote",
  "rachat",
  "racine",
  "raclez",
  "racola",
  "racole",
  "radeau",
  "radios",
  "radium",
  "radius",
  "radota",
  "radoub",
  "rafale",
  "raffut",
  "rafler",
  "rafles",
  "raflez",
  "ragent",
  "ragera",
  "ragots",
  "raguer",
  "railla",
  "raille",
  "rainer",
  "raison",
  "ramait",
  "ramena",
  "ramené",
  "rament",
  "rameur",
  "ramone",
  "ramons",
  "ramper",
  "rampez",
  "rances",
  "rancie",
  "rancir",
  "rancis",
  "ranger",
  "rangez",
  "ranime",
  "rapace",
  "raphia",
  "rapide",
  "rappel",
  "raquer",
  "rareté",
  "rasade",
  "rasage",
  "rasais",
  "rasant",
  "rasent",
  "rasoir",
  "rasons",
  "rassir",
  "rassit",
  "ratage",
  "ratais",
  "ratant",
  "ratera",
  "rating",
  "ration",
  "ratios",
  "ratons",
  "ravage",
  "ravale",
  "ravies",
  "ravina",
  "ravira",
  "ravisa",
  "ravoir",
  "rayait",
  "rayons",
  "razzia",
  "réagir",
  "réagit",
  "rebond",
  "rebord",
  "rebuta",
  "récent",
  "récits",
  "recoin",
  "record",
  "recoud",
  "rectum",
  "recuit",
  "reculé",
  "rédigé",
  "redira",
  "redire",
  "redits",
  "redoux",
  "réduit",
  "réelle",
  "refait",
  "refera",
  "reflet",
  "reflux",
  "refuge",
  "refuse",
  "refusé",
  "regain",
  "regard",
  "régime",
  "région",
  "régler",
  "regret",
  "reines",
  "rejeta",
  "rejeté",
  "relais",
  "relaxa",
  "relent",
  "releva",
  "relevé",
  "relief",
  "relier",
  "reliés",
  "relira",
  "relise",
  "reluit",
  "remisa",
  "remise",
  "remous",
  "rempli",
  "remuer",
  "renais",
  "renard",
  "rendes",
  "rendez",
  "rendit",
  "rendre",
  "rendue",
  "rendus",
  "renies",
  "renoms",
  "renoua",
  "rénové",
  "renter",
  "rentes",
  "rentra",
  "rentre",
  "renvoi",
  "repaie",
  "repars",
  "repaya",
  "repens",
  "repent",
  "répond",
  "report",
  "reposa",
  "repose",
  "repris",
  "reprit",
  "repues",
  "réputé",
  "requis",
  "réseau",
  "réside",
  "résolu",
  "ressac",
  "restée",
  "rester",
  "restés",
  "résume",
  "résumé",
  "retard",
  "retenu",
  "retire",
  "retiré",
  "retour",
  "return",
  "réunir",
  "réunis",
  "réunit",
  "réussi",
  "révélé",
  "revenu",
  "revers",
  "revint",
  "revoir",
  "revois",
  "revoit",
  "revues",
  "riants",
  "ribler",
  "riches",
  "ridant",
  "rifles",
  "rigide",
  "riment",
  "rinces",
  "rioter",
  "rirent",
  "ririez",
  "rirons",
  "risqua",
  "risque",
  "risqué",
  "rituel",
  "rivage",
  "rivais",
  "rivale",
  "rivant",
  "riviez",
  "rivons",
  "roches",
  "rocker",
  "rococo",
  "rodage",
  "rodais",
  "rogner",
  "rognez",
  "rognon",
  "romain",
  "romans",
  "rompes",
  "rompue",
  "ronces",
  "rondin",
  "ronfla",
  "rongea",
  "rosace",
  "rosbif",
  "rosses",
  "rotins",
  "rotule",
  "rouges",
  "rouget",
  "rougie",
  "rougir",
  "rouler",
  "roules",
  "roulez",
  "roulis",
  "rounds",
  "rousse",
  "routes",
  "royale",
  "royaux",
  "rudoie",
  "rudoya",
  "rueras",
  "ruerez",
  "rugbys",
  "rugira",
  "ruiler",
  "ruines",
  "ruinez",
  "ruions",
  "rumeur",
  "rumina",
  "rumine",
  "rusera",
  "rusiez",
  "russes",
  "rutila",
  "rythme",
  "sabirs",
  "sabote",
  "sabrer",
  "sabres",
  "sabrez",
  "saches",
  "sacrer",
  "sacres",
  "saille",
  "sailli",
  "saines",
  "sainte",
  "saisie",
  "saisir",
  "saisis",
  "saison",
  "salade",
  "salait",
  "saline",
  "salive",
  "salles",
  "salmis",
  "saloir",
  "salons",
  "saloon",
  "saluez",
  "samedi",
  "santon",
  "saoule",
  "sapait",
  "sapant",
  "sapera",
  "sapeur",
  "saquer",
  "sarrau",
  "satins",
  "satire",
  "satura",
  "sauces",
  "sauges",
  "saules",
  "saumon",
  "sauner",
  "sauter",
  "sauver",
  "savait",
  "savant",
  "savent",
  "saveur",
  "saviez",
  "savoir",
  "savons",
  "scanda",
  "scande",
  "scella",
  "schéma",
  "sciant",
  "sciera",
  "sciiez",
  "scions",
  "scorie",
  "scouts",
  "scruta",
  "séance",
  "second",
  "secret",
  "séduit",
  "seiche",
  "séjour",
  "seller",
  "semais",
  "semble",
  "semoir",
  "senior",
  "sentir",
  "sentis",
  "sépare",
  "séparé",
  "serait",
  "séries",
  "serons",
  "seront",
  "serrer",
  "sertie",
  "serval",
  "servir",
  "servit",
  "setter",
  "seuils",
  "seules",
  "sevrer",
  "sevrez",
  "seyant",
  "shoots",
  "shorts",
  "sienne",
  "sieste",
  "siffla",
  "signal",
  "signée",
  "signer",
  "signes",
  "signés",
  "signet",
  "signez",
  "sillon",
  "simoun",
  "simple",
  "singer",
  "siphon",
  "située",
  "situer",
  "situes",
  "situés",
  "situez",
  "skiant",
  "skient",
  "skiera",
  "skieur",
  "skiffs",
  "skions",
  "slogan",
  "sobres",
  "social",
  "socles",
  "sodium",
  "soeurs",
  "soient",
  "soigna",
  "soigne",
  "soirée",
  "soldez",
  "soleil",
  "solide",
  "sombra",
  "sombre",
  "sommes",
  "sommet",
  "sommez",
  "sonate",
  "sondes",
  "songea",
  "songer",
  "songez",
  "sonner",
  "sonnet",
  "sonore",
  "sorbes",
  "sortes",
  "sortie",
  "sortir",
  "sortis",
  "sortit",
  "sottes",
  "soucia",
  "soucie",
  "soucis",
  "souder",
  "soudes",
  "soulte",
  "soumet",
  "soumis",
  "soumit",
  "souper",
  "soupes",
  "soupez",
  "souple",
  "source",
  "sourde",
  "sourds",
  "souris",
  "sourit",
  "soviet",
  "soyeux",
  "spasme",
  "sphinx",
  "spires",
  "sports",
  "spread",
  "square",
  "stable",
  "stades",
  "stages",
  "stagna",
  "stagne",
  "stands",
  "statif",
  "statua",
  "statue",
  "statut",
  "steaks",
  "stemms",
  "stocka",
  "stocke",
  "stocks",
  "stoppe",
  "stores",
  "strate",
  "stress",
  "strict",
  "studio",
  "styles",
  "stylet",
  "suaves",
  "subies",
  "subits",
  "subtil",
  "sucres",
  "suerai",
  "sueras",
  "suffit",
  "suinta",
  "suinte",
  "suisse",
  "suites",
  "suives",
  "suivie",
  "suivis",
  "suivit",
  "suivre",
  "sujets",
  "sulkys",
  "sultan",
  "surent",
  "surfai",
  "surfas",
  "surfil",
  "surgie",
  "surjet",
  "sursis",
  "survie",
  "survis",
  "survit",
  "survol",
  "swings",
  "sympas",
  "syndic",
  "synode",
  "syrien",
  "tabacs",
  "tabler",
  "tables",
  "tachez",
  "tailla",
  "taille",
  "tairas",
  "tairez",
  "talent",
  "talons",
  "tandem",
  "tandis",
  "tangua",
  "tangue",
  "tantes",
  "tapais",
  "tapait",
  "tapant",
  "tapeur",
  "tapies",
  "tapira",
  "tapirs",
  "tapons",
  "tapota",
  "taquet",
  "taquin",
  "tarder",
  "tardez",
  "tardif",
  "targua",
  "taries",
  "tarifa",
  "tarife",
  "tarifs",
  "tarots",
  "tartes",
  "tartir",
  "tasser",
  "tasses",
  "tassez",
  "taupes",
  "taurin",
  "taxant",
  "taxent",
  "telles",
  "témoin",
  "tempes",
  "temple",
  "tempos",
  "tenais",
  "tenait",
  "tenant",
  "tendez",
  "tendre",
  "tendue",
  "teneur",
  "teniez",
  "tennis",
  "tenons",
  "tenter",
  "tentes",
  "tentés",
  "tentez",
  "tenues",
  "termes",
  "ternis",
  "ternit",
  "terrer",
  "terres",
  "terril",
  "tertre",
  "tester",
  "testes",
  "testez",
  "textes",
  "thuyas",
  "ticket",
  "tienne",
  "tierce",
  "tigres",
  "tildes",
  "tiller",
  "timbre",
  "timide",
  "timing",
  "tiques",
  "tirade",
  "tirage",
  "tirait",
  "tirant",
  "tirent",
  "tirera",
  "tirets",
  "tiroir",
  "tisane",
  "tisons",
  "tisses",
  "tissez",
  "tissus",
  "titane",
  "titans",
  "titrer",
  "titres",
  "toiles",
  "toiser",
  "toises",
  "toisez",
  "toison",
  "tomate",
  "tombée",
  "tomber",
  "tombes",
  "tonals",
  "tondre",
  "tondue",
  "tonner",
  "tonnes",
  "topaze",
  "toquer",
  "tordre",
  "tordus",
  "torero",
  "torils",
  "torons",
  "torses",
  "tosser",
  "totale",
  "totaux",
  "touche",
  "touché",
  "toupet",
  "toupie",
  "tourne",
  "tourné",
  "toutes",
  "toxine",
  "traces",
  "tracta",
  "tracte",
  "trafic",
  "trahie",
  "trahir",
  "trains",
  "traita",
  "traite",
  "traité",
  "traits",
  "trajet",
  "trames",
  "transi",
  "traqua",
  "trauma",
  "treize",
  "trempe",
  "trente",
  "trésor",
  "tresse",
  "treuil",
  "triade",
  "triage",
  "triais",
  "tribun",
  "triche",
  "tricot",
  "trient",
  "triera",
  "triiez",
  "trille",
  "triple",
  "triplé",
  "triste",
  "trombe",
  "trompe",
  "troqua",
  "troque",
  "trotta",
  "troues",
  "trouve",
  "trouvé",
  "truand",
  "truffa",
  "truffe",
  "truies",
  "truite",
  "truste",
  "tuiles",
  "tuners",
  "tunnel",
  "turban",
  "turent",
  "tussor",
  "tuyaux",
  "tweeds",
  "typhon",
  "typons",
  "ukases",
  "ultime",
  "unifia",
  "unifie",
  "unique",
  "unirez",
  "unités",
  "urbain",
  "urgent",
  "usager",
  "usages",
  "usante",
  "usants",
  "userai",
  "useras",
  "userez",
  "usiner",
  "usines",
  "usurpa",
  "utiles",
  "vacant",
  "vaccin",
  "vaches",
  "vagues",
  "vaille",
  "vaincs",
  "vaines",
  "valais",
  "valait",
  "valant",
  "valent",
  "valeur",
  "valgus",
  "vallée",
  "vallon",
  "valoir",
  "valons",
  "valser",
  "valsez",
  "vamper",
  "vanner",
  "vanter",
  "vantes",
  "vapeur",
  "vaquer",
  "varech",
  "varier",
  "vaseux",
  "vassal",
  "vastes",
  "vaudra",
  "veille",
  "veiner",
  "venait",
  "venant",
  "vendit",
  "vendra",
  "vendre",
  "vendue",
  "vendus",
  "veneur",
  "vengea",
  "venger",
  "venges",
  "vengez",
  "venons",
  "ventes",
  "ventre",
  "ventru",
  "venues",
  "verbes",
  "verdir",
  "verdit",
  "verger",
  "vérité",
  "vernie",
  "vernis",
  "verres",
  "verrez",
  "verrou",
  "versée",
  "verser",
  "verses",
  "versés",
  "versez",
  "versos",
  "vertes",
  "vertus",
  "vessie",
  "vexais",
  "vexant",
  "vexiez",
  "viable",
  "viager",
  "viande",
  "vidage",
  "vidais",
  "vidait",
  "vidant",
  "vidéos",
  "videra",
  "vielle",
  "vienne",
  "vierge",
  "vigies",
  "vigile",
  "vignes",
  "villas",
  "villes",
  "vineux",
  "vinyle",
  "violet",
  "violon",
  "virage",
  "virago",
  "virais",
  "virant",
  "virera",
  "viriez",
  "virons",
  "visage",
  "visais",
  "visait",
  "visant",
  "visent",
  "visera",
  "visiez",
  "vision",
  "visita",
  "visite",
  "visons",
  "visser",
  "vissez",
  "visuel",
  "vitrer",
  "vitres",
  "vivace",
  "vivant",
  "vivent",
  "vivier",
  "viviez",
  "vivons",
  "vivote",
  "vivrai",
  "vivras",
  "vocale",
  "vodkas",
  "voguer",
  "vogues",
  "voient",
  "voirie",
  "voisin",
  "volait",
  "volant",
  "volent",
  "volera",
  "volets",
  "voleur",
  "volume",
  "volute",
  "votais",
  "votant",
  "votent",
  "votera",
  "votons",
  "vouais",
  "vouait",
  "vouent",
  "vouiez",
  "voulez",
  "voulue",
  "voyage",
  "voyais",
  "voyait",
  "voyant",
  "voyeur",
  "voyiez",
  "voyons",
  "vraies",
  "vrille",
  "zestes",
  "zipper",
  "abaissé",
  "abonnés",
  "aborder",
  "aboutir",
  "aboutit",
  "absence",
  "absolue",
  "absorbe",
  "accéder",
  "accents",
  "accepte",
  "accepté",
  "accorde",
  "accordé",
  "accords",
  "accueil",
  "achetée",
  "acheter",
  "achetés",
  "acquise",
  "acteurs",
  "actions",
  "actives",
  "actrice",
  "actuels",
  "adaptée",
  "adapter",
  "adaptés",
  "adeptes",
  "adéquat",
  "adjoint",
  "adoptée",
  "adopter",
  "adresse",
  "adressé",
  "adultes",
  "affaire",
  "affecté",
  "affiche",
  "affiché",
  "affirme",
  "affirmé",
  "agences",
  "ajoutée",
  "ajouter",
  "allures",
  "amateur",
  "ambiant",
  "amendes",
  "ampleur",
  "analyse",
  "anciens",
  "anglais",
  "animaux",
  "annexes",
  "annonce",
  "annoncé",
  "annuels",
  "anonyme",
  "antenne",
  "appelée",
  "appeler",
  "appelés",
  "appelle",
  "appétit",
  "apporte",
  "apporté",
  "apprend",
  "arrivée",
  "arriver",
  "arrivés",
  "article",
  "artiste",
  "aspects",
  "assiste",
  "assisté",
  "associe",
  "associé",
  "assorti",
  "assumer",
  "assurée",
  "assurer",
  "assurés",
  "atelier",
  "attaché",
  "attaque",
  "atteint",
  "attendu",
  "attente",
  "attirer",
  "attrait",
  "aurions",
  "auteurs",
  "automne",
  "avaient",
  "avancée",
  "avancer",
  "avancés",
  "avocats",
  "bagages",
  "baisser",
  "baisses",
  "balance",
  "banking",
  "banques",
  "baptisé",
  "baroque",
  "barreau",
  "basilic",
  "bateaux",
  "berceau",
  "berline",
  "besoins",
  "billets",
  "bizarre",
  "blessés",
  "blocage",
  "bloquer",
  "boisson",
  "bonheur",
  "bordure",
  "bougent",
  "bougies",
  "bouquet",
  "bourses",
  "boutons",
  "branche",
  "branché",
  "brevets",
  "briques",
  "brokers",
  "brutale",
  "budgets",
  "bureaux",
  "cabinet",
  "cachent",
  "cadeaux",
  "caisses",
  "calcule",
  "calculé",
  "calculs",
  "caméras",
  "camions",
  "capable",
  "capital",
  "carbone",
  "carnets",
  "cartons",
  "cascade",
  "caution",
  "cellule",
  "central",
  "centres",
  "cercles",
  "certain",
  "cerveau",
  "cessent",
  "cession",
  "chacune",
  "chaises",
  "chaleur",
  "chambre",
  "chances",
  "changer",
  "changes",
  "chanson",
  "chanter",
  "chapeau",
  "charbon",
  "chargée",
  "charger",
  "charges",
  "chargés",
  "charmes",
  "chaudes",
  "chemins",
  "chemise",
  "cherche",
  "cherché",
  "chevaux",
  "cheveux",
  "chiffre",
  "chinois",
  "choisie",
  "choisir",
  "choisis",
  "choisit",
  "circuit",
  "citoyen",
  "civiles",
  "claires",
  "classer",
  "classes",
  "classés",
  "clauses",
  "clavier",
  "clichés",
  "clients",
  "cliquet",
  "colonne",
  "colorés",
  "coloris",
  "combien",
  "combine",
  "combiné",
  "combler",
  "comédie",
  "comités",
  "comment",
  "commune",
  "communs",
  "compact",
  "compare",
  "comparé",
  "complet",
  "compose",
  "composé",
  "compris",
  "compter",
  "comptes",
  "concept",
  "concert",
  "conclus",
  "conclut",
  "concret",
  "conduit",
  "confiée",
  "confier",
  "conflit",
  "confort",
  "connais",
  "connues",
  "conquis",
  "conseil",
  "console",
  "constat",
  "contact",
  "content",
  "contenu",
  "continu",
  "contrat",
  "contrer",
  "convenu",
  "copains",
  "correct",
  "costume",
  "coucher",
  "couches",
  "couleur",
  "couples",
  "coupons",
  "courage",
  "courant",
  "courbes",
  "courent",
  "courses",
  "courtes",
  "couteau",
  "coutume",
  "couture",
  "couvert",
  "couvrir",
  "crainte",
  "cravate",
  "créance",
  "créatif",
  "crédits",
  "créneau",
  "cristal",
  "croient",
  "croyait",
  "crucial",
  "cuisine",
  "cuisson",
  "cultive",
  "culture",
  "curieux",
  "dangers",
  "danoise",
  "déceler",
  "déchets",
  "décidée",
  "décider",
  "déclare",
  "déclaré",
  "décline",
  "découle",
  "découpe",
  "décrire",
  "déduire",
  "défaire",
  "défaite",
  "défauts",
  "défendu",
  "défense",
  "déficit",
  "définie",
  "définir",
  "définis",
  "définit",
  "dégager",
  "délégué",
  "délicat",
  "demande",
  "demandé",
  "démarre",
  "démarré",
  "demeure",
  "dénonce",
  "densité",
  "départs",
  "dépasse",
  "dépassé",
  "dépense",
  "déplace",
  "déploie",
  "déposée",
  "déposer",
  "députés",
  "dérivés",
  "dernier",
  "déroule",
  "descend",
  "désigne",
  "désigné",
  "dessert",
  "dessine",
  "dessiné",
  "dessins",
  "dessous",
  "destiné",
  "détails",
  "détenir",
  "détente",
  "détenue",
  "détenus",
  "détient",
  "détruit",
  "devenir",
  "devenue",
  "devenus",
  "devient",
  "devises",
  "dévoile",
  "devoirs",
  "devrait",
  "devront",
  "diamant",
  "diffuse",
  "diffusé",
  "diminue",
  "diminué",
  "directe",
  "directs",
  "dirigée",
  "diriger",
  "discret",
  "disparu",
  "dispose",
  "disposé",
  "disques",
  "divorce",
  "dizaine",
  "docteur",
  "doivent",
  "dollars",
  "domaine",
  "dommage",
  "donnait",
  "donnant",
  "données",
  "donnent",
  "donnera",
  "dossier",
  "doubler",
  "doubles",
  "douceur",
  "douleur",
  "douteux",
  "dresser",
  "drogues",
  "durable",
  "échange",
  "échappe",
  "échappé",
  "échéant",
  "échelle",
  "écoulée",
  "écouter",
  "éditeur",
  "édition",
  "efforts",
  "élaboré",
  "élargir",
  "élégant",
  "élément",
  "élevées",
  "éloigné",
  "émanant",
  "émettre",
  "émotion",
  "emploie",
  "emplois",
  "employé",
  "emporté",
  "emprunt",
  "encadré",
  "endroit",
  "énergie",
  "enfance",
  "enfants",
  "engagée",
  "engager",
  "engagés",
  "enlever",
  "énormes",
  "ensuite",
  "entamée",
  "entamer",
  "entendu",
  "entités",
  "entoure",
  "entouré",
  "entrées",
  "entrent",
  "environ",
  "envoyer",
  "envoyés",
  "épargne",
  "épaules",
  "épingle",
  "épisode",
  "épreuve",
  "éprouve",
  "équipée",
  "équipes",
  "équipés",
  "erreurs",
  "espaces",
  "espérer",
  "espoirs",
  "esprits",
  "essayer",
  "essence",
  "estimée",
  "estimer",
  "établie",
  "établir",
  "établis",
  "établit",
  "étaient",
  "étendre",
  "étendue",
  "éthique",
  "étoiles",
  "étrange",
  "étroite",
  "étudier",
  "évaluée",
  "évaluer",
  "évident",
  "évitant",
  "évoluer",
  "évoquer",
  "examens",
  "examine",
  "examiné",
  "exclure",
  "exécuté",
  "exemple",
  "exercée",
  "exercer",
  "exigent",
  "exister",
  "experts",
  "explosé",
  "exporte",
  "exposés",
  "express",
  "exprime",
  "exprimé",
  "externe",
  "extrait",
  "faciles",
  "facteur",
  "facture",
  "faculté",
  "faibles",
  "faisait",
  "faisant",
  "faisons",
  "fallait",
  "fameuse",
  "famille",
  "fassent",
  "fatigue",
  "fausses",
  "faveurs",
  "fédéral",
  "féminin",
  "fermeté",
  "feuille",
  "février",
  "fiables",
  "fichier",
  "fiction",
  "figurer",
  "figures",
  "filiale",
  "filtres",
  "finance",
  "financé",
  "finesse",
  "fiscale",
  "fiscaux",
  "flacons",
  "flamand",
  "florins",
  "forfait",
  "formats",
  "forment",
  "formule",
  "fortune",
  "fournie",
  "fournir",
  "fournis",
  "fournit",
  "fragile",
  "franchi",
  "frapper",
  "freiner",
  "fromage",
  "fusions",
  "futures",
  "gagnant",
  "gagnent",
  "galerie",
  "garanti",
  "gardant",
  "gardent",
  "general",
  "général",
  "générer",
  "gestion",
  "glisser",
  "globale",
  "gouttes",
  "graisse",
  "grammes",
  "grandes",
  "grandir",
  "gratuit",
  "gravité",
  "grecque",
  "grimper",
  "grosses",
  "groupes",
  "guerres",
  "guichet",
  "habitué",
  "hausses",
  "hauteur",
  "hésiter",
  "heureux",
  "holding",
  "hommage",
  "honneur",
  "horaire",
  "horizon",
  "humaine",
  "humains",
  "ignorer",
  "imagine",
  "imaginé",
  "immense",
  "importe",
  "imposée",
  "imposer",
  "imposés",
  "imprimé",
  "incarne",
  "inciter",
  "inconnu",
  "inculpé",
  "indices",
  "indique",
  "indiqué",
  "informé",
  "initial",
  "initiés",
  "inscrit",
  "insiste",
  "inspire",
  "inspiré",
  "instant",
  "intégré",
  "intense",
  "interne",
  "inutile",
  "inventé",
  "inverse",
  "investi",
  "invités",
  "italien",
  "janvier",
  "jardins",
  "joindre",
  "joueurs",
  "journal",
  "journée",
  "juillet",
  "juriste",
  "justice",
  "lacunes",
  "laisser",
  "laissez",
  "lancées",
  "lancent",
  "langage",
  "langues",
  "largeur",
  "leaders",
  "leasing",
  "lecteur",
  "lecture",
  "légales",
  "légende",
  "légumes",
  "lenteur",
  "lettres",
  "liaison",
  "libéral",
  "libérer",
  "liberté",
  "licence",
  "limitée",
  "limiter",
  "limites",
  "limités",
  "liquide",
  "litiges",
  "livrent",
  "locales",
  "logique",
  "loisirs",
  "longues",
  "lorsque",
  "lourdes",
  "ludique",
  "luxueux",
  "machine",
  "magasin",
  "magique",
  "maigres",
  "mailing",
  "maisons",
  "majeure",
  "majeurs",
  "malades",
  "maladie",
  "malaise",
  "malheur",
  "manager",
  "manches",
  "mandats",
  "manquer",
  "manteau",
  "marasme",
  "marcher",
  "marchés",
  "mariage",
  "marquée",
  "marquer",
  "marques",
  "masques",
  "massifs",
  "massive",
  "mauvais",
  "maximum",
  "médecin",
  "médical",
  "mélange",
  "membres",
  "mémoire",
  "menaces",
  "ménages",
  "mensuel",
  "mention",
  "message",
  "mesurer",
  "mesures",
  "méthode",
  "métiers",
  "mettant",
  "mettent",
  "metteur",
  "meubles",
  "meurtre",
  "milieux",
  "millier",
  "million",
  "mineurs",
  "minimum",
  "minutes",
  "miracle",
  "miroirs",
  "mission",
  "mobiles",
  "modérée",
  "moderne",
  "modeste",
  "modifie",
  "modifié",
  "modules",
  "moindre",
  "moments",
  "mondial",
  "monnaie",
  "monstre",
  "montage",
  "montant",
  "montent",
  "montrer",
  "montres",
  "morales",
  "morceau",
  "moteurs",
  "moyenne",
  "muscles",
  "musical",
  "musique",
  "nations",
  "naturel",
  "navires",
  "négatif",
  "négocie",
  "négocié",
  "nerveux",
  "nippons",
  "niveaux",
  "normale",
  "notaire",
  "notions",
  "nourrir",
  "nourrit",
  "nouveau",
  "nuances",
  "numéros",
  "obligée",
  "obligés",
  "observe",
  "observé",
  "obtenir",
  "obtenue",
  "obtenus",
  "obtient",
  "occupée",
  "occuper",
  "occupés",
  "octobre",
  "octroyé",
  "oeuvres",
  "offerte",
  "offerts",
  "offrait",
  "offrant",
  "offrent",
  "oignons",
  "oiseaux",
  "onéreux",
  "opinion",
  "options",
  "optique",
  "oreille",
  "organes",
  "orienté",
  "origine",
  "oscille",
  "oublier",
  "ouverte",
  "ouverts",
  "ouvrage",
  "ouvrant",
  "ouvrier",
  "palette",
  "panique",
  "panneau",
  "papiers",
  "paquets",
  "paradis",
  "parents",
  "parfait",
  "parfois",
  "parfums",
  "parking",
  "parlait",
  "parlant",
  "parlent",
  "paroles",
  "parquet",
  "partage",
  "partagé",
  "partant",
  "partent",
  "partiel",
  "parties",
  "partout",
  "parvenu",
  "passage",
  "passait",
  "passant",
  "passées",
  "passent",
  "passera",
  "passion",
  "patient",
  "patrons",
  "pauvres",
  "payante",
  "paysage",
  "paysans",
  "peintre",
  "peloton",
  "pencher",
  "pendant",
  "pénible",
  "pensait",
  "pensent",
  "pension",
  "pensons",
  "pénurie",
  "perdant",
  "perdent",
  "période",
  "pervers",
  "petites",
  "pétrole",
  "peuples",
  "peuvent",
  "phrases",
  "pierres",
  "piliers",
  "pilotes",
  "piscine",
  "placées",
  "placent",
  "plafond",
  "plainte",
  "plaisir",
  "planche",
  "plantes",
  "plaques",
  "plateau",
  "plongée",
  "plonger",
  "plupart",
  "poignée",
  "pointes",
  "poisson",
  "polices",
  "portait",
  "portant",
  "portent",
  "portera",
  "porteur",
  "positif",
  "pourrez",
  "poussée",
  "pousser",
  "pouvait",
  "pouvant",
  "pouvoir",
  "pouvons",
  "préavis",
  "précédé",
  "précise",
  "précisé",
  "précoce",
  "préféré",
  "premier",
  "prenait",
  "prenant",
  "prendra",
  "prendre",
  "preneur",
  "prépare",
  "préparé",
  "présent",
  "préside",
  "presque",
  "prétend",
  "preuves",
  "prévaut",
  "prévenu",
  "prévoir",
  "prévoit",
  "prévues",
  "privées",
  "procédé",
  "process",
  "proches",
  "procure",
  "produit",
  "profile",
  "profils",
  "profite",
  "profité",
  "profits",
  "profond",
  "projets",
  "propice",
  "propose",
  "proposé",
  "propres",
  "protégé",
  "prouver",
  "prudent",
  "publics",
  "publiée",
  "publier",
  "publiés",
  "puisque",
  "qualité",
  "quelles",
  "quelque",
  "quitter",
  "quotité",
  "rachats",
  "racheté",
  "racines",
  "raconte",
  "radical",
  "raffiné",
  "raisins",
  "raisons",
  "ralenti",
  "ramener",
  "rapides",
  "rappelé",
  "rapport",
  "ratings",
  "réalise",
  "réalisé",
  "réalité",
  "récente",
  "récents",
  "recette",
  "recevra",
  "réclame",
  "récolte",
  "reconnu",
  "records",
  "recours",
  "reculer",
  "rédiger",
  "réduire",
  "réduite",
  "réduits",
  "réelles",
  "refaire",
  "réflexe",
  "réforme",
  "refuser",
  "regarde",
  "regards",
  "régimes",
  "régions",
  "réglage",
  "rejoint",
  "réjouir",
  "relance",
  "relatif",
  "relever",
  "remises",
  "remonte",
  "remonté",
  "remplie",
  "remplir",
  "remplit",
  "rendant",
  "rendent",
  "renoncé",
  "renouer",
  "rentrée",
  "rentrer",
  "renvoie",
  "répandu",
  "réparer",
  "réparti",
  "repérer",
  "répéter",
  "répondu",
  "réponse",
  "reposer",
  "reprend",
  "reprise",
  "réputée",
  "réputés",
  "requise",
  "réseaux",
  "réserve",
  "réservé",
  "résiste",
  "résisté",
  "respect",
  "respire",
  "ressent",
  "ressort",
  "restait",
  "restant",
  "restent",
  "restera",
  "restons",
  "résulte",
  "retards",
  "retenir",
  "retenue",
  "retenus",
  "retient",
  "retirer",
  "retombé",
  "retrait",
  "returns",
  "réunies",
  "réunion",
  "réussie",
  "réussir",
  "réussit",
  "révéler",
  "revendu",
  "revenir",
  "revente",
  "revenue",
  "revenus",
  "revient",
  "révolte",
  "rigueur",
  "risquer",
  "risques",
  "roulant",
  "routier",
  "routine",
  "royaume",
  "rumeurs",
  "rupture",
  "rythmes",
  "sachant",
  "sagesse",
  "saisons",
  "salaire",
  "salarié",
  "saurait",
  "sauvage",
  "saveurs",
  "scanner",
  "schémas",
  "science",
  "scrutin",
  "séances",
  "seconde",
  "seconds",
  "secours",
  "secrets",
  "secteur",
  "section",
  "séduire",
  "segment",
  "séjours",
  "semaine",
  "sentent",
  "séparée",
  "séparer",
  "séparés",
  "sérieux",
  "serions",
  "servant",
  "servent",
  "serveur",
  "service",
  "servira",
  "signale",
  "signalé",
  "signaux",
  "silence",
  "sillage",
  "simples",
  "situait",
  "situant",
  "situées",
  "situent",
  "sociale",
  "sociaux",
  "société",
  "soigner",
  "soirées",
  "solaire",
  "soldats",
  "solides",
  "sombres",
  "sommeil",
  "sommets",
  "sondage",
  "sonores",
  "sortant",
  "sortent",
  "sorties",
  "sortira",
  "soudain",
  "souffle",
  "souffre",
  "souhait",
  "soumise",
  "souples",
  "sources",
  "sourire",
  "soutenu",
  "soutien",
  "souvent",
  "spécial",
  "spectre",
  "spirale",
  "sportif",
  "stables",
  "station",
  "statuts",
  "stimule",
  "stocker",
  "stricte",
  "studios",
  "succédé",
  "suédois",
  "suffira",
  "suicide",
  "suisses",
  "suivant",
  "suivent",
  "suivies",
  "superbe",
  "support",
  "suppose",
  "surface",
  "surplus",
  "surpris",
  "sursaut",
  "surtout",
  "suscite",
  "suscité",
  "symbole",
  "tableau",
  "tablent",
  "talents",
  "tardive",
  "teintes",
  "témoins",
  "tendent",
  "tension",
  "tentant",
  "tentent",
  "termine",
  "terminé",
  "terrain",
  "terroir",
  "textile",
  "texture",
  "théorie",
  "tiendra",
  "tiroirs",
  "tomates",
  "tombent",
  "toucher",
  "touches",
  "touchés",
  "tournée",
  "tourner",
  "tournoi",
  "trading",
  "traduit",
  "traiter",
  "traités",
  "tranche",
  "travail",
  "travaux",
  "travers",
  "trésors",
  "tromper",
  "trouble",
  "troupes",
  "trouvée",
  "trouver",
  "truffes",
  "tutelle",
  "typique",
  "uniques",
  "univers",
  "urbaine",
  "urbains",
  "urgence",
  "usagers",
  "utilise",
  "utilisé",
  "valable",
  "valeurs",
  "vanille",
  "variant",
  "varient",
  "variété",
  "vedette",
  "veiller",
  "velours",
  "vendant",
  "vendent",
  "vendeur",
  "vendues",
  "verdure",
  "verront",
  "versées",
  "version",
  "veulent",
  "viandes",
  "victime",
  "vieille",
  "viendra",
  "vigueur",
  "village",
  "violent",
  "virtuel",
  "visages",
  "visible",
  "visiter",
  "visites",
  "vitesse",
  "vitrine",
  "voisine",
  "voisins",
  "voiture",
  "volonté",
  "volumes",
  "voulais",
  "voulait",
  "vouloir",
  "voulons",
  "voyager",
  "voyages",
  "accéléré",
  "acceptée",
  "accepter",
  "accident",
  "accompli",
  "accordée",
  "accorder",
  "accordés",
  "achetant",
  "achetées",
  "acheteur",
  "acquérir",
  "acquiert",
  "acquises",
  "activité",
  "actuelle",
  "adaptées",
  "adéquate",
  "admettre",
  "adoptent",
  "adresser",
  "adresses",
  "aérienne",
  "affaires",
  "affectés",
  "affichée",
  "afficher",
  "affiches",
  "affichés",
  "affiliés",
  "affirmer",
  "africain",
  "agissent",
  "agréable",
  "agressif",
  "agricole",
  "ailleurs",
  "aimerait",
  "aisément",
  "ajoutant",
  "ajoutent",
  "aliments",
  "allaient",
  "allemand",
  "alliance",
  "allusion",
  "amateurs",
  "ambiance",
  "ambition",
  "améliore",
  "amélioré",
  "amoureux",
  "analogue",
  "analyser",
  "analyses",
  "analyste",
  "ancienne",
  "anglaise",
  "annoncée",
  "annoncer",
  "annonces",
  "annoncés",
  "annuelle",
  "anonymes",
  "anticipé",
  "appareil",
  "appelées",
  "applique",
  "appliqué",
  "apporter",
  "apprécie",
  "apprécié",
  "approche",
  "approuvé",
  "archives",
  "argument",
  "arrivent",
  "articles",
  "artisans",
  "artistes",
  "assiette",
  "assistée",
  "assister",
  "associée",
  "associés",
  "assortie",
  "assurant",
  "assurent",
  "assureur",
  "ateliers",
  "attachés",
  "attaques",
  "atteinte",
  "atteints",
  "attendre",
  "attendue",
  "attendus",
  "attentes",
  "attentif",
  "attirent",
  "attitude",
  "attribue",
  "attribué",
  "augmente",
  "augmenté",
  "auraient",
  "autonome",
  "autorise",
  "autorisé",
  "autorité",
  "auxquels",
  "avancées",
  "avancent",
  "avantage",
  "aventure",
  "bailleur",
  "bancaire",
  "banlieue",
  "banquier",
  "baptisée",
  "bataille",
  "batterie",
  "beaucoup",
  "bénéfice",
  "boissons",
  "bordeaux",
  "bouillon",
  "boursier",
  "boutique",
  "brancher",
  "branches",
  "brasseur",
  "brillant",
  "brochure",
  "bulletin",
  "business",
  "cabinets",
  "calculée",
  "calculer",
  "campagne",
  "canadien",
  "candidat",
  "capables",
  "capacité",
  "capitale",
  "capitaux",
  "capteurs",
  "cassette",
  "ceinture",
  "cellules",
  "centaine",
  "centimes",
  "centrale",
  "centraux",
  "certaine",
  "certains",
  "chambres",
  "champion",
  "changent",
  "chansons",
  "chanteur",
  "chantier",
  "chapelle",
  "chapitre",
  "chargées",
  "chaussée",
  "chemises",
  "chercher",
  "chiffres",
  "chimique",
  "chinoise",
  "chocolat",
  "chrétien",
  "cinéaste",
  "circuits",
  "circuler",
  "citoyens",
  "clinique",
  "cocktail",
  "cohésion",
  "collants",
  "collecte",
  "colloque",
  "colonnes",
  "combiner",
  "comédien",
  "commande",
  "commandé",
  "commence",
  "commencé",
  "commente",
  "commerce",
  "communal",
  "communes",
  "comparer",
  "complété",
  "complets",
  "complexe",
  "complice",
  "comporte",
  "composée",
  "composer",
  "composés",
  "comprend",
  "comprise",
  "comptait",
  "comptant",
  "comptent",
  "comptoir",
  "concepts",
  "concerne",
  "concerné",
  "concerts",
  "conclure",
  "concours",
  "concrets",
  "condamné",
  "conduire",
  "conduite",
  "confiant",
  "confirme",
  "confirmé",
  "conflits",
  "conforme",
  "conjoint",
  "consacre",
  "consacré",
  "conseils",
  "consenti",
  "conserve",
  "conservé",
  "consiste",
  "consomme",
  "consommé",
  "constant",
  "constate",
  "constaté",
  "contacts",
  "contenir",
  "contente",
  "conteste",
  "contexte",
  "contient",
  "continue",
  "continué",
  "contrats",
  "convient",
  "coquille",
  "correcte",
  "corriger",
  "costumes",
  "cotation",
  "couleurs",
  "couloirs",
  "coupable",
  "courante",
  "courants",
  "coureurs",
  "couronne",
  "courrier",
  "courtage",
  "courtier",
  "couverte",
  "couverts",
  "couvrant",
  "couvrent",
  "craindre",
  "craintes",
  "créances",
  "créateur",
  "créatifs",
  "création",
  "crédible",
  "critique",
  "culturel",
  "cultures",
  "cyclique",
  "cycliste",
  "débarque",
  "débiteur",
  "déboires",
  "débouche",
  "débouché",
  "décalage",
  "décembre",
  "décennie",
  "décharge",
  "décident",
  "décision",
  "déclarer",
  "déclarés",
  "découvre",
  "décroche",
  "décroché",
  "défendre",
  "déficits",
  "déguster",
  "déjeuner",
  "délégués",
  "délicate",
  "demandée",
  "demander",
  "demandes",
  "demandés",
  "démarche",
  "démarrer",
  "démontre",
  "démontré",
  "dénicher",
  "dénoncer",
  "dépasser",
  "dépendra",
  "dépenser",
  "dépenses",
  "déplacer",
  "dérapage",
  "derniers",
  "désastre",
  "descente",
  "designer",
  "désigner",
  "désirent",
  "désireux",
  "désordre",
  "desquels",
  "desserts",
  "dessinée",
  "dessiner",
  "destinée",
  "destinés",
  "détaillé",
  "détecter",
  "détenait",
  "détenues",
  "détruire",
  "devaient",
  "devenait",
  "devenant",
  "devenues",
  "devienne",
  "dévoiler",
  "dialogue",
  "diamants",
  "diffusée",
  "diffuser",
  "digitale",
  "dimanche",
  "diminuer",
  "directes",
  "director",
  "discours",
  "discuter",
  "disposer",
  "disposés",
  "distance",
  "diverses",
  "division",
  "dizaines",
  "document",
  "domaines",
  "domicile",
  "dommages",
  "donation",
  "dossiers",
  "douleurs",
  "douzaine",
  "durables",
  "duration",
  "durement",
  "échanger",
  "échanges",
  "échapper",
  "échéance",
  "économie",
  "écriture",
  "écrivain",
  "éditeurs",
  "éditions",
  "effectif",
  "effectue",
  "effectué",
  "efficace",
  "élaborée",
  "élaborer",
  "élégance",
  "élégante",
  "éléments",
  "éliminer",
  "émettant",
  "émetteur",
  "émission",
  "émotions",
  "employés",
  "emprunte",
  "emprunté",
  "emprunts",
  "endroits",
  "engendre",
  "engendré",
  "enseigne",
  "ensemble",
  "entendre",
  "environs",
  "envisage",
  "envisagé",
  "épreuves",
  "équipées",
  "équivaut",
  "escompté",
  "espagnol",
  "espérant",
  "espérons",
  "essayons",
  "estimait",
  "estimant",
  "estiment",
  "estimons",
  "établies",
  "étonnant",
  "étranger",
  "étudiant",
  "européen",
  "éventail",
  "éventuel",
  "évidence",
  "évidente",
  "évoluent",
  "évoquant",
  "évoquent",
  "examiner",
  "excessif",
  "exclusif",
  "exécuter",
  "exécutif",
  "exemples",
  "exercent",
  "exercice",
  "exigeant",
  "exigence",
  "existait",
  "existant",
  "existent",
  "exotique",
  "explique",
  "expliqué",
  "exploite",
  "exposées",
  "exprimer",
  "externes",
  "extraits",
  "fabrique",
  "fabriqué",
  "fabuleux",
  "facettes",
  "facilite",
  "facilité",
  "facteurs",
  "factures",
  "faillite",
  "fameuses",
  "familial",
  "familles",
  "faudrait",
  "fauteuil",
  "favorise",
  "fédérale",
  "fédéraux",
  "féminine",
  "féminins",
  "féminité",
  "festival",
  "feuilles",
  "fichiers",
  "fidélité",
  "figurant",
  "figurent",
  "filiales",
  "financée",
  "financer",
  "finances",
  "finement",
  "finition",
  "fiscales",
  "fixation",
  "flamande",
  "flamands",
  "flexible",
  "fonction",
  "football",
  "formuler",
  "formules",
  "fortiori",
  "fortunes",
  "fournies",
  "fraction",
  "fragiles",
  "franchir",
  "frappant",
  "freinage",
  "fréquent",
  "fromages",
  "gagnants",
  "galeries",
  "garantie",
  "garantir",
  "garantis",
  "garantit",
  "générale",
  "généraux",
  "généreux",
  "génocide",
  "gourmand",
  "grandeur",
  "gratuite",
  "gratuits",
  "gravures",
  "habitant",
  "habitude",
  "habituel",
  "habitués",
  "handicap",
  "hardware",
  "harmonie",
  "haussier",
  "hectares",
  "héritage",
  "heureuse",
  "histoire",
  "holdings",
  "horaires",
  "hormones",
  "houlette",
  "humaines",
  "identité",
  "illusion",
  "illustre",
  "illustré",
  "imaginer",
  "immédiat",
  "immeuble",
  "implanté",
  "implique",
  "impliqué",
  "imposant",
  "imposées",
  "imposent",
  "imprimer",
  "imprimés",
  "incendie",
  "inchangé",
  "incident",
  "incluant",
  "inconnue",
  "indiquer",
  "individu",
  "informer",
  "informés",
  "initiale",
  "inscrits",
  "inspirée",
  "installe",
  "installé",
  "instance",
  "intégrée",
  "intégrer",
  "intégrés",
  "interdit",
  "internes",
  "intitulé",
  "inutiles",
  "investie",
  "investir",
  "investis",
  "investit",
  "italiens",
  "japonais",
  "jeunesse",
  "joliment",
  "journaux",
  "journées",
  "jugement",
  "juristes",
  "justesse",
  "justifie",
  "justifié",
  "laissant",
  "laissent",
  "laquelle",
  "lecteurs",
  "légitime",
  "lesquels",
  "liaisons",
  "libellés",
  "libérale",
  "libéraux",
  "licences",
  "licencié",
  "liégeois",
  "limitant",
  "limitées",
  "limitent",
  "linéaire",
  "lingerie",
  "liquides",
  "location",
  "logement",
  "logiciel",
  "longueur",
  "lumineux",
  "lunettes",
  "machines",
  "magasins",
  "magazine",
  "maintenu",
  "maintien",
  "majeures",
  "majorité",
  "maladies",
  "managers",
  "managing",
  "manquait",
  "manquent",
  "manquera",
  "marchand",
  "marginal",
  "mariages",
  "maritime",
  "masculin",
  "matériau",
  "matériel",
  "maturité",
  "mauvaise",
  "maximale",
  "médaille",
  "médecine",
  "médecins",
  "médicale",
  "médicaux",
  "médiocre",
  "méfiance",
  "meilleur",
  "mélanger",
  "mélodies",
  "mémoires",
  "ménagers",
  "mercredi",
  "méritent",
  "messages",
  "méthodes",
  "mexicain",
  "milliard",
  "milliers",
  "millions",
  "minimale",
  "ministre",
  "minorité",
  "missions",
  "mobilier",
  "mobilité",
  "modernes",
  "modestes",
  "modifiée",
  "modifier",
  "moelleux",
  "moindres",
  "mondiale",
  "mondiaux",
  "moniteur",
  "monnaies",
  "monopole",
  "monsieur",
  "montagne",
  "montants",
  "montrent",
  "morceaux",
  "morosité",
  "moyennes",
  "multiple",
  "musicale",
  "musicien",
  "musiques",
  "mutation",
  "mutuelle",
  "mythique",
  "national",
  "naturels",
  "négatifs",
  "négative",
  "négliger",
  "négocier",
  "nettoyer",
  "nipponne",
  "noblesse",
  "nocturne",
  "nombreux",
  "nominale",
  "normales",
  "notation",
  "nouveaux",
  "nouvelle",
  "novembre",
  "objectif",
  "observer",
  "obstacle",
  "occasion",
  "occupait",
  "occupant",
  "occupent",
  "offertes",
  "officiel",
  "opinions",
  "opportun",
  "optimale",
  "optiques",
  "oreilles",
  "organise",
  "organisé",
  "orientée",
  "orientés",
  "original",
  "origines",
  "ouvertes",
  "ouvrages",
  "ouvriers",
  "paiement",
  "paisible",
  "panneaux",
  "panoplie",
  "pantalon",
  "paradoxe",
  "parcours",
  "parcouru",
  "pareille",
  "parfaite",
  "parisien",
  "parkings",
  "partager",
  "partagés",
  "parution",
  "parvenir",
  "parvenue",
  "parvenus",
  "parvient",
  "passager",
  "passages",
  "passions",
  "patience",
  "patients",
  "patronat",
  "pauvreté",
  "pavillon",
  "payement",
  "paysages",
  "peintres",
  "peinture",
  "pénétrer",
  "pensions",
  "périodes",
  "persiste",
  "personne",
  "persuadé",
  "physique",
  "pianiste",
  "pilotage",
  "pionnier",
  "plaindre",
  "plaintes",
  "plaisirs",
  "plancher",
  "planches",
  "planning",
  "poétique",
  "poissons",
  "policier",
  "polonais",
  "portable",
  "porteurs",
  "portrait",
  "positifs",
  "position",
  "positive",
  "posséder",
  "possible",
  "pourquoi",
  "pourrait",
  "pourront",
  "poursuit",
  "pourtant",
  "poussent",
  "pouvoirs",
  "pratique",
  "pratiqué",
  "précieux",
  "préciser",
  "précises",
  "préférer",
  "premiers",
  "prennent",
  "préparer",
  "préparés",
  "présence",
  "présente",
  "présenté",
  "présents",
  "pression",
  "prestige",
  "prétexte",
  "prévenir",
  "prévenus",
  "prévient",
  "primaire",
  "principe",
  "priorité",
  "probable",
  "procéder",
  "procédés",
  "prochain",
  "procurer",
  "produire",
  "produite",
  "produits",
  "profiter",
  "profonde",
  "projette",
  "prolonge",
  "prolongé",
  "promesse",
  "prononcé",
  "proposée",
  "proposer",
  "proposés",
  "protégée",
  "protéger",
  "protégés",
  "provient",
  "province",
  "provoque",
  "provoqué",
  "prudence",
  "prudente",
  "prudents",
  "publiées",
  "publique",
  "puissant",
  "puissent",
  "purement",
  "qualifie",
  "qualifié",
  "qualités",
  "quantité",
  "quarante",
  "quartier",
  "quatorze",
  "quelques",
  "question",
  "quittent",
  "rachetée",
  "racheter",
  "raconter",
  "radicale",
  "raffinée",
  "ralentir",
  "rapidité",
  "rappeler",
  "rappelle",
  "rapporte",
  "rapporté",
  "rapports",
  "rarement",
  "rassurer",
  "réaction",
  "réalisée",
  "réaliser",
  "réalisés",
  "réalisme",
  "réaliste",
  "réalités",
  "rebelles",
  "récentes",
  "recettes",
  "recevoir",
  "réclamer",
  "récolter",
  "reconnue",
  "reconnus",
  "recourir",
  "recouvre",
  "récupéré",
  "redonner",
  "réduites",
  "réformes",
  "réfugiés",
  "refusent",
  "regarder",
  "régional",
  "registre",
  "regrette",
  "regroupe",
  "régulier",
  "relancer",
  "relatifs",
  "relation",
  "relative",
  "relevant",
  "religion",
  "remarque",
  "remarqué",
  "remédier",
  "remettre",
  "remontée",
  "remonter",
  "remplace",
  "remplacé",
  "remporte",
  "remporté",
  "renforce",
  "renforcé",
  "renommée",
  "renoncer",
  "rentable",
  "rentrées",
  "répandue",
  "répartir",
  "répartis",
  "réplique",
  "répondre",
  "réponses",
  "reporter",
  "reprises",
  "reproche",
  "requiert",
  "réservée",
  "réserver",
  "réserves",
  "réservés",
  "résister",
  "résoudre",
  "respecte",
  "respecté",
  "restants",
  "résultat",
  "rétablir",
  "retenues",
  "retomber",
  "retourne",
  "retraite",
  "retrouve",
  "retrouvé",
  "réunions",
  "réussite",
  "revanche",
  "revenant",
  "revendre",
  "révision",
  "richesse",
  "ridicule",
  "risquent",
  "rotation",
  "routiers",
  "rubrique",
  "rwandais",
  "salaires",
  "salarial",
  "salariés",
  "sanction",
  "sauvages",
  "scandale",
  "scénario",
  "sciences",
  "scission",
  "scolaire",
  "secondes",
  "secteurs",
  "sécurité",
  "segments",
  "semaines",
  "semblait",
  "semblent",
  "semestre",
  "sénateur",
  "sensible",
  "sentiers",
  "seraient",
  "sérénité",
  "sérieuse",
  "serveurs",
  "services",
  "shopping",
  "signaler",
  "signifie",
  "sinistre",
  "sobriété",
  "sociales",
  "sociétés",
  "software",
  "soixante",
  "solaires",
  "solidité",
  "solitude",
  "solution",
  "sondages",
  "soucieux",
  "souffert",
  "souffrir",
  "souhaite",
  "souhaité",
  "souligne",
  "souligné",
  "soumises",
  "soupapes",
  "souscrit",
  "soutenir",
  "soutenue",
  "soutient",
  "souvenir",
  "souvient",
  "spatiale",
  "spéciale",
  "spéciaux",
  "sponsors",
  "sportifs",
  "sportive",
  "standard",
  "stations",
  "sterling",
  "stimuler",
  "stockage",
  "strangle",
  "strictes",
  "subsides",
  "subsiste",
  "suédoise",
  "suivante",
  "suivants",
  "superbes",
  "supporte",
  "supports",
  "supposer",
  "supprimé",
  "surfaces",
  "surprend",
  "surprise",
  "survient",
  "survivre",
  "susciter",
  "suspects",
  "symboles",
  "syndical",
  "syndicat",
  "synonyme",
  "tableaux",
  "tactique",
  "tailleur",
  "taxation",
  "télécoms",
  "témoigne",
  "tendance",
  "tensions",
  "terminal",
  "terminée",
  "terminer",
  "terrains",
  "terrasse",
  "terrible",
  "textiles",
  "thérapie",
  "tiennent",
  "toilette",
  "totalité",
  "touchant",
  "touchent",
  "toujours",
  "tourisme",
  "tournage",
  "tournant",
  "tournent",
  "toxiques",
  "traduire",
  "tragique",
  "traitées",
  "traitent",
  "trancher",
  "tranches",
  "transmet",
  "transmis",
  "traverse",
  "traversé",
  "triangle",
  "tribunal",
  "triomphe",
  "troubles",
  "trouvait",
  "trouvant",
  "trouvent",
  "trouvera",
  "trouvons",
  "uniforme",
  "utilisée",
  "utiliser",
  "utilisés",
  "vacances",
  "valables",
  "validité",
  "variable",
  "variétés",
  "vedettes",
  "véhicule",
  "vendeurs",
  "vendredi",
  "vérifier",
  "versions",
  "victimes",
  "victoire",
  "vieilles",
  "viennent",
  "vignoble",
  "villages",
  "vinaigre",
  "violence",
  "visibles",
  "visiteur",
  "visuelle",
  "vitamine",
  "vitesses",
  "vivement",
  "vocation",
  "voitures",
  "volaille",
  "voudrais",
  "voudrait",
  "voyageur",
  "vraiment",
  "abandonne",
  "abondante",
  "abordable",
  "accélérée",
  "accélérer",
  "acceptent",
  "accidents",
  "accomplir",
  "accordées",
  "accueille",
  "accueilli",
  "accumuler",
  "acheteurs",
  "acquéreur",
  "activités",
  "actualité",
  "actuariel",
  "actuelles",
  "aériennes",
  "aéroports",
  "affichait",
  "affichant",
  "affichent",
  "affirment",
  "affronter",
  "africaine",
  "africains",
  "agréables",
  "agressive",
  "agricoles",
  "alentours",
  "alimenter",
  "allemande",
  "allemands",
  "alliances",
  "aluminium",
  "ambitieux",
  "ambitions",
  "améliorée",
  "améliorer",
  "américain",
  "amoureuse",
  "analystes",
  "anciennes",
  "annoncées",
  "annoncent",
  "annuelles",
  "anticipée",
  "anticiper",
  "anversois",
  "appareils",
  "apparence",
  "appellent",
  "appliquée",
  "appliquer",
  "appliqués",
  "apportées",
  "apportent",
  "appréciée",
  "apprécier",
  "apprendre",
  "approprié",
  "argumente",
  "arguments",
  "ascension",
  "asiatique",
  "assemblée",
  "assiettes",
  "assistant",
  "assurance",
  "assureurs",
  "atteindre",
  "attendait",
  "attendant",
  "attendent",
  "attendons",
  "attention",
  "attitudes",
  "attractif",
  "attrayant",
  "attribuer",
  "audacieux",
  "augmentée",
  "augmenter",
  "autonomes",
  "autonomie",
  "autorisée",
  "autorisés",
  "autorités",
  "autoroute",
  "autrefois",
  "autrement",
  "avantages",
  "aventures",
  "bactéries",
  "bancaires",
  "banquette",
  "banquiers",
  "batteries",
  "bénéfices",
  "bénéficie",
  "bénéficié",
  "bénéfique",
  "blessures",
  "boulevard",
  "bourgeois",
  "boursiers",
  "bouteille",
  "boutiques",
  "brasserie",
  "bricolage",
  "brillante",
  "brillants",
  "brochures",
  "cadastral",
  "campagnes",
  "candidats",
  "capacités",
  "capitaine",
  "carburant",
  "cardiaque",
  "carrément",
  "cassation",
  "casserole",
  "cassettes",
  "catalogue",
  "catégorie",
  "cauchemar",
  "centaines",
  "centrales",
  "cependant",
  "céramique",
  "certaines",
  "certitude",
  "champagne",
  "champions",
  "chantiers",
  "chapitres",
  "chasseurs",
  "chauffage",
  "chauffeur",
  "chaussure",
  "cherchent",
  "chercheur",
  "chimiques",
  "chinoises",
  "chirurgie",
  "chronique",
  "cinquante",
  "circulent",
  "classique",
  "coalition",
  "cohérence",
  "collectif",
  "combattre",
  "comédiens",
  "commander",
  "commandes",
  "commencer",
  "commerces",
  "communale",
  "communaux",
  "compagnie",
  "compagnon",
  "compensée",
  "compenser",
  "compétent",
  "compléter",
  "complexes",
  "complices",
  "compliqué",
  "comporter",
  "composant",
  "composent",
  "comprends",
  "compromis",
  "comptable",
  "concentre",
  "concentré",
  "concernée",
  "concernés",
  "concevoir",
  "condamnés",
  "condition",
  "confiance",
  "confirmée",
  "confirmer",
  "confondre",
  "conformes",
  "confronté",
  "confusion",
  "conjoints",
  "connecter",
  "connexion",
  "conquérir",
  "consacrée",
  "consacrer",
  "consacrés",
  "conscient",
  "conseille",
  "conseillé",
  "consensus",
  "consentir",
  "consentis",
  "conservée",
  "conserver",
  "considéré",
  "consolidé",
  "consommer",
  "constante",
  "constater",
  "constitue",
  "constitué",
  "construit",
  "consulter",
  "contacter",
  "contenant",
  "contenter",
  "continent",
  "continuer",
  "contraint",
  "contraire",
  "contraste",
  "contribue",
  "contribué",
  "convaincu",
  "convertir",
  "convivial",
  "coquilles",
  "corporate",
  "coulisses",
  "coupables",
  "courantes",
  "courtiers",
  "craignent",
  "créancier",
  "créateurs",
  "créations",
  "créatrice",
  "crevettes",
  "critiques",
  "croissant",
  "cuisinier",
  "culturels",
  "curiosité",
  "cycliques",
  "cylindres",
  "dangereux",
  "davantage",
  "débiteurs",
  "déboucher",
  "débouchés",
  "débourser",
  "décennies",
  "déception",
  "décevants",
  "décideurs",
  "décisions",
  "déclarent",
  "déclenche",
  "découvert",
  "découvrir",
  "décrocher",
  "déduction",
  "défensive",
  "définitif",
  "déflation",
  "délicieux",
  "demandait",
  "demandant",
  "demandent",
  "demandeur",
  "démarches",
  "démarrage",
  "demeurant",
  "demeurent",
  "démission",
  "démontrer",
  "dépassant",
  "dépassent",
  "dépendant",
  "dépendent",
  "déroulent",
  "déroulera",
  "descendre",
  "désespoir",
  "désormais",
  "destinées",
  "détaillée",
  "détection",
  "détenteur",
  "détention",
  "détermine",
  "déterminé",
  "détriment",
  "développe",
  "développé",
  "deviendra",
  "devraient",
  "différent",
  "difficile",
  "diffusion",
  "dimension",
  "diminuent",
  "directeur",
  "direction",
  "directive",
  "dirigeant",
  "disposait",
  "disposant",
  "disposent",
  "disposons",
  "disquette",
  "distances",
  "distingue",
  "distribue",
  "distribué",
  "diversité",
  "dividende",
  "divisions",
  "documents",
  "dominante",
  "doucement",
  "douteuses",
  "dynamique",
  "dynamisme",
  "échappent",
  "échéances",
  "éclairage",
  "économies",
  "écrivains",
  "éducation",
  "effectifs",
  "effective",
  "effectuée",
  "effectuer",
  "effectués",
  "efficaces",
  "également",
  "électeurs",
  "élections",
  "électoral",
  "emballage",
  "émergents",
  "émetteurs",
  "émissions",
  "employées",
  "employeur",
  "emprunter",
  "enseignes",
  "ensembles",
  "entourage",
  "entrepris",
  "entretien",
  "enveloppe",
  "envergure",
  "envisagée",
  "envisager",
  "équilibre",
  "équilibré",
  "espagnole",
  "essentiel",
  "étiquette",
  "étonnante",
  "étrangers",
  "étudiants",
  "européens",
  "évènement",
  "éventuels",
  "évolution",
  "excellent",
  "exception",
  "excessive",
  "exclusive",
  "executive",
  "exercices",
  "exigences",
  "existante",
  "existants",
  "existence",
  "exotiques",
  "expansion",
  "expliquer",
  "exploiter",
  "explosion",
  "extension",
  "extérieur",
  "fabricant",
  "fabriquer",
  "fabriqués",
  "faciliter",
  "facilités",
  "faiblesse",
  "faillites",
  "faisaient",
  "familiale",
  "familiaux",
  "fantaisie",
  "fascinant",
  "favorable",
  "favoriser",
  "favorites",
  "fermement",
  "fermeture",
  "fiabilité",
  "financier",
  "finissent",
  "fiscalité",
  "flamandes",
  "fonctions",
  "fondateur",
  "fondation",
  "forcément",
  "formation",
  "fortement",
  "fourneaux",
  "fragilité",
  "franchise",
  "fréquence",
  "fréquente",
  "fréquents",
  "fusionner",
  "garanties",
  "gendarmes",
  "générales",
  "généreuse",
  "générique",
  "génétique",
  "gourmande",
  "graphique",
  "gratuites",
  "grossesse",
  "habitants",
  "habitudes",
  "hautement",
  "héritiers",
  "histoires",
  "homologue",
  "identique",
  "immédiate",
  "immeubles",
  "impératif",
  "implantée",
  "impliqués",
  "important",
  "imposable",
  "inattendu",
  "incapable",
  "incertain",
  "indemnité",
  "indicatif",
  "indiquent",
  "indirecte",
  "individus",
  "industrie",
  "inférieur",
  "inflation",
  "influence",
  "influencé",
  "ingénieur",
  "injection",
  "installée",
  "installer",
  "installés",
  "instances",
  "instituts",
  "intégrale",
  "intégrées",
  "intention",
  "interdire",
  "interdite",
  "intéresse",
  "intéressé",
  "interface",
  "intérieur",
  "interrogé",
  "intervenu",
  "interview",
  "introduit",
  "invention",
  "investies",
  "irlandais",
  "italienne",
  "japonaise",
  "judicieux",
  "juridique",
  "justement",
  "justifiée",
  "justifier",
  "lancement",
  "largement",
  "lendemain",
  "lentement",
  "libellées",
  "libérales",
  "librairie",
  "librement",
  "liégeoise",
  "liquidité",
  "livraison",
  "locataire",
  "logements",
  "logiciels",
  "longévité",
  "longtemps",
  "lumineuse",
  "magazines",
  "magistrat",
  "maintenir",
  "maintenue",
  "maintient",
  "manifeste",
  "manifesté",
  "manipuler",
  "mannequin",
  "manoeuvre",
  "marchands",
  "marketing",
  "masculine",
  "matériaux",
  "matériels",
  "mauvaises",
  "mécanique",
  "mécanisme",
  "médicales",
  "médiocres",
  "meilleure",
  "meilleurs",
  "mensuelle",
  "mentalité",
  "merveille",
  "mexicaine",
  "militaire",
  "militants",
  "millésime",
  "milliards",
  "ministres",
  "mobiliers",
  "mobiliser",
  "modalités",
  "modernité",
  "molécules",
  "mondiales",
  "monétaire",
  "montagnes",
  "monuments",
  "mouvement",
  "moyennant",
  "multiples",
  "multiplie",
  "multiplié",
  "multitude",
  "musiciens",
  "musulmans",
  "naissance",
  "nationale",
  "nationaux",
  "naturelle",
  "néanmoins",
  "nécessite",
  "nécessité",
  "négatives",
  "nettement",
  "nettoyage",
  "nostalgie",
  "notamment",
  "notations",
  "notoriété",
  "nouveauté",
  "nouvelles",
  "nucléaire",
  "nullement",
  "numérique",
  "objectifs",
  "objective",
  "obstacles",
  "occasions",
  "occupants",
  "offensive",
  "officiels",
  "olympique",
  "opérateur",
  "opération",
  "optimisme",
  "optimiste",
  "ordinaire",
  "organisée",
  "organiser",
  "organisés",
  "organisme",
  "orientale",
  "originale",
  "originaux",
  "ouverture",
  "paiements",
  "parcourir",
  "parlement",
  "partagent",
  "participe",
  "participé",
  "partielle",
  "partisans",
  "passagers",
  "passionné",
  "patienter",
  "peintures",
  "percevoir",
  "pérennité",
  "permanent",
  "permettra",
  "permettre",
  "personnel",
  "personnes",
  "pétrolier",
  "pharmacie",
  "physiques",
  "pionniers",
  "placement",
  "plaignent",
  "plastique",
  "plusieurs",
  "polémique",
  "policiers",
  "politique",
  "pollution",
  "polyester",
  "populaire",
  "portables",
  "portraits",
  "portugais",
  "positions",
  "positives",
  "possédait",
  "possibles",
  "potentiel",
  "poursuite",
  "poursuivi",
  "pouvaient",
  "pratiquée",
  "pratiquer",
  "pratiques",
  "pratiqués",
  "préalable",
  "précédent",
  "précieuse",
  "précision",
  "précompte",
  "préférons",
  "préjudice",
  "prématuré",
  "préoccupe",
  "préparent",
  "présentée",
  "présenter",
  "présentes",
  "présentés",
  "préserver",
  "président",
  "pressions",
  "prétendre",
  "prévision",
  "prévoient",
  "prévoyait",
  "prévoyant",
  "prévoyons",
  "princesse",
  "principal",
  "principes",
  "printemps",
  "priorités",
  "procédure",
  "processus",
  "prochaine",
  "prochains",
  "procurent",
  "procureur",
  "produites",
  "profitent",
  "profitera",
  "profondes",
  "programme",
  "progresse",
  "progressé",
  "prolongée",
  "prolonger",
  "promenade",
  "promesses",
  "promoteur",
  "promotion",
  "prononcée",
  "prononcer",
  "proposait",
  "proposant",
  "proposées",
  "proposent",
  "proposera",
  "proposons",
  "propriété",
  "protocole",
  "prototype",
  "provenant",
  "provinces",
  "provision",
  "provoquer",
  "proximité",
  "publicité",
  "publiques",
  "puissance",
  "puissante",
  "puissants",
  "qualifiée",
  "qualifier",
  "qualifiés",
  "quantités",
  "quartiers",
  "quasiment",
  "questions",
  "quiconque",
  "quinzaine",
  "quotidien",
  "rachetant",
  "radiation",
  "raffinage",
  "rangement",
  "rapporter",
  "rapproche",
  "rassemble",
  "rassurant",
  "rattraper",
  "réactions",
  "réalisant",
  "réalisées",
  "réalisent",
  "réalistes",
  "récemment",
  "réception",
  "récession",
  "recevront",
  "recherche",
  "recherché",
  "réclament",
  "recueilli",
  "récupérer",
  "recyclage",
  "rédaction",
  "redevable",
  "redresser",
  "réduction",
  "réduisant",
  "réduisent",
  "référence",
  "réfléchir",
  "réflexion",
  "régionale",
  "régionaux",
  "regretter",
  "regrouper",
  "réguliers",
  "rejoindre",
  "relations",
  "relatives",
  "religieux",
  "remarquer",
  "remarques",
  "remboursé",
  "remontent",
  "remplacer",
  "remplacés",
  "rencontre",
  "rencontré",
  "rendement",
  "renforcée",
  "renforcer",
  "renouveau",
  "rentables",
  "réparties",
  "répondant",
  "répondent",
  "reportage",
  "reprenant",
  "reprendre",
  "repreneur",
  "reprocher",
  "réservées",
  "réservoir",
  "résidence",
  "résistant",
  "respecter",
  "ressemble",
  "restaurer",
  "resteront",
  "restreint",
  "résultant",
  "résultats",
  "retiendra",
  "retombées",
  "retourner",
  "retrouvée",
  "retrouver",
  "reviendra",
  "richesses",
  "rigoureux",
  "ristourne",
  "riverains",
  "roulement",
  "salariale",
  "salariaux",
  "sanctions",
  "satellite",
  "satisfait",
  "sauvetage",
  "savoureux",
  "scandales",
  "scénarios",
  "scolaires",
  "sculpteur",
  "sculpture",
  "séduction",
  "séduisant",
  "sélection",
  "semblable",
  "séminaire",
  "sensation",
  "sensibles",
  "sentiment",
  "septembre",
  "séquences",
  "sérieuses",
  "seulement",
  "signature",
  "similaire",
  "situation",
  "solitaire",
  "sollicité",
  "solutions",
  "somptueux",
  "souffrent",
  "souligner",
  "soumettre",
  "souplesse",
  "souscrire",
  "souscrite",
  "souvenirs",
  "spéciales",
  "spectacle",
  "sportives",
  "stabilité",
  "standards",
  "stratégie",
  "structure",
  "subissent",
  "substance",
  "suffirait",
  "suffisait",
  "suffisant",
  "suffisent",
  "suivantes",
  "supérieur",
  "supporter",
  "supprimer",
  "surmonter",
  "surprises",
  "survivant",
  "suscitent",
  "sympathie",
  "syndicale",
  "syndicats",
  "syndicaux",
  "synergies",
  "technique",
  "téléphone",
  "télétexte",
  "télévisée",
  "tellement",
  "tendances",
  "tendresse",
  "tentation",
  "tentative",
  "terminaux",
  "théorique",
  "thermique",
  "titulaire",
  "tolérance",
  "touristes",
  "toutefois",
  "tradition",
  "transfert",
  "transport",
  "travaille",
  "travaillé",
  "traversée",
  "traverser",
  "trentaine",
  "tribunaux",
  "trimestre",
  "trouverez",
  "universel",
  "utilisant",
  "utilisées",
  "utilisent",
  "vainqueur",
  "valoriser",
  "variables",
  "variantes",
  "variation",
  "véhicules",
  "véritable",
  "versement",
  "verticale",
  "viendront",
  "vingtaine",
  "virtuelle",
  "visiteurs",
  "vitamines",
  "voulaient",
  "voyageurs",
];
