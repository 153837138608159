/* global Bliss */

import _assign from "lodash/assign";
import _get from "lodash/get";
import _set from "lodash/set";
import _isUndefined from "lodash/isUndefined";

import { isValidURL, isExternalUrl } from "helpers/url_helper";
import { formatMailTo } from "helpers/string_helper";

import "./link_inline.scss";

export default class LinkInline {
  static title = "link-inline";

  static get isInline() {
    return true;
  }

  static get icons() {
    return {
      link: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/link.svg"),
      unlink: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/unlink.svg"),
      check: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/check.svg"),
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
    };
  }

  static get sanitize() {
    return {
      a: {
        href: true,
        target: "_blank",
        rel: "noopener",
        title: "Ouverture dans un nouvel onglet",
      },
    };
  }

  get shortcut() {
    return "CMD+K";
  }

  constructor({ api, config }) {
    this.api = api;
    this.config = this._parseConfig(config);

    this._selectors = {
      actions: "pe-link-inline",
      actionsInput: "pe-link-inline__input",
      actionsButton: "pe-link-inline__button",
    };

    this._buttonElem = null;
    this._actionsElem = null;
    this._savedSelectionRange = null;
    // this._fakeHighlight = false;
  }

  // create button
  render() {
    this._buttonElem = Bliss.create({
      tag: "button",
      type: "button",
      class: this.api.styles.inlineToolButton,
      innerHTML: LinkInline.icons.link + LinkInline.icons.unlink,
    });

    return this._buttonElem;
  }

  // create actions
  renderActions() {
    this._actionsElem = Bliss.create({
      tag: "div",
      class: this._selectors.actions,
      contents: [
        {
          tag: "input",
          type: "text",
          placeholder: this.config.placeholder,
          class: this._selectors.actionsInput,
          events: { keydown: this._submit.bind(this) },
        },
        {
          tag: "button",
          type: "button",
          class: this._selectors.actionsButton,
          innerHTML: LinkInline.icons.check,
          events: { click: this._submit.bind(this) },
        },
      ],
    });

    return this._actionsElem;
  }

  // tool button is clicked
  surround(_) {
    if (this.hasActions) {
      // if tool's actions are activated, restore a possibly lost selection
      this._restoreSelection();
    } else {
      // else save selection before activating them
      // the selection is saved creating a fake highlight to keep track of the saved selection
      // however, removing the fake highlight will remove bold or italic applied inside the selection
      this._saveSelection(true);
    }

    const anchorElem = this.api.selection.findParentTag("A");
    const hasAnchor = Boolean(anchorElem);

    // if selection has an A tag, just unlink and close inline toolbar
    // actions are cleared in the clear function
    if (hasAnchor) {
      this.api.selection.expandToTag(anchorElem);
      this._unlink(anchorElem);
      this.api.inlineToolbar.close();
      return;
    }

    // show/hide actions
    this._toggleActions();
  }

  // determine tool state when selection happens
  checkState(_) {
    const anchorElem = this.api.selection.findParentTag("A");
    const hasAnchor = Boolean(anchorElem);

    // if selection is inside an A tag
    if (hasAnchor) {
      // show tool's actions
      this._toggleActions(hasAnchor);

      // initialize input
      const anchorHref = anchorElem.getAttribute("href");
      if (anchorHref) {
        _set(this._actionsElem.querySelector("." + this._selectors.actionsInput), "value", anchorHref);
      }

      // save selection in case it is an href update, no state toggling
      this._saveSelection();
    }

    return hasAnchor;
  }

  // clear seems to be called twice each time...
  clear() {
    this._toggleActions(false);
  }

  // GETTERS & SETTERS

  get hasActions() {
    return this._actionsElem.classList.contains("active");
  }

  // PRIVATE
  _parseConfig(config) {
    const assignedConfig = _assign({}, LinkInline.defaultConfig, config);

    return assignedConfig;
  }

  _saveSelection(/* mark */) {
    if (this._savedSelectionRange) {
      return;
    }

    // add fake hightlight because selection will be lost on input focus
    // if (mark) {
    //   this._fakeHighlight = true;
    //   document.execCommand("backColor", false, "#354BCF30"); // #RRGGBBAA (rgba($ceruleanblue, 0.3))
    // }

    // save current range
    const selection = window.getSelection();
    this._savedSelectionRange = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  }

  _restoreSelection() {
    if (!this._savedSelectionRange) {
      return;
    }

    // set selection to saved range
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(this._savedSelectionRange);

    // remove fake hightlight
    // if (this._fakeHighlight) {
    //   this._fakeHighlight = false;
    //   document.execCommand("removeFormat");
    // }
  }

  _collapseSelection() {
    const selection = window.getSelection();
    const range = document.createRange();

    range.selectNodeContents(selection.focusNode);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  // _cleanFakeHighlight() {
  //   if (!this._fakeHighlight) {
  //     return;
  //   }

  //   // save current range
  //   const currentSelection = window.getSelection();
  //   const currentRange = currentSelection && currentSelection.rangeCount > 0 ? currentSelection.getRangeAt(0) : null;

  //   // restore and clean previous selection
  //   this._restoreSelection();

  //   // restore current selection
  //   const restoredSelection = window.getSelection();
  //   restoredSelection.removeAllRanges();
  //   restoredSelection.addRange(currentRange);
  // }

  _toggleActions(force) {
    const showActions = this._actionsElem.classList.toggle("active", force);
    this._buttonElem.classList.toggle(this.api.styles.inlineToolButtonActive, showActions);

    if (!showActions) {
      // re-initialize input
      const actionsInput = this._actionsElem.querySelector("." + this._selectors.actionsInput);
      _set(actionsInput, "value", null);
      actionsInput.classList.remove("is-invalid");
    }

    // clear selection only if action is forced to false (not undefined)
    if (force === false) {
      // this._cleanFakeHighlight();
      this._savedSelectionRange = null;
    }

    // focus input if link is activeted and not already set
    if (showActions && _isUndefined(force)) {
      const actionsInput = this._actionsElem.querySelector("." + this._selectors.actionsInput);
      actionsInput.focus();
    }
  }

  _validate() {
    const actionsInput = this._actionsElem.querySelector("." + this._selectors.actionsInput);
    const href = formatMailTo(_get(actionsInput, "value", "").trim());

    // validate url
    if (!isValidURL(href)) {
      actionsInput.classList.add("is-invalid");
      return;
    }

    actionsInput.classList.remove("is-invalid");

    // make sur to have to text selected, then create the link, then close the selection
    this._restoreSelection();
    this._createLink(href);
    this._collapseSelection();
    this.api.inlineToolbar.close();
  }

  _createLink(href) {
    // retrieve anchor element if it's an update
    let anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      // update href attribute
      anchorElem.setAttribute("href", href);
    } else {
      // make sure there are no sub-links
      this._unlink();

      // create anchor element
      document.execCommand("createLink", false, href);

      // find created element
      anchorElem = this.api.selection.findParentTag("A");
      if (!anchorElem) {
        const selection = window.getSelection();
        const nextAnchor = _get(selection, "anchorNode.nextElementSibling");
        const prevAnchor = _get(selection, "focusNode.previousElementSibling");
        anchorElem = nextAnchor || prevAnchor;
      }
    }

    // update target attributes
    if (isExternalUrl(href)) {
      anchorElem.setAttribute("target", "_blank");
      anchorElem.setAttribute("rel", "noopener");
      anchorElem.setAttribute("title", "Ouverture dans un nouvel onglet");
    } else {
      anchorElem.removeAttribute("target");
      anchorElem.removeAttribute("title");
      anchorElem.removeAttribute("rel");
    }

    this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
  }

  _unlink(anchorElem) {
    if (anchorElem) {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    }

    document.execCommand("unlink");
  }

  // LISTENERS

  _submit(event) {
    // validate the link on button click or enter press inside input
    const shouldSubmit = (event.type === "keydown" && event.keyCode === 13) || event.type === "click";

    if (!shouldSubmit) {
      return;
    }

    this._stopEverything(event);
    this._validate();
  }

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }

  _stopEverything(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }
}
