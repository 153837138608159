/* global Bliss */

// polyfills
import "promise-polyfill/src/polyfill";
import "whatwg-fetch";
// import "fetch-jsonp";

import _assign from "lodash/assign";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _get from "lodash/get";
import _has from "lodash/has";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import _isString from "lodash/isString";
import _pick from "lodash/pick";

import { stringifyUrlSearch } from "../../helpers/url_helper";

import EMBED_CONFIG from "./embed_config.js";

import "./video_block.scss";

export default class VideoBlock {
  // ImageBlock data schema
  // {
  //   url: < string: url, required >,
  //   ratio: < float, required >,
  //   thumbnail: < string: url>
  // }

  static get icons() {
    return {
      play: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/play-circle.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "video",
      icon: VideoBlock.icons.play,
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
      ratio: 1.78, // default ratio to 16/9
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      url: true,
      ratio: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-video",
      iframeWrapper: "pe-video-wrapper",
      label: "pe-video-label",
      input: "pe-video-input",
      feedback: "pe-video-feedback",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    let blockData = this._normalizeData({});

    const videoElem = blockContent.querySelector("." + this._selectors.iframeWrapper);
    if (videoElem) {
      try {
        blockData = JSON.parse(videoElem.getAttribute("data-video-params"));
        blockData = _pick(blockData, ["url", "ratio", "thumbnail"]);
      } catch (err) {
        console.error(err);
      }
    }

    // missing video url then block is in error
    if (!_has(blockData, "url")) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["vidéo manquante"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_blockData) {
    return true;
  }

  // GETTERS & SETTERS

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["url", "ratio", "thumbnail"]);

    data.ratio = _get(data, "ratio", this.config.ratio);

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, VideoBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "div",
      className: this._selectors.block,
      contents: [],
    };

    // input options
    const inputOptions = {
      tag: "input",
      type: "text",
      placeholder: this.config.placeholder,
      className: [this._selectors.input, this.api.styles.input].join(" "),
      events: {
        keydown: this._disableEnter.bind(this),
        input: _debounce(this._updateInput.bind(this), 300),
      },
    };

    // label options
    const labelOptions = {
      tag: "label",
      className: this._selectors.label,
      contents: [
        {
          tag: "span",
          innerText: this.api.i18n.t("label"),
        },
        inputOptions,
        {
          tag: "span",
          className: this._selectors.feedback,
          innerText: this.api.i18n.t("feedback"),
        },
      ],
    };

    // insert iframe and input options
    blockElemOptions.contents.push(this._iframeOptions(blockData));
    if (!this.readOnly) {
      blockElemOptions.contents.push(labelOptions);
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _insertVideoElem(blockData) {
    // create new video elem
    const newVideoElem = Bliss.create(this._iframeOptions(blockData));

    // replace video elem in DOM
    this._blockElem.replaceChild(newVideoElem, this._blockElem.firstChild);
  }

  _iframeOptions(iframeData) {
    const iframeOptions = {
      tag: "div",
      class: this._selectors.iframeWrapper,
      "data-video-params": JSON.stringify(iframeData),
      style: { "padding-top": 0 }, // initialize empty video wrapper
    };

    if (_get(iframeData, "url")) {
      iframeOptions.style = {
        "padding-top": (100 / iframeData.ratio).toFixed(2) + "%",
      };
      iframeOptions.contents = [
        {
          tag: "iframe",
          src: iframeData.url,
          frameborder: 0,
          width: 800,
          height: Math.floor(800 / iframeData.ratio),
          allowfullscreen: true,
          marginheight: 0,
          marginwidth: 0,
          scrolling: "no",
        },
      ];
    }

    return iframeOptions;
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  _updateInput(_) {
    const inputElem = this._blockElem.querySelector("." + this._selectors.input);
    inputElem.classList.add("is-loading");
    inputElem.classList.remove(this._selectors.input + "--invalid");

    this._fetchEmbedPromise(_get(inputElem, "value"))
      .then((videoData) => {
        this._insertVideoElem(videoData);
      })
      .catch((err) => {
        inputElem.classList.add(this._selectors.input + "--invalid");
        console.error(err);
      })
      .finally((_) => {
        inputElem.classList.remove("is-loading");
      });
  }

  _fetchEmbedPromise(url) {
    return new Promise((resolve, reject) => {
      let embedId;
      const vendor = _find(EMBED_CONFIG, (vendor) => {
        return _find(vendor.re, (re) => {
          const result = re.exec(url);

          // not matching
          if (!_isArray(result)) {
            return false;
          }

          // keep embedId from regexp
          if (result.length > 1) {
            embedId = result[1];
          }

          return true;
        });
      });

      // no vendor associated with url
      if (_isNil(vendor)) {
        reject(new Error("Invalid url"));
        return;
      }

      // no oEmbed endpoint for the vendor, we generate url on pattern
      if (_isFunction(vendor.url)) {
        const videoData = {
          url: vendor.url(embedId),
          ratio: vendor.ratio || this.config.ratio,
        };

        if (_isFunction(vendor.thumbnail)) {
          videoData.thumbnail = vendor.thumbnail(embedId);
        }

        resolve(videoData);
        return;
      }

      const params = {
        url: vendor.config.url,
        data: {
          url: url,
        },
      };

      // let fetchFn = window.fetch;

      // if (vendor.config.dataType == 'jsonp') {
      //   let fetchFn = window.fetchJsonp;
      //   // params.dataType = vendor.config.dataType;
      // }

      window
        .fetch(params.url + "?" + stringifyUrlSearch(params.data))
        .then((response) => response.json())
        .then((data) => {
          if (!data || !_isString(data.html)) {
            reject();
            return;
          }

          const videoData = {
            url: _get(data.html.match(/src="([^"]*)"/i), "[1]"),
            ratio: this.config.ratio,
          };

          const height = _get(data, "height");
          const width = _get(data, "width");

          if (height && width) {
            videoData.ratio = (width / height).toFixed(2);
          }

          const thumbnail = _get(data, "thumbnail_url");
          if (thumbnail) {
            videoData.thumbnail = thumbnail;
          }

          resolve(videoData);
        })
        .catch(reject);
    });
  }
}
