import _get from "lodash/get";
import _map from "lodash/map";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["items", "template"];
  static values = { newId: Number };

  // LIFECYCLE

  initialize() {
    this.selectors = {
      relationshipItem: ".relationship-item",
    };
  }

  connect() {}

  // ACTIONS

  addItem(event) {
    const id = _get(event, "detail.id");
    const items = _map(
      this.itemsTarget.querySelectorAll(this.selectors.relationshipItem + ' input[type="hidden"]'),
      "value"
    );

    if (items.includes(id.toString())) {
      return;
    }

    const item = this.templateTarget.innerHTML
      .replace(new RegExp(this.newIdValue, "g"), id)
      .replace(/NEW_TITLE/g, _get(event, "detail.title"));
    this.itemsTarget.insertAdjacentHTML("beforeend", item);
  }

  removeItem(event) {
    event.preventDefault();

    const item = event.target.closest(this.selectors.relationshipItem);
    item.remove();
  }
}
