/* global Bliss */

import _assign from "lodash/assign";
import _get from "lodash/get";

import "./text_block.scss";

export default class TextBlock {
  // TextBlock data schema
  // {
  //   text: < string: inline HTML, required >,
  // }

  static get toolbox() {
    return {
      title: "text",
      icon: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/paragraph.svg"),
    };
  }

  static get pasteConfig() {
    return {
      tags: ["P"],
    };
  }

  static get conversionConfig() {
    return {
      export: "text",
      import: "text",
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-text",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    // add edit listener to links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    });

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    return {
      text: blockContent.innerHTML,
    };
  }

  validate(blockData) {
    return blockData.text.trim() !== "";
  }

  destroy() {
    // remove edit listeners on links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.off(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  onPaste(event) {
    const html = _get(event, "detail.data.innerHTML");

    if (html) {
      this._blockElem.innerHTML = html;
    }
  }

  merge(data) {
    this._blockElem.innerHTML += data.text;
  }

  // PRIVATE

  _normalizeData(data) {
    if (typeof data !== "object") {
      data = {};
    }

    data.text = data.text || "";

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, TextBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    const blockElemOptions = {
      tag: "p",
      class: this._selectors.block + " text",
      contenteditable: !this.readOnly,
      "data-placeholder": this.config.placeholder,
      innerHTML: blockData.text,
    };

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  // LISTENERS

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }
}
