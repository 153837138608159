import _get from "lodash/get";
import _set from "lodash/set";

import { Controller } from "stimulus";
// import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  initialize() {
    this.expando = "_parisData";
  }

  connect() {
    // StimulusReflex.register(this);
  }

  // Get feedback DOM element
  get feedbackElem() {
    return document.getElementById("manage-feedback");
  }

  // Get feedback controller
  get feedback() {
    return this.application.getControllerForElementAndIdentifier(this.feedbackElem, "feedback");
  }

  // Get & Set data to DOM element similarly to RailsUJS
  // https://github.com/rails/rails/blob/main/actionview/app/assets/javascripts/rails-ujs/utils/dom.coffee
  getData(element, key, defaultValue) {
    return _get(element, [this.expando, key], defaultValue);
  }

  setData(element, key, value) {
    return _set(element, [this.expando, key], value);
  }

  deleteData(element, key) {
    delete element[this.expando][key];
  }

  // Get controller associated to element with the specific identifier
  getControllerForIdentifierContainingElement(identifier, element) {
    if (!element || typeof element.closest !== "function") {
      throw new Error("Missing DOM element");
    }

    const controllerElement = element.closest(`[${this.application.schema.controllerAttribute}~="${identifier}"]`);
    return this.application.getControllerForElementAndIdentifier(controllerElement, identifier);
  }

  // Stop the propagation of the event
  stopEventPropagation(event) {
    if (!event) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  loading(toggle) {
    document.body.classList.toggle("loading", toggle);
  }

  // beforeReflex(_element, _reflex, _noop, _reflexId) {
  //   // document.body.classList.add('loading')
  // }

  // reflexSuccess(_element, _reflex, _noop, _reflexId) {
  //   // show success message
  // }

  // reflexError(_element, _reflex, _error, _reflexId) {
  //   // show error message
  // }

  // reflexHalted(_element, _reflex, _error, _reflexId) {
  //   // handle aborted Reflex action
  // }

  // afterReflex(_element, _reflex, _noop, _reflexId) {
  //   // document.body.classList.remove('loading')
  // }

  // finalizeReflex(_element, _reflex, _noop, _reflexId) {
  //   // all operations have completed, animation etc is now safe
  // }
}
