import { Toast } from "bootstrap";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toast"];

  connect() {
    this.toastsList = this.toastTargets.map((toast) => {
      const t = new Toast(toast);
      t.show();
      return t;
    });
  }
}
