/* global Bliss */

export default class BoldInline {
  static title = "bold-inline";

  static get isInline() {
    return true;
  }

  static get icons() {
    return {
      bold: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/bold.svg"),
    };
  }

  static get sanitize() {
    return {
      b: {},
      strong: {},
    };
  }

  get shortcut() {
    return "CMD+B";
  }

  constructor({ api, _ }) {
    this.api = api;

    this._buttonElem = null;
  }

  // create button
  render() {
    this._buttonElem = Bliss.create({
      tag: "button",
      type: "button",
      class: this.api.styles.inlineToolButton,
      innerHTML: BoldInline.icons.bold,
    });

    return this._buttonElem;
  }

  // tool button is clicked
  surround(_) {
    document.execCommand("bold");
  }

  // determine tool state when selection happens
  checkState(_) {
    const isActive = document.queryCommandState("bold");

    this._buttonElem.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}
