/* global Bliss */

import _get from "lodash/get";
import _isString from "lodash/isString";
import _pick from "lodash/pick";

import "./verbatim_block.scss";

export default class VerbatimBlock {
  // VerbatimBlock data schema
  // {
  //   text: < string: inline HTML, required >,
  //   author: < string >,
  //   position: < string >
  // }

  static get icons() {
    return {
      quote: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/quote-left.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "verbatim",
      icon: VerbatimBlock.icons.quote,
    };
  }

  static get conversionConfig() {
    return {
      export: "text",
      import: "text",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      text: {
        br: true,
      },
      author: true,
      position: true,
    };
  }

  constructor({ data, _config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-verbatim",
      text: "pe-verbatim-text",
      author: "pe-verbatim-author",
      position: "pe-verbatim-position",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const textElem = blockContent.querySelector("." + this._selectors.text);
    const authorElem = blockContent.querySelector("." + this._selectors.author);
    const positionElem = blockContent.querySelector("." + this._selectors.position);
    const blockData = {
      text: _get(textElem, "innerHTML", "").replace(/<br>$/, ""),
      author: _get(authorElem, "value", ""),
      position: _get(positionElem, "value", ""),
    };

    // empty text then block is in error
    if (_get(textElem, "textContent", "").trim() === "") {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["texte manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["text", "author", "position"]);

    data.text = this._forceInlineHTML(data.text);
    data.author = _isString(data.author) ? data.author : "";
    data.position = _isString(data.position) ? data.position : "";

    return data;
  }

  _forceInlineHTML(string) {
    if (!string) {
      return "";
    }

    // replace div and p by br
    return String(string)
      .replace(/<(div|p)[^>]*><br><\/?(div|p)[^>]?>/g, "<br>")
      .replace(/(<\/?(div|p)[^>]*>)+/g, "<br>");
  }

  _buildBlockElem(blockData) {
    const blockElemOptions = {
      tag: "div",
      class: this._selectors.block + " verbatim",
      contents: [
        {
          tag: "p",
          class: this._selectors.text + " verbatim-text",
          "data-placeholder": "citation... »",
          contenteditable: !this.readOnly,
          innerHTML: blockData.text,
        },
      ],
    };

    const authorOptions = {
      tag: "input",
      type: "text",
      class: this._selectors.author + " verbatim-author",
      placeholder: "auteur...",
      value: blockData.author,
      events: { keydown: this._disableEnter.bind(this) },
    };

    const positionOptions = {
      tag: "input",
      type: "text",
      class: this._selectors.position + " verbatim-position",
      placeholder: "position...",
      value: blockData.position,
      events: { keydown: this._disableEnter.bind(this) },
    };

    if (this.readOnly) {
      authorOptions.readonly = true;
      positionOptions.readonly = true;
    }

    blockElemOptions.contents.push(authorOptions, positionOptions);

    return Bliss.create(blockElemOptions);
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
