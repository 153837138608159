import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "checkEmailInput",
    "rememberCheckbox",
    "url",
    "noticeContainer",
    "provideEmailForm",
    "pageTitle",
    "passwordField",
    "eyeOpen",
    "eyeClosed",
  ];

  connect() {
    if (!this.hasCheckEmailInputTarget) {
      return;
    }
    if (this.pageTitleTarget.innerText === "Mot de passe oublié") {
      if (this.checkEmailInputTarget.parentElement.querySelector(".invalid-feedback")) {
        this.noticeContainerTarget.classList.remove("hidden");
        this.provideEmailFormTarget.classList.add("hidden");
      } else {
        this.provideEmailFormTarget.classList.remove("hidden");
      }
    }
  }

  toggleRememberCheckbox() {
    this.rememberCheckboxTarget.checked = !this.rememberCheckboxTarget.checked;
  }

  handleEmailChange(event) {
    if (this.hasUrlTarget) {
      let email = event.target.value;
      let href = this.urlTarget.href.split("?")[0];
      this.urlTarget.href = href + "?user[email]=" + email;
    }
  }

  showPassword() {
    this.passwordFieldTarget.type = "text";
  }

  hidePassword() {
    this.passwordFieldTarget.type = "password";
  }

  handleIconClick() {
    this.eyeOpenTarget.classList.toggle("hidden");
    this.eyeClosedTarget.classList.toggle("hidden");
  }
}
