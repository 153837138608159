/* global Bliss */
import _find from "lodash/find";
import _get from "lodash/get";
import { createPopper } from "@popperjs/core";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["popover", "template"];

  // LIFECYLE

  initialize() {
    this.selectors = {
      onConnectItem: '[data-popover-on-connect="true"]',
    };
  }

  connect() {
    this.element.querySelectorAll(this.selectors.onConnectItem).forEach((onConnectElement) => {
      this._show(onConnectElement);
    });
  }

  // ACTIONS

  show(event) {
    this._show(event.currentTarget);
  }

  hide(_event) {
    if (!this.hasPopoverTarget) {
      return;
    }

    this.popoverTarget.remove();
  }

  // PRIVATE

  _show(target) {
    const content = this._popoverContent(target);

    if (!content) {
      return;
    }

    this._createPopover(target, content);
  }

  _popoverContent(target) {
    if (!target) {
      return "";
    }

    let content = _get(target, "dataset.popoverContent");
    content ||= this.data.get("content");

    if (content || !this.hasTemplateTarget) {
      return content || "";
    }

    let templateTarget;
    const templateTargetId = _get(
      target.closest("[data-popover-template-target-id]"),
      "dataset.popoverTemplateTargetId"
    );
    if (templateTargetId) {
      templateTarget = _find(this.templateTargets, ["dataset.popoverTemplateId", templateTargetId]);
    }

    templateTarget ||= this.templateTarget;

    return templateTarget.innerHTML || "";
  }

  _createPopover(target, content) {
    if (!target) {
      return;
    }

    const popoverArrowElem = Bliss.create({ tag: "div", className: "popover-arrow" });
    const popoverElem = Bliss.create({
      tag: "div",
      role: "tooltip",
      className: "popover popover-bo",
      "data-popover-target": "popover",
      contents: [popoverArrowElem, { tag: "div", className: "popover-body", innerHTML: content }],
    });

    this.element.appendChild(popoverElem);

    createPopper(target, popoverElem, {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "arrow",
          options: {
            element: popoverArrowElem,
          },
        },
      ],
    });
  }
}
