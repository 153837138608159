import ApplicationController from "./application_controller";
import zxcvbn from "zxcvbn";

export default class extends ApplicationController {
  static targets = [
    "passwordField",
    "eyeOpen",
    "eyeClosed",
    "passwordCheckbox",
    "passwordInputContainer",
    "passwordStrength",
    "strengthZero",
    "strengthOne",
    "strengthTwo",
    "strengthThree",
    "strengthFour",
    "newPasswordField",
  ];

  connect() {
    if (this.passwordCheckboxTarget.dataset.hasCheckboxTarget === "false") {
      this.generatePassword();
      this.showThatPasswordIsStrong();
    }
  }

  generatePassword() {
    let newPassword = this.newPasswordFieldTarget;

    let wordsMin = 3;
    let wordsMax = 3;
    let wordlist = require("../helpers/wordlist");
    let charlist = ["$", "€", "%", "&", "@", "#", "?", "!", "*"];
    // let wordlist = ["pierre", "feuille", "ciseau"];
    let wordlistCount = wordlist.length;
    let wordsCount = Math.round(Math.random() * (wordsMax - wordsMin) + wordsMin);
    let array = new Uint32Array(wordsCount);
    let crypto = window.crypto || window.msCrypto;

    crypto.getRandomValues(array);

    let generatedPasswordArray = [];
    let remainingWords = wordsCount;
    let usedDigitOrChar = 0;

    // Grab a random word, push it to the password array
    for (let i = 0; i < array.length; i++) {
      let index = array[i] % wordlistCount;
      let word = wordlist[index];
      let addDigitOrChar = false;

      // Add digit or special char, based on number already used
      if (remainingWords == 1) {
        // One word left to process
        if (usedDigitOrChar <= 1 || Math.random() > 0.8) addDigitOrChar = true;
      } else if (remainingWords <= Math.floor(wordsCount / 2)) {
        // Less than hald words
        if (Math.random() > 0.5) addDigitOrChar = true;
      } else {
        // More than hald ords remaining
        if (Math.random() > 0.8) addDigitOrChar = true;
      }

      if (addDigitOrChar) {
        if (Math.random() > 0.7)
          word =
            Math.round(Math.random() * 10).toString() + charlist[Math.floor(Math.random() * charlist.length)] + word;
        else if (Math.random() > 0.6)
          word += charlist[Math.floor(Math.random() * charlist.length)] + Math.round(Math.random() * 10).toString();
        else word += Math.round(Math.random() * 10).toString();
        usedDigitOrChar++;
      }

      remainingWords--;

      generatedPasswordArray.push(word);
    }

    // Glue
    let glue = glue || [" ", "-"][Math.round(Math.random())];
    let generatedPassword = generatedPasswordArray.join(glue);

    newPassword.value = generatedPassword;
  }

  showPassword() {
    this.passwordFieldTarget.type = "text";
  }

  hidePassword() {
    this.passwordFieldTarget.type = "password";
  }

  handleIconClick() {
    this.eyeOpenTarget.classList.toggle("hidden");
    this.eyeClosedTarget.classList.toggle("hidden");
  }

  showThatPasswordIsStrong() {
    let passwordStrengthDiv = this.passwordStrengthTarget;
    passwordStrengthDiv.classList.remove("d-none");
    passwordStrengthDiv.classList.add("d-block");
    this.strengthFourTarget.classList.add("d-inline");
  }

  handleNewPassword(event) {
    const password = event.target.value;
    let passwordStrengthDiv = this.passwordStrengthTarget;

    if (event.target.value === "") {
      passwordStrengthDiv.classList.remove("d-block");
      passwordStrengthDiv.classList.add("d-none");
      return;
    }

    passwordStrengthDiv.classList.remove("d-none");
    passwordStrengthDiv.classList.add("d-block");
    const evaluation = zxcvbn(password);
    let zero = this.strengthZeroTarget;
    let one = this.strengthOneTarget;
    let two = this.strengthTwoTarget;
    let three = this.strengthThreeTarget;
    let four = this.strengthFourTarget;

    const hideAllScore = () => {
      zero.classList.remove("d-inline");
      zero.classList.add("d-none");
      one.classList.remove("d-inline");
      one.classList.add("d-none");
      two.classList.remove("d-inline");
      two.classList.add("d-none");
      three.classList.remove("d-inline");
      three.classList.add("d-none");
      four.classList.remove("d-inline");
      four.classList.add("d-none");
    };

    if (evaluation.score === 0) {
      hideAllScore();
      zero.classList.add("d-inline");
    } else if (evaluation.score === 1) {
      hideAllScore();
      one.classList.add("d-inline");
    } else if (evaluation.score === 2) {
      hideAllScore();
      two.classList.add("d-inline");
    } else if (evaluation.score === 3) {
      hideAllScore();
      three.classList.add("d-inline");
    } else if (evaluation.score === 4) {
      hideAllScore();
      four.classList.add("d-inline");
    }
  }

  handlePasswordCheckbox() {
    if (this.passwordCheckboxTarget.checked) {
      this.passwordFieldTarget.value = "";
    } else {
      this.generatePassword();
      this.showThatPasswordIsStrong();
    }

    this.passwordInputContainerTarget.classList.toggle("hidden");
  }
}
