import ApplicationController from "./application_controller";

import EditorJS from "@editorjs/editorjs";

// inline
import BoldInline from "editor/bold_inline/bold_inline";
import ItalicInline from "editor/italic_inline/italic_inline";

// block
import TextBlock from "editor/text_block/text_block";
import ListBlock from "editor/list_block/list_block";

export default class extends ApplicationController {
  static targets = ["holder", "blocks"];
  static values = { readOnly: Boolean };

  // LIFECYCLE

  initialize() {
    this.editorError = { message: "", data: [] };
  }

  connect() {
    this.initEditor();
  }

  disconnect() {
    // if (this.editor instanceof EditorJS) {
    //   this.editor.destroy();
    // }
  }

  initEditor(data) {
    let editorData = data || {};

    if (this.hasBlocksTarget) {
      editorData = this._getEditorData();
    }

    this.holderTarget.classList.toggle("is-readonly", this.readOnlyValue);

    this.editor = new EditorJS({
      holder: this.holderTarget,
      placeholder: "Votre texte...",
      defaultBlock: "text",
      inlineToolbar: ["bold-inline", "italic-inline"],
      tools: this.toolsConfig,
      // NOTE i18n dictionary is shared between EditorJS instances : https://github.com/codex-team/editor.js/issues/1461
      // i18n: this.i18nConfig,
      data: editorData,
      minHeight: 20,
      logLevel: "ERROR",
      onChange: () => {
        let content = "";
        this.holderTarget.querySelectorAll(".ce-block__content").forEach((block) => {
          content += this._editor_to_html(block.firstChild);
        });
        this.blocksTarget.value = content;
      },
    });
  }

  render(data, readOnly) {
    readOnly = readOnly || false;

    this.editor.destroy();
    this.readOnlyValue = readOnly;
    this.initEditor(data);
  }

  // GETTERS & SETTERS

  get toolsConfig() {
    const toolsConfig = {
      // INLINE
      "bold-inline": {
        class: BoldInline,
        shortcut: "CMD+B",
      },
      "italic-inline": {
        class: ItalicInline,
        shortcut: "CMD+I",
      },
      // BLOCK
      text: {
        class: TextBlock,
        inlineToolbar: true,
      },
      list: {
        class: ListBlock,
        inlineToolbar: true,
      },
    };

    return toolsConfig;
  }

  // PRIVATE

  _editor_to_html(block) {
    return `<${block.nodeName.toLowerCase()}>${block.innerHTML}</${block.nodeName.toLowerCase()}>`;
  }

  _getEditorData() {
    let editorData = {
      time: Math.floor(Date.now()),
      version: "2.19.1",
      blocks: [],
    };

    const parser = new DOMParser();
    const doc = parser.parseFromString(this.blocksTarget.value, "text/html");
    const nodes = doc.getElementsByTagName("*");

    if (this._isHtmlString(this.blocksTarget.value)) {
      for (let i in nodes) {
        let node = nodes[i];

        if (node.nodeName == "P") {
          editorData.blocks.push(this._getEditorTextItem(node.innerHTML));
        } else if (node.nodeName == "UL" || node.nodeName == "OL") {
          editorData.blocks.push(this._getEditorListItem(node, node.nodeName == "OL"));
        }
      }
    } else {
      // If we have old data string in db
      editorData.blocks.push(this._getEditorTextItem(this.blocksTarget.value));
    }

    return editorData;
  }

  _isHtmlString(str) {
    return /(<([^>]+)>)/i.test(str);
  }

  _getEditorTextItem(text) {
    return {
      type: "text",
      data: {
        text: text,
      },
    };
  }

  _getEditorListItem(node, ordered) {
    let listItem = {
      type: "list",
      data: {
        items: [],
        style: ordered ? "ol" : "ul",
      },
    };

    node.querySelectorAll("li").forEach((li) => {
      listItem.data.items.push(li.innerHTML);
    });
    return listItem;
  }
}
