/* global Bliss */

import _assign from "lodash/assign";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isString from "lodash/isString";
import _pick from "lodash/pick";

import { isValidURL } from "helpers/url_helper";
import EMBED_CONFIG from "../video_block/embed_config.js";

import "./iframe_block.scss";

export default class IframeBlock {
  // IframeBlock data schema
  // {
  //   html: < string: HTML >,
  //   modifiers: [< string: "is-video">, ...]
  // }

  static get icons() {
    return {
      code: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/code.svg"),
      video: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/play-circle.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "iframe",
      icon: IframeBlock.icons.code,
    };
  }

  static get defaultConfig() {
    return {
      readOnly: true,
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      html: {
        iframe: true,
      },
      modifiers: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = this._parseConfig(config);
    this.data = this._normalizeData(data);
    this.readOnly = readOnly || this.config.readOnly;

    this._selectors = {
      block: "pe-iframe",
      input: "pe-iframe-input",
      feedback: "pe-iframe-feedback",
      settings: "pe-iframe-settings",
      modifierSettings: "pe-iframe-settings-modifier",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const inputElem = blockContent.querySelector("." + this._selectors.input);
    const blockData = {
      html: _get(inputElem, "value", "").trim(),
      modifiers: this.currentModifiers,
    };

    // empty text then block is in error
    if (!this._validateHTML(blockData.html)) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["iframe invalide"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // remove block if empty
  validate(savedData) {
    return savedData.html.trim() !== "";
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  // GETTERS & SETTERS

  // get current modifiers according to block element classes
  get currentModifiers() {
    return this.modifiers.reduce((result, modifier) => {
      if (this._blockElem.classList.contains(modifier.class)) {
        result.push(modifier.class);
      }

      return result;
    }, []);
  }

  get modifiers() {
    return [
      {
        class: "is-video",
        html: IframeBlock.icons.video,
        title: "iframe d'un vidéo",
      },
    ];
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["html", "modifiers"]);

    data.html = _isString(data.html) ? data.html : "";
    data.modifiers = _isArray(data.modifiers) ? data.modifiers : [];

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, IframeBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "label",
      className: this._selectors.block,
      contents: [
        {
          tag: "span",
          innerText: this.api.i18n.t("title"),
        },
        {
          tag: "textarea",
          className: this._selectors.input + " " + this.api.styles.input,
          placeholder: this.api.i18n.t("placeholder"),
          rows: 5,
          value: blockData.html,
          events: {
            input: _debounce(this._validateHTML.bind(this), 300),
          },
        },
        {
          tag: "span",
          className: this._selectors.feedback,
        },
      ],
    };

    // update according to modifiers
    if (_isArray(blockData.modifiers)) {
      blockElemOptions.className += " " + blockData.modifiers.join(" ");
    }

    if (this.readOnly) {
      blockElemOptions.contents[1].readonly = true;
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _buildSettingsElem() {
    const settingsOptions = {
      tag: "div",
      class: this._selectors.settings,
      contents: [],
    };

    // add modifiers options
    this.modifiers.forEach((modifier) => {
      const modifierOptions = {
        tag: "span",
        class: this.api.styles.settingsButton + " " + this._selectors.modifierSettings,
        "data-tooltip": "bottom",
        "aria-label": modifier.title,
        "data-modifier": modifier.class,
        innerHTML: modifier.html,
        events: {
          click: (event) => {
            this._toggleModifier(event.target, modifier);
          },
        },
      };

      // set active modifier
      if (this.currentModifiers.includes(modifier.class)) {
        modifierOptions.class += " " + this.api.styles.settingsButtonActive;
      }

      settingsOptions.contents.push(modifierOptions);
    });

    // create settingsElem
    return Bliss.create(settingsOptions);
  }

  _toggleModifier(target, modifier) {
    const settingElem = target.closest("." + this._selectors.modifierSettings);
    settingElem.classList.toggle(this.api.styles.settingsButtonActive);
    this._blockElem.classList.toggle(modifier.class);
  }

  _validateHTML(html) {
    const inputElem = this._blockElem.querySelector("." + this._selectors.input);
    const feedbackElem = this._blockElem.querySelector("." + this._selectors.feedback);

    feedbackElem.innerText = "";
    inputElem.classList.remove(this._selectors.input + "--invalid");

    if (!_isString(html)) {
      html = inputElem.value.trim();
    }

    if (html === "") {
      return true;
    }

    // matches iframe tag
    const iframeMatches = html.match(/(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/gim);

    if (!iframeMatches || iframeMatches.length === 0) {
      feedbackElem.innerText = this.api.i18n.t("feedbackNoIframe");
    } else if (iframeMatches.length > 1 || iframeMatches[0] !== html) {
      feedbackElem.innerText = this.api.i18n.t("feedbackTooMany");
    } else {
      // matches iframe src
      const srcMatches = /src=['"](.*?)['"]/.exec(html);

      if (!srcMatches || srcMatches.length === 0 || !this._isValidIframeSrc(srcMatches[1])) {
        feedbackElem.innerText = this.api.i18n.t("feedbackNoSrc");
      } else {
        // check if it could be a video iframe
        const vendor = _find(EMBED_CONFIG, (vendor) => {
          return _find(vendor.re, (re) => {
            return re.test(srcMatches[1]);
          });
        });

        if (vendor) {
          feedbackElem.innerText = this.api.i18n.t("feedbackVideo");
        }
      }
    }

    if (feedbackElem.innerText !== "") {
      inputElem.classList.add(this._selectors.input + "--invalid");

      // in case of vendor error, display error but allow save
      return feedbackElem.innerText === this.api.i18n.t("feedbackVideo");
      // return false
    }

    return true;
  }

  _isValidIframeSrc(src) {
    return isValidURL(src) || /^data:text\/html;.+/.test(src);
  }
}
