import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["item"];

  // LIFECYCLE

  connect() {}

  // ACTIONS

  hidden(event) {
    this._toggle(event.target, this._toggleHiddenItem.bind(this));
  }

  disabled(event) {
    this._toggle(event.currentTarget, this._toggleDisabledItem.bind(this));
  }

  // PRIVATE

  _toggle(toggler, itemToggleFn) {
    let toggled = this._checkToggler(toggler);

    if (toggled === undefined) {
      return;
    }

    // reverse toggle state if specified
    if (toggler.dataset.toggleReversed) {
      toggled = !toggled;
    }

    const toggleId = toggler.dataset.toggleId;

    this.itemTargets.forEach((item) => {
      if (toggleId) {
        if (item.dataset.toggleId == toggleId) {
          itemToggleFn(item, toggled);
        }
      } else {
        itemToggleFn(item, toggled);
      }
    });
  }

  // Return true/false wether the toggler is activated or not
  _checkToggler(toggler) {
    if (!toggler) {
      return;
    }

    // text input
    if (toggler.type === "text" || toggler.type === "hidden") {
      return toggler.value === "";
    }

    // checkbox
    if (toggler.type === "checkbox") {
      return !toggler.checked;
    }

    // radio
    if (toggler.type === "radio") {
      return toggler.dataset.toggleActive != "true";
    }

    // select
    if (toggler.tagName?.toLowerCase() === "select") {
      return toggler.value === "";
    }

    // toggle item itself with value specified as a data attribute (default to false)
    if (this.itemTargets.includes(toggler)) {
      return toggler.dataset.toggleValue !== "true";
    }

    // toggle controller item with value specified as a data attribute (default to false)
    if (this.element === toggler) {
      return toggler.dataset.toggleValue !== "true";
    }

    // toggle element referencing a toggle id with value specified as a data attribute (default to false)
    if (toggler.hasAttribute("data-toggle-id")) {
      return toggler.dataset.toggleValue !== "true";
    }
  }

  // Toggle disabled attribute on specific item
  _toggleDisabledItem(item, disabled) {
    const dispatchedEventName = disabled ? "toggle:disabled" : "toggle:enabled";

    // a tags can't be disabled, hence we toggle disabled class
    if (item.tagName?.toLowerCase() === "a") {
      item.classList.toggle("disabled", disabled);
      item.dispatchEvent(new Event(dispatchedEventName));
      return;
    }

    item.disabled = disabled;
    item.dispatchEvent(new Event(dispatchedEventName));
  }

  // Toggle hidden attribute on specific item
  _toggleHiddenItem(item, hidden) {
    if (item.dataset.toggleReversed === "true") {
      hidden = !hidden;
    }

    item.hidden = hidden;

    if (hidden && item.dataset.toggleReset !== "false") {
      this._resetItem(item);
    } else {
      this._initItem(item);
      item.dispatchEvent(new Event("toggle:shown", { bubbles: true }));
    }
  }

  // Reset item's fields
  _resetItem(item) {
    // nested form
    const nestedFormController = this.application.getControllerForElementAndIdentifier(item, "nested-form");
    if (nestedFormController) {
      nestedFormController.clearItems();
    } else {
      // inputs
      const inputs = item.querySelectorAll('input[type="hidden"], input[type="text"], textarea');
      inputs.forEach((input) => (input.value = ""));

      // datetime input
      const datetimeIdentifier = "datetime";
      const datetimeElements = item.querySelectorAll(
        `[${this.application.schema.controllerAttribute}~="${datetimeIdentifier}"]`
      );
      datetimeElements.forEach((datetimeElem) => {
        const datetimeController = this.application.getControllerForElementAndIdentifier(
          datetimeElem,
          datetimeIdentifier
        );
        datetimeController.clear();
      });
    }
  }

  // Init item's fields
  _initItem(item) {
    // maps
    const mapIdentifier = "geo-picker";
    const mapElements = item.querySelectorAll(`[${this.application.schema.controllerAttribute}~="${mapIdentifier}"]`);
    mapElements.forEach((mapElem) => {
      const mapController = this.application.getControllerForElementAndIdentifier(mapElem, mapIdentifier);
      mapController.reset();
    });
  }
}
