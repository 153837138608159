import Rails from "@rails/ujs";
import _get from "lodash/get";
import _pick from "lodash/pick";
import { parseUrlSearch, stringifyUrlSearch } from "../helpers/url_helper";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["content"];
  static values = { url: String };

  initialize() {
    this.errorClass = this.identifier + "--error";
  }

  // ACTIONS

  filter(event) {
    this.stopEventPropagation(event);

    this.filtersForm = event.target.closest("form");

    // reset pagination
    delete this.paginateParams;

    this._fetchFilteredContent();
  }

  orderBy(event) {
    this.stopEventPropagation(event);
    
    const params = parseUrlSearch(_get(event, "target.href"));
    this.orderByParams = _pick(params, ["order_by"]);

    // reset pagination
    delete this.paginateParams;

    this._fetchFilteredContent();
  }

  paginate(event) {
    this.stopEventPropagation(event);

    const params = parseUrlSearch(_get(event, "target.href"));
    this.paginateParams = _pick(params, ["page"]);

    this._fetchFilteredContent();
  }

  // PRIVATE

  _fetchFilteredContent() {
    if (!this.hasUrlValue) {
      console.error("Missing content filter url");
      return;
    }

    this.loading(true);
    // this.element.classList.remove(this.errorClass);

    const dataArray = ["partial=1"];

    if (this.filtersForm) {
      dataArray.push(Rails.serializeElement(this.filtersForm));
    }
    if (this.orderByParams) {
      dataArray.push(stringifyUrlSearch(this.orderByParams));
    }
    if (this.paginateParams) {
      dataArray.push(stringifyUrlSearch(this.paginateParams));
    }

    Rails.ajax({
      type: "GET",
      url: this.urlValue,
      data: dataArray.join("&"),
      success: (_response, _status, xhr) => {
        this.contentTarget.outerHTML = xhr.response;
      },
      error: (response, _status, xhr) => {
        this.loading(false);
        // this.element.classList.add(this.errorClass);
        console.error(xhr.response);
      },
      complete: (_xhr, _status) => {
        this.loading(false);
      },
    });
  }
}
