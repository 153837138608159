import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["current", "select"];

  edit() {
    this.element.classList.add("edit");
  }

  cancel() {
    this.element.classList.remove("edit");
  }

  confirm() {
    if (!this.hasSelectTarget) {
      return;
    }

    const selectedValue = _get(this.selectTarget, ["options", this.selectTarget.selectedIndex, "value"]);

    if (this.hasCurrentTarget && selectedValue) {
      this.currentTarget.innerText = selectedValue;

      // TODO enable saving archive date
    }

    this.element.classList.remove("edit");
  }
}
