import _isString from "lodash/isString";

export function slugify(string) {
  if (!_isString(string)) {
    return "";
  }

  /* eslint-disable */
  // prettier-ignore
  const specialChars = {
    "à":"a","á":"a","ä":"a","â":"a","æ":"ae","å":"a",
    "é":"e","è":"e","ë":"e","ê":"e",
    "í":"i","ì":"i","ï":"i","î":"i","ı":"i",
    "ó":"o","ò":"o","ö":"o","ô":"o","õ":"o","ø":"o","œ":"oe",
    "ú":"u","ù":"u","ü":"u","û":"u",
    "ÿ":"y",
    "ç":"c","ş":"s",
    "ń":"n","ǹ":"n","ñ":"n",
    "ß":"s",
    "ğ":"g",
    "а":"a","б":"b","в":"v","г":"g","д":"d","е":"e","ё":"yo","ж":"zh","з":"z","и":"i","й":"j","к":"k",
    "л":"l","м":"m","н":"n","о":"o","п":"p","р":"r","с":"s","т":"t","у":"u","ф":"f","х":"h","ц":"c",
    "ч":"ch","ш":"sh","щ":"sh","ъ":"","ы":"y","ь":"","э":"e","ю":"yu","я":"ya",
    "'":"-","’":"-","·":"-","/":"-","_":"-",",":"-",":":"-",";":"-",".":"-",
    "&":"-et-","€":"-euro-",
  };
  /* eslint-enable */

  return string
    .toLowerCase()
    .replace(/\s+/g, "-") // replace spaces with -
    .replace(/./g, (target) => specialChars[target] || target) // replace special characters using the hash map
    .replace(/[^\w-]+/g, "") // remove all non-word chars
    .replace(/--+/g, "-") // replace multiple - with single -
    .replace(/^-+/, "") // trim - from start of text
    .replace(/-+$/, "") // trim - from end of text
    .substring(0, 100); // We need a length of 101 as we will remove the first character '#'
}

export function isEmail(string) {
  // source: http://emailregex.com/
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(string);
}

export function formatMailTo(string) {
  if (!_isString(string)) {
    return "";
  }

  if (!isEmail(string)) {
    return string;
  }

  return "mailto:" + string;
}

export function removeExtensionFromName(fileName) {
  return fileName.split(".").slice(0, -1).join(".");
}
