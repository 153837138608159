/* global Bliss */

export default class SupInline {
  static title = "sup-inline";

  static get isInline() {
    return true;
  }

  static get icons() {
    return {
      superscript: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/superscript.svg"),
    };
  }

  static get sanitize() {
    return {
      sup: {},
    };
  }

  constructor({ api, _ }) {
    this.api = api;

    this._buttonElem = null;
  }

  // create button
  render() {
    this._buttonElem = Bliss.create({
      tag: "button",
      type: "button",
      class: this.api.styles.inlineToolButton,
      innerHTML: SupInline.icons.superscript,
    });

    return this._buttonElem;
  }

  // tool button is clicked
  surround(_) {
    document.execCommand("superscript");
  }

  // determine tool state when selection happens
  checkState(_) {
    const isActive = document.queryCommandState("superscript");

    this._buttonElem.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}
