/* global Bliss */

export function copyAnchor(params) {
  const target = params.e.target.closest(`.${params.classname}`);
  const flashInput = Bliss.create({
    tag: "input",
    style: {
      position: "fixed",
      top: "-100%",
      left: "-100%",
      width: "2em",
      height: "2em",
      padding: 0,
      border: "none",
      outline: "none",
    },
    value: params.copyText,
  });
  target.appendChild(flashInput);
  flashInput.select();

  if (document.execCommand("copy")) {
    console.info("Identifiant copié : ", params.copyText);

    const successClass = params.styles.settingsButton + "--success";
    target.classList.add(successClass);
    setTimeout(() => {
      target.innerHTML = params.block.icons.anchor;
      target.classList.remove(successClass);
    }, 3000);
  } else {
    target.removeChild(flashInput);
  }
}
