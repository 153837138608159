import ApplicationController from "./application_controller";
import L from "leaflet";
import { EsriProvider } from "leaflet-geosearch";
import "leaflet/dist/leaflet.css";
import MARKER from "../../components/form/map_component/marker.svg";

import {
  TILE_LAYER,
  TILE_LAYER_MIN_ZOOM,
  DEFAULT_ICON,
  DEFAULT_LAYER_ATTRIBUTION,
  OPTIONS,
  DEFAULT_COORDS,
} from "../../components/form/map_component/map_config_component";

export default class extends ApplicationController {
  static targets = ["map", "street", "postcode", "city", "latitude", "longitude"];

  // LIFECYCLE

  initialize() {
    this.L = L;
  }

  connect() {
    this._buildAddress();

    if (this.hasMapTarget) {
      this._initMap();
      this._setPicker();
    }
  }

  // ACTIONS

  updatePicker() {
    this._buildAddress();
    this._searchAddress();
  }

  reset() {
    if (!this.map) {
      return;
    }

    // set correct map size if map was hidden
    this.map.invalidateSize();

    this._setPicker();
  }

  // PRIVATE

  _initMap() {
    this.map = L.map(this.mapTarget, OPTIONS).setView(DEFAULT_COORDS);

    L.tileLayer(TILE_LAYER, {
      minZoom: TILE_LAYER_MIN_ZOOM,
      attribution: DEFAULT_LAYER_ATTRIBUTION,
    }).addTo(this.map);

    const ICON_PARIS = L.icon({
      iconUrl: MARKER,
      ...DEFAULT_ICON,
    });

    L.Marker.prototype.options.icon = ICON_PARIS;
    this.marker = L.marker(DEFAULT_COORDS, { draggable: true }).addTo(this.map);

    // On dragend Marker func
    this.marker.on("dragend", (e) => {
      const position = e.target.getLatLng();
      this.coords = [position.lat, position.lng];
      this._updateCoords(false);
      this.map.panTo([position.lat, position.lng]);
    });
  }

  _buildAddress() {
    if (!this.hasStreetTarget || !this.hasPostcodeTarget || !this.hasCityTarget) {
      this.address = "";
      return;
    }

    const street = this.streetTarget.value;
    const postcode = this.postcodeTarget.value;
    const city = this.cityTarget.value;

    this.address = street && postcode && city ? `${street}, ${postcode}, ${city}` : "";
  }

  _searchAddress() {
    if (!this.address) {
      this.coords = DEFAULT_COORDS;
      this._updateCoords(true);
      return;
    }

    const provider = new EsriProvider();
    return provider
      .search({ query: this.address || "" })
      .then((result) => {
        this.coords = result.length > 0 && [result[0].y, result[0].x];
        this._updateCoords(true);
      })
      .catch(() => {
        this.coords = DEFAULT_COORDS;
        this._updateCoords(true);
      });
  }

  _setPicker() {
    // set marker according to coordinates
    if (this.hasLatitudeTarget && this.latitudeTarget.value) {
      this.coords = [this.latitudeTarget.value, this.longitudeTarget.value];
      this._updateCoords(true);
    }
    // set marker according to address or default
    else {
      this._searchAddress();
    }
  }

  _updateCoords(shouldUpdateView) {
    if (this.hasMapTarget && shouldUpdateView) {
      this._updateView();
    }

    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = this.coords[0];
      this.longitudeTarget.value = this.coords[1];
      this.latitudeTarget.dispatchEvent(new Event("location:change", { bubbles: true }));
    }
  }

  _updateView() {
    this.marker.setLatLng(this.coords);
    this.map.setView(this.coords, this.map.getZoom());
  }
}
