// https://www.dailymotion.com/video/x5glebm
// https://vimeo.com/152069351
// https://youtu.be/ZdprZzanJI0
// https://www.ina.fr/video/CAF92048184

const EMBED_CONFIG = {
  dailymotion: {
    config: {
      url: "https://www.dailymotion.com/services/oembed",
      dataType: "jsonp",
    },
    re: [
      /^https?:\/\/(?:www\.)?dailymotion\.com\/video\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/(?:www\.)?dai\.ly\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/(?:www\.)?dailymotion\.com\/embed\/video\/([a-zA-Z0-9_-]+)/i,
    ],
  },
  vimeo: {
    config: {
      url: "https://vimeo.com/api/oembed.json",
    },
    re: [
      /^https?:\/\/vimeo\.com\/[a-zA-Z0-9_-]+\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/vimeo\.com\/([0-9]+)/i,
      /^https?:\/\/player\.vimeo\.com\/video\/([0-9]+)/i,
    ],
  },
  youtube: {
    config: {
      url: "https://www.youtube.com/oembed",
    },
    re: [
      /^https?:\/\/(?:www\.)?youtube\.com\/(?:tv#\/)?watch\/?\?(?:[^&]+&)*v=([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/youtu\.be\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/m\.youtube\.com\/#\/watch\?(?:[^&]+&)*v=([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/www\.youtube\.com\/v\/([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/www\.youtube\.com\/user\/[a-zA-Z0-9_-]+\/?\?v=([a-zA-Z0-9_-]+)/i,
      /^https?:\/\/www\.youtube-nocookie\.com\/v\/([a-zA-Z0-9_-]+)/i,
    ],
  },
  ina: {
    url: (embedId) => {
      return "https://player.ina.fr/player/embed/" + embedId + "/1/1b0bd203fbcd702f9bc9b10ac3d0fc21/wide/0";
    },
    thumbnail: (embedId) => {
      return "https://www.ina.fr/images_v2/512x384/" + embedId + ".jpeg";
    },
    re: [
      /^https?:\/\/(?:www\.)?ina\.fr\/video\/([a-zA-Z0-9_-]+)/i,
      /https?:\/\/player\.ina\.fr\/player\/embed\/([a-zA-Z0-9_-]+)/i,
    ],
  },
};

export default EMBED_CONFIG;
