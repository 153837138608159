import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["tagField"];

  // LIFECYCLE

  connect() {}

  // ACTIONS

  toggleTagField(_event) {
    if (!this.hasTagFieldTarget) {
      return;
    }

    const universeSelectElem = event.target;
    const isUniverseSelected = universeSelectElem.selectedIndex !== 0;
    let tags = [];

    if (isUniverseSelected) {
      try {
        tags = tags.concat(
          JSON.parse(_get(universeSelectElem, ["options", universeSelectElem.selectedIndex, "dataset", "tags"]))
        );
      } catch (err) {
        console.error(err);
      }
    }

    this.tagFieldTarget.hidden = !(tags && isUniverseSelected);

    // tag select
    const tagSelectorElem = this.tagFieldTarget.querySelector('[data-controller~="tag-selector"]');
    const tagSelectorController = this.application.getControllerForElementAndIdentifier(
      tagSelectorElem,
      "tag-selector"
    );

    tagSelectorController.resetOptions(tags);
  }
}
