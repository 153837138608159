import _capitalize from "lodash/capitalize";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input"];

  // ACTIONS

  capitalize() {
    if (!this.hasInputTarget) return;

    this.inputTarget.value = _capitalize(this.inputTarget.value);
  }

  passwordToggle() {
    if (!this.hasInputTarget) return;

    this.inputTarget.type = this.inputTarget.type == "password" ? "text" : "password";
  }
}
