/* global Bliss */

import _debounce from "lodash/debounce";
import _get from "lodash/get";
import _isBoolean from "lodash/isBoolean";
import _isString from "lodash/isString";
import _pick from "lodash/pick";

import { isValidURL, isExternalUrl } from "../../helpers/url_helper";
import { formatMailTo } from "../../helpers/string_helper";

import "./link_block.scss";

export default class LinkBlock {
  // LinksBlock data schema
  // {
  //   text: < string, required >,
  //   url: < string: url | path to media (without CDN hostname), required >,
  //   external: < boolean, default: false >,
  //   icon: < string: "external" | "download", required >,
  //   id: < integer >,
  //   size: < float >,
  // }

  static get icons() {
    return {
      external: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/external-link-alt.svg"),
      link: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/link.svg"),
      download: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/file-download.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "link",
      icon: LinkBlock.icons.external,
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      // N.B.: skip sanitizing as this should not be HTML. It also avoids parsing & as &amp;
      text: true,
      url: true,
      external: true,
      icon: true,
      id: true,
      size: true,
    };
  }

  constructor({ data, _config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-link",
      switch: "pe-link-switch",
      text: "pe-link-text",
      url: "pe-link-url",
      file: "pe-link-file",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const external = this.isExternal;
    const textElem = blockContent.querySelector("." + this._selectors.text);
    const urlElem = blockContent.querySelector("." + this._selectors.url);

    const blockData = {
      text: _get(textElem, "value", ""),
      url: _get(urlElem, "value", ""),
      icon: external ? "external" : "download",
      external: external,
    };

    // external link
    if (external) {
      // preprend mailto: if url is an email
      blockData.url = formatMailTo(blockData.url);
    } else {
      // file to download

      let attachmentParams;

      try {
        attachmentParams = JSON.parse(_get(blockContent, "dataset.attachmentParams", "{}"));
      } catch (err) {
        console.error(err);
      }

      blockData.size = _get(attachmentParams, "size", 0);
      const id = _get(attachmentParams, "id");
      if (id) {
        blockData.id = id;
      }
    }

    // empty text or url then block is in error
    const errorMessages = [];
    if (blockData.text.trim() === "") {
      errorMessages.push("texte manquant");
    }

    if (external) {
      if (blockData.url.trim() === "") {
        errorMessages.push("url manquante");
      }

      if (!isValidURL(blockData.url)) {
        errorMessages.push("url invalide");
      }
    } else if (!blockData.url) {
      // some imported blocks don't mention the file id,
      // this validation is commented until then
      // } else if (!blockData.id) {
      errorMessages.push("fichier manquant");
    }

    if (errorMessages.length > 0) {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: errorMessages,
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  // GETTERS & SETTERS

  get isExternal() {
    return this._blockElem.classList.contains(this._selectors.block + "--external");
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["text", "url", "external", "icon", "id", "size", "target"]);

    data.text = _isString(data.text) ? data.text : "";
    data.url = _isString(data.url) ? data.url : "";
    data.target = _isString(data.target) ? data.target : "";
    data.external = _isBoolean(data.external) ? data.external : false;
    data.icon = data.external ? "external" : "download";

    const size = parseInt(data.size, 10);
    if (size) {
      data.size = size;
    }

    const id = parseInt(data.id, 10);
    if (id) {
      data.id = id;
    }

    return data;
  }

  _buildBlockElem(blockData) {
    const blockElemClassNames = [this._selectors.block];

    // blockElem options
    const blockElemOptions = {
      tag: "div",
      "data-controller": "attachment",
      "data-attachment-params": JSON.stringify(blockData),
      contents: [
        {
          tag: "div",
          className: "d-flex mb-10",
          contents: [
            {
              tag: "input",
              type: "text",
              placeholder: "texte du lien...",
              className: this._selectors.text,
              value: blockData.text,
              "data-target": "attachment.text",
              events: { keydown: this._disableEnter.bind(this) },
            },
            {
              tag: "div",
              className: this._selectors.switch + " btn-group",
              role: "group",
              contents: [
                {
                  tag: "button",
                  type: "button",
                  className: "btn btn-sm btn-outline-primary",
                  innerHTML: LinkBlock.icons.download,
                  events: {
                    click: (_) => {
                      this._changeExternal(false);
                    },
                  },
                },
                {
                  tag: "button",
                  type: "button",
                  className: "btn btn-sm btn-outline-primary",
                  innerHTML: LinkBlock.icons.link,
                  events: {
                    click: (_) => {
                      this._changeExternal(true);
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    if (this.readOnly) {
      blockElemOptions.contents[0].contents[0].readonly = true;
      delete blockElemOptions.contents[0].contents[1].contents[0].events.click;
      delete blockElemOptions.contents[0].contents[1].contents[1].events.click;
    }

    // link options
    let linkOptions;

    // external link options
    if (blockData.external) {
      blockElemClassNames.push(this._selectors.block + "--external");

      // set active switch
      blockElemOptions.contents[0].contents[1].contents[1].className += " active";

      const linkClasses = [this.api.styles.input, this._selectors.url];

      if (isExternalUrl(blockData.url)) {
        linkClasses.push(this._selectors.url + "--blank");
      }

      linkOptions = {
        tag: "input",
        type: "text",
        placeholder: "url du lien...",
        className: linkClasses.join(" "),
        value: blockData.url,
        events: {
          keydown: this._disableEnter.bind(this),
          input: _debounce(this._toggleExternal.bind(this), 300),
        },
      };

      if (this.readOnly) {
        linkOptions.readonly = true;
      }
    } else {
      // file download options

      blockElemClassNames.push(this._selectors.block + "--download");

      // set active switch
      blockElemOptions.contents[0].contents[1].contents[0].className += " active";

      linkOptions = {
        tag: "div",
        className: this._selectors.file,
        contents: [
          {
            tag: "button",
            type: "button",
            className: this._selectors.file + "__button btn btn-primary",
            innerHTML: LinkBlock.icons.download,
            "data-action": "attachments-library#open",
          },
          {
            tag: "input",
            type: "text",
            readonly: true,
            placeholder: "insérer un fichier...",
            className: [this._selectors.url, this._selectors.file + "__url", this.api.styles.input].join(" "),
            "data-target": "attachment.url",
            value: blockData.url,
          },
        ],
      };

      if (this.readOnly) {
        linkOptions.contents.shift();
      }
    }

    // insert link options
    blockElemOptions.contents.push(linkOptions);

    // concat class names
    blockElemOptions.className = blockElemClassNames.join(" ");

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _replaceBlockElem(blockData) {
    // create new block
    const newBlockElem = this._buildBlockElem(blockData);

    // replace block in DOM
    this._blockElem.parentNode.replaceChild(newBlockElem, this._blockElem);

    // keep new block reference
    this._blockElem = newBlockElem;

    // focus text input
    this._blockElem.querySelector("." + this._selectors.text).focus();
  }

  _changeExternal(external) {
    // unchanged status
    if ((external && this.isExternal) || (!external && !this.isExternal)) {
      return;
    }

    const textElem = this._blockElem.querySelector("." + this._selectors.text);

    const blockData = {
      text: _get(textElem, "value", ""),
      url: "",
      external: external,
    };

    this._replaceBlockElem(blockData);
  }

  _toggleExternal(event) {
    event.target.classList.toggle(this._selectors.url + "--blank", isExternalUrl(event.target.value));
  }

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }
}
