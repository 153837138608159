/* global Bliss */

import _assign from "lodash/assign";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _map from "lodash/map";
import _pick from "lodash/pick";
import _property from "lodash/property";

import "./list_block.scss";

export default class ListBlock {
  // ListBlock data schema
  // {
  //   style: < string: "ol" | "ul", default: "ul" >,
  //   items: [< string: inline HTML, required >, ...]
  // }

  static get icons() {
    // original icons
    // {
    //   list: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>'
    //   listUl: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>'
    //   listOl: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"><path d="M5.819 4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0-4.607h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 1 1 0-2.138zm0 9.357h9.362a1.069 1.069 0 0 1 0 2.138H5.82a1.069 1.069 0 0 1 0-2.137zM1.468 4.155V1.33c-.554.404-.926.606-1.118.606a.338.338 0 0 1-.244-.104A.327.327 0 0 1 0 1.59c0-.107.035-.184.105-.234.07-.05.192-.114.369-.192.264-.118.475-.243.633-.373.158-.13.298-.276.42-.438a3.94 3.94 0 0 1 .238-.298C1.802.019 1.872 0 1.975 0c.115 0 .208.042.277.127.07.085.105.202.105.351v3.556c0 .416-.15.624-.448.624a.421.421 0 0 1-.32-.127c-.08-.085-.121-.21-.121-.376zm-.283 6.664h1.572c.156 0 .275.03.358.091a.294.294 0 0 1 .123.25.323.323 0 0 1-.098.238c-.065.065-.164.097-.296.097H.629a.494.494 0 0 1-.353-.119.372.372 0 0 1-.126-.28c0-.068.027-.16.081-.273a.977.977 0 0 1 .178-.268c.267-.264.507-.49.722-.678.215-.188.368-.312.46-.371.165-.11.302-.222.412-.334.109-.112.192-.226.25-.344a.786.786 0 0 0 .085-.345.6.6 0 0 0-.341-.553.75.75 0 0 0-.345-.08c-.263 0-.47.11-.62.329-.02.029-.054.107-.101.235a.966.966 0 0 1-.16.295c-.059.069-.145.103-.26.103a.348.348 0 0 1-.25-.094.34.34 0 0 1-.099-.258c0-.132.031-.27.093-.413.063-.143.155-.273.279-.39.123-.116.28-.21.47-.282.189-.072.411-.107.666-.107.307 0 .569.045.786.137a1.182 1.182 0 0 1 .618.623 1.18 1.18 0 0 1-.096 1.083 2.03 2.03 0 0 1-.378.457c-.128.11-.344.282-.646.517-.302.235-.509.417-.621.547a1.637 1.637 0 0 0-.148.187z"/></svg>'
    // }

    return {
      list: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/list-alt.svg"),
      listUl: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/list-ul.svg"),
      listOl: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/list-ol.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "list",
      icon: ListBlock.icons.list,
    };
  }

  static get pasteConfig() {
    return {
      tags: ["OL", "UL", "LI"],
    };
  }

  static get conversionConfig() {
    return {
      export: (data) => {
        return data.items.join("<br>");
      },
      import: (string) => {
        return {
          items: string.split(/<br\s*\/?>/i).filter(Boolean),
        };
      },
    };
  }

  static get defaultConfig() {
    return {
      defaultStyle: "ul",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      style: true,
      items: {
        br: true,
      },
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-list",
      settings: "pe-list-settings",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    this._addAnchorEditListeners();

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const blockData = {
      style: this.currentStyle,
      items: this.currentItems,
    };

    // only one empty item then block is in error
    const textItems = _map(Bliss.$("li", blockContent), "textContent");
    if (textItems.length === 1 && textItems[0].trim() === "") {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["texte manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // remove block if no items
  validate(savedData) {
    return _isArray(savedData.items) && savedData.items.length > 0;
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  destroy() {
    this._removeAnchorEditListeners();
  }

  onPaste(event) {
    const content = event.detail.data;
    const blockData = {
      style: content.tagName.toLowerCase(),
      items: [],
    };

    if (blockData.style === "li") {
      blockData.style = this.config.defaultStyle;
      blockData.items = [content.innerHTML];
    } else {
      blockData.items = _map(Bliss.$("li", content), "innerHTML");
    }

    this._replaceBlockElem(blockData);
  }

  merge(blockData) {
    this._replaceBlockElem({
      style: this.currentStyle,
      items: this.currentItems.concat(blockData.items),
    });

    this._blockElem.focus();
  }

  // GETTERS & SETTERS

  get currentItems() {
    return _map(Bliss.$("li", this._blockElem), "innerHTML");
  }

  get currentStyle() {
    return _get(this._blockElem, "tagName").toLowerCase() || this.config.defaultStyle;
  }

  get styles() {
    return [
      {
        tag: "ul",
        svg: ListBlock.icons.listUl,
        title: "liste non ordonnée",
      },
      {
        tag: "ol",
        svg: ListBlock.icons.listOl,
        title: "liste ordonnée",
      },
    ];
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["style", "items"]);

    data.style = this.styles.map(_property("tag")).includes(data.style) ? data.style : this.config.defaultStyle;

    data.items = _isArray(data.items) ? data.items : [""];
    // TODO filter invalid objects in the items array

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, ListBlock.defaultConfig, config);

    // default style isn't one of the authorized style
    if (!this.styles.map(_property("tag")).includes(assignedConfig.defaultStyle)) {
      throw new Error("ListBlock: default style isn't an available style.");
    }

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: blockData.style,
      class: this._selectors.block + " list",
      contenteditable: !this.readOnly,
      contents: [],
      events: { keydown: this._handleKey.bind(this) },
    };

    const items = blockData.items;

    // add items options
    items.forEach((itemHTML) => {
      blockElemOptions.contents.push({
        tag: "li",
        innerHTML: itemHTML,
      });
    });

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _replaceBlockElem(blockData) {
    // create new block
    const newBlockElem = this._buildBlockElem(blockData);

    this._removeAnchorEditListeners();

    // replace block in DOM
    this._blockElem.parentNode.replaceChild(newBlockElem, this._blockElem);

    // keep new block reference
    this._blockElem = newBlockElem;

    this._addAnchorEditListeners();
  }

  _buildSettingsElem() {
    const settingsOptions = {
      tag: "div",
      class: this._selectors.settings,
      contents: [],
    };

    // add style options
    this.styles.forEach((style) => {
      const styleOptions = {
        tag: "span",
        class: this.api.styles.settingsButton,
        "data-tooltip": "bottom",
        "aria-label": style.title,
        "data-style": style.tag,
        innerHTML: style.svg,
        events: {
          click: (event) => {
            this._changeStyle(style);
            this._toggleActiveSettings(event.target, style);
          },
        },
      };

      // set active style
      if (this.currentStyle === style.tag) {
        styleOptions.class += " " + this.api.styles.settingsButtonActive;
      }

      settingsOptions.contents.push(styleOptions);
    });

    // create settingsElem
    return Bliss.create(settingsOptions);
  }

  _changeStyle(style) {
    this._replaceBlockElem({
      style: style.tag,
      items: this.currentItems,
    });
  }

  _toggleActiveSettings(target, style) {
    const settingsElem = target.closest("." + this._selectors.settings);
    const settingsArray = Bliss.$("." + this.api.styles.settingsButton, settingsElem);

    settingsArray.forEach((settingElem) => {
      const isStyle = settingElem.dataset.style === style.tag;
      settingElem.classList.toggle(this.api.styles.settingsButtonActive, isStyle);
    });
  }

  _addAnchorEditListeners() {
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  _removeAnchorEditListeners() {
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.off(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  // LISTENERS

  _handleKey(event) {
    switch (event.keyCode) {
      case 13: // ENTER
        this._breakList(event);
        break;
      default: // ignore
    }
  }

  _breakList(event) {
    const items = event.target.querySelectorAll("li");

    // keep the item if there is only one
    if (items.length < 2) {
      return;
    }

    const lastItem = items[items.length - 1];
    const currentItem = this.api.selection.findParentTag("LI");

    // keep the item if the current is not the last or if it is not empty
    if (currentItem !== lastItem || lastItem.textContent.trim().length > 0) {
      return;
    }

    // remove last item and insert new default block
    this._blockElem.removeChild(lastItem);
    this.api.blocks.insert();

    event.preventDefault();
    event.stopPropagation();
  }

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }
}
