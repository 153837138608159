import _template from "lodash/template";
import autocomplete from "autocomplete.js"; // doc: https://github.com/algolia/autocomplete/tree/v0

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input", "selection", "id"];
  static values = { endpoint: String, staticDataset: Boolean, options: Object };

  initialize() {
    this.defaulOptions = {
      hint: false,
    };
    this.staticDataset = [];
  }

  connect() {
    this._initAutocomplete();
  }

  reInit() {
    this.ac.autocomplete.destroy();
    this._initAutocomplete();
  }

  _initAutocomplete() {
    if (!this.hasInputTarget) {
      return;
    }

    const acOptions = this.hasOptionsValue ? this.optionsValue : this.defaulOptions;
    const { suggestionTemplate = "<% if (id) { %>#<%- id %> - <% } %><em><%- title %></em>" } = acOptions;
    this.suggestionCompiled = _template(suggestionTemplate, { imports: { id: 0 } });

    this.ac = autocomplete(this.inputTarget, acOptions, [
      {
        source: this._source(),
        debounce: 500,
        templates: {
          suggestion: (suggestion) => {
            return this.suggestionCompiled(suggestion);
          },
        },
      },
    ])
      .on("autocomplete:selected", (event, suggestion, _dataset, _context) => {
        const paramsString = JSON.stringify(suggestion);
        const suggestionHtml = this.suggestionCompiled(suggestion);

        if (this.hasIdTarget && suggestion.id) {
          this.idTarget.value = suggestion.id;
        }

        if (this.hasSelectionTarget) {
          this.selectionTarget.dataset.autocompleteParams = paramsString;
          this.selectionTarget.innerHTML = suggestionHtml;
          this.selectionTarget.dispatchEvent(new CustomEvent("autocomplete:afterSelected", { detail: suggestion }));
          this.ac.autocomplete.setVal();
        } else {
          this.element.dataset.autocompleteParams = paramsString;
          this.element.dispatchEvent(new CustomEvent("autocomplete:afterSelected", { detail: suggestion }));
          this.ac.autocomplete.setVal();
        }
      })
      .on("autocomplete:shown", (_) => {
        this.inputTarget.classList.remove("is-empty");
      })
      .on("autocomplete:empty", (_) => {
        this.inputTarget.classList.add("is-empty");
      })
      .on("autocomplete:updated", (_) => {
        this.inputTarget.classList.remove("is-loading");
      });
  }

  _source() {
    return (query, callback) => {
      this.inputTarget.classList.add("is-loading");

      // filter on static dataset previously fetched
      if (this.staticDatasetValue && this.staticDataset.length > 0) {
        return callback(this._filterDataset(this.staticDataset, query));
      }

      // static dataset has no query
      const fetchUrl = this.staticDatasetValue ? this.endpointValue : this.endpointValue + query;

      window
        .fetch(fetchUrl, { method: "GET" })
        .then((response) => response.json())
        .then((json) => {
          // store and filter fetched static dataset
          if (this.staticDatasetValue) {
            this.staticDataset = json;
            return callback(this._filterDataset(this.staticDataset, query));
          }

          callback(json);
        })
        .catch((err) => {
          console.error(err);
          return callback([]);
        });
    };
  }

  _filterDataset(dataset, query) {
    return dataset.filter((item) => this.suggestionCompiled(item).includes(query));
  }
}
