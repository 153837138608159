/* global Bliss */

import _get from "lodash/get";
import _isString from "lodash/isString";
import _pick from "lodash/pick";
import _uniqueId from "lodash/uniqueId";

import "./accordion_block.scss";

export default class AccordionBlock {
  // AccordionBlock data schema
  // {
  //   title: < string, required >,
  //   subtitle: < string, required >,
  //   text: < string: inline HTML, required >
  // }

  static get icons() {
    return {
      caret: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/caret-square-down.svg"),
      arrow: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/angle-down.svg"),
      arrowCompact: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/angle-down.svg"),
      compress: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/compress-alt.svg"),
      expand: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/expand-alt.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "accordion",
      icon: AccordionBlock.icons.caret,
    };
  }

  static get conversionConfig() {
    return {
      export: (data) => {
        return [data.title, data.text].filter(Boolean).join("<br>");
      },
      import: "text",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      // N.B.: skip sanitizing as this should not be HTML. It also avoids parsing & as &amp;
      title: true,
      subtitle: true,
      text: {
        br: true,
      },
    };
  }

  constructor({ data, _config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: this.blockStyle,
      title: "pe-accordion-title",
      subtitle: this.displaySubtitle,
      toggler: "pe-accordion-toggler",
      panel: "pe-accordion-panel",
      text: "pe-accordion-text",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    // add edit listener to links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    });

    return this._blockElem;
  }

  renderSettings() {
    return this._buildSettingsElem();
  }

  // view => data
  save(blockContent) {
    const titleElem = blockContent.querySelector("." + this._selectors.title);
    const subtitleElem = blockContent.querySelector("." + this._selectors.subtitle);
    const textElem = blockContent.querySelector("." + this._selectors.text);
    const blockData = {
      title: _get(titleElem, "value", ""),
      subtitle: _get(subtitleElem, "value", ""),
      text: _get(textElem, "innerHTML", ""),
      compressed: this.data.compressed,
    };

    // empty title or text then block is in error
    if (_get(textElem, "textContent", "").trim() === "" || blockData.title.trim() === "") {
      blockData._errors = {
        title: "composant " + this.api.i18n.t("title"),
        messages: ["texte manquant"],
      };
    }

    this._blockElem.closest(".ce-block").classList.toggle("ce-block--invalid", Boolean(blockData._errors));

    return blockData;
  }

  // never remove block on validation
  validate(_) {
    return true;
  }

  destroy() {
    // remove edit listeners on links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.off(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  get compressIcon() {
    return this.data.compressed ? AccordionBlock.icons.compress : AccordionBlock.icons.expand;
  }

  get displaySubtitle() {
    return !this.data.compressed ? "pe-accordion-subtitle" : "pe-accordion-subtitle d-none";
  }

  get blockStyle() {
    return this.data.compressed ? "pe-accordion is-compact" : "pe-accordion";
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["title", "subtitle", "text", "compressed"]);

    if (data.compressed === undefined) data.compressed = false;
    else {
      data.compressed = data.compressed ? true : false;
    }

    data.title = _isString(data.title) ? data.title : "";
    data.subtitle = _isString(data.subtitle) ? data.subtitle : "";
    data.text = this._forceInlineHTML(data.text);

    return data;
  }

  _forceInlineHTML(string) {
    if (!string) return "";

    // replace div and p by br
    return String(string)
      .replace(/<(div|p)[^>]*><br><\/?(div|p)[^>]?>/g, "<br>")
      .replace(/(<\/?(div|p)[^>]*>)+/g, "<br>");
  }

  _buildBlockElem(blockData) {
    // peat: paris editor accordion toggler
    const togglerId = _uniqueId("peat-");

    // N.B.: as enableLineBreaks is enabled for this plugin, we have to make sure that contenteditable tags are p
    // Then specific style is applied for line breaks to insert br to keep only inline tags in contenteditable
    // see discussion in issue #1212 (https://github.com/parisnumerique/paris-fr-v3/issues/1212)
    const blockElemOptions = {
      tag: "div",
      class: this._selectors.block,
      contents: [
        {
          tag: "input",
          type: "text",
          class: this._selectors.title,
          placeholder: "titre...",
          value: blockData.title,
          events: { keydown: this._disableEnter.bind(this) },
        },
        {
          tag: "input",
          type: "checkbox",
          id: togglerId,
          class: this._selectors.toggler,
        },
        {
          tag: "label",
          for: togglerId,
          innerHTML: blockData.compressed ? AccordionBlock.icons.arrowCompact : AccordionBlock.icons.arrow,
        },
        {
          tag: "input",
          type: "text",
          class: this._selectors.subtitle,
          id: `${togglerId}-subtitle`,
          placeholder: "sous-titre...",
          value: blockData.subtitle,
          events: { keydown: this._disableEnter.bind(this) },
        },
        {
          tag: "div",
          class: this._selectors.panel,
          contents: [
            {
              tag: "p",
              class: this._selectors.text,
              "data-placeholder": "texte...",
              contenteditable: true,
              innerHTML: blockData.text,
            },
          ],
        },
      ],
    };

    if (this.readOnly) {
      blockElemOptions.contents[0].readonly = true;
      blockElemOptions.contents[1].readonly = true;
    }

    return Bliss.create(blockElemOptions);
  }

  // LISTENERS

  _disableEnter(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }

  _buildSettingsElem() {
    const holder = document.createElement("DIV");

    const compressBtn = document.createElement("SPAN");
    compressBtn.classList.add("cdx-settings-button");
    compressBtn.setAttribute("id", "compress");
    compressBtn.setAttribute("data-type", "state");
    compressBtn.dataset.state = "compress";
    compressBtn.innerHTML = this.data.compressed ? AccordionBlock.icons.expand : AccordionBlock.icons.compress;

    compressBtn.addEventListener("click", () => {
      compressBtn.innerHTML = this.compressIcon;
      this.data.compressed = !this.data.compressed;
      const subtitle = this._blockElem.querySelector(`.${this._selectors.subtitle.replace("d-none", "").trim()}`);
      subtitle.classList.toggle("d-none", this.data.compressed);
      this._blockElem.classList.toggle("is-compact", this.data.compressed);
      this._blockElem.querySelector("label").innerHTML = this.data.compressed
        ? AccordionBlock.icons.arrowCompact
        : AccordionBlock.icons.arrow;
      if (this.data.compressed) subtitle.value = "";
    });

    holder.appendChild(compressBtn);
    return holder;
  }
}
