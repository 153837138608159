/* global Bliss */

export default class ItalicInline {
  static title = "italic-inline";

  static get isInline() {
    return true;
  }

  static get icons() {
    return {
      italic: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/italic.svg"),
    };
  }

  static get sanitize() {
    return {
      i: {},
      em: {},
    };
  }

  get shortcut() {
    return "CMD+I";
  }

  constructor({ api, _ }) {
    this.api = api;

    this._buttonElem = null;
  }

  // create button
  render() {
    this._buttonElem = Bliss.create({
      tag: "button",
      type: "button",
      class: this.api.styles.inlineToolButton,
      innerHTML: ItalicInline.icons.italic,
    });

    return this._buttonElem;
  }

  // tool button is clicked
  surround(_) {
    document.execCommand("italic");
  }

  // determine tool state when selection happens
  checkState(_) {
    const isActive = document.queryCommandState("italic");

    this._buttonElem.classList.toggle(this.api.styles.inlineToolButtonActive, isActive);

    return isActive;
  }
}
