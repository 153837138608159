/* global Bliss */

import _assign from "lodash/assign";
import _constant from "lodash/constant";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _isNil from "lodash/isNil";
import _map from "lodash/map";
import _times from "lodash/times";

import "./table_block.scss";

export default class TableBlock {
  // TableBlock data schema
  // {
  //   rows: [
  //     {
  //       cells: [< string: inline HTML >, ...]
  //     },
  //     ...
  //   ]
  // }

  static get icons() {
    return {
      table: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/table.svg"),
      add: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/plus-circle.svg"),
      remove: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/times-circle.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "table",
      icon: TableBlock.icons.table,
    };
  }

  static get defaultConfig() {
    return {
      placeholder: "...",
    };
  }

  // avoid creating new paragraph block on input enter, it sometimes generate invalid paragraph block
  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      cells: {
        br: true,
      },
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = this._parseConfig(config);

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-table",
      actionsRow: "pe-table-row-actions",
      actions: "pe-table-actions",
      tr: "paris-table-tr",
      th: "paris-table-th",
      td: "paris-table-td",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    // add edit listener to links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.on(anchorElem, "click", this._editLink.bind(this), false);
    });

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    const blockData = {
      rows: [],
    };

    const theadRow = blockContent.querySelector("table thead ." + this._selectors.tr);
    const thCells = _map(Bliss.$("." + this._selectors.th + " p", theadRow), (th) => {
      return th.innerHTML;
    });

    blockData.rows.push({ cells: thCells });

    const tbodyRows = blockContent.querySelectorAll("table tbody ." + this._selectors.tr);
    tbodyRows.forEach((row) => {
      const tdCells = _map(Bliss.$("." + this._selectors.td + " p", row), (td) => {
        return td.innerHTML;
      });

      blockData.rows.push({ cells: tdCells });
    });

    return blockData;
  }

  // remove block if no cells
  validate(savedData) {
    return (
      _isArray(savedData.rows) &&
      savedData.rows.length > 0 &&
      _isArray(savedData.rows[0].cells) &&
      savedData.rows[0].cells.length > 0
    );
  }

  destroy() {
    // remove edit listeners on links
    const anchorsArray = Bliss.$("a", this._blockElem);
    anchorsArray.forEach((anchorElem) => {
      this.api.listeners.off(anchorElem, "click", this._editLink.bind(this), false);
    });
  }

  // GETTERS & SETTERS

  // PRIVATE

  _normalizeData(data) {
    if (typeof data !== "object") {
      data = {
        rows: [{ cells: ["", ""] }, { cells: ["", ""] }],
      };
    }

    if (!_isArray(data.rows)) {
      data.rows = [{ cells: ["", ""] }, { cells: ["", ""] }];
    }

    data.rows.forEach((row) => {
      if (!_isArray(row.cells)) {
        row.cells = ["", ""];
      }
    });

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, TableBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "div",
      class: this._selectors.block,
      contents: [
        {
          tag: "table",
          class: "paris-table",
          contents: [
            { tag: "thead", contents: [] },
            { tag: "tbody", contents: [] },
          ],
        },
      ],
    };

    const rows = blockData.rows;
    const theadContents = _get(blockElemOptions, "contents[0].contents[0].contents");
    const tbodyContents = _get(blockElemOptions, "contents[0].contents[1].contents");

    // add rows options
    rows.forEach((rowData, index) => {
      if (index === 0) {
        theadContents.push(this._rowOptions(rowData, index));
      } else {
        tbodyContents.push(this._rowOptions(rowData, index));
      }
    });

    // add action row options
    if (!this.readOnly) {
      const colCount = _get(rows, "[0].cells.length", 0);
      if (colCount > 0) {
        const actionRowContents = _times(colCount, (index) => {
          return this._actionCellOptions(null, index);
        });
        actionRowContents.push(this._actionCellOptions());

        tbodyContents.push({
          tag: "tr",
          class: this._selectors.actionsRow,
          contents: actionRowContents,
        });
      }
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }

  _rowOptions(rowData, index) {
    const rowOptions = {
      tag: "tr",
      class: this._selectors.tr,
      contents: [],
    };

    const cells = rowData.cells;

    // add cells options
    cells.forEach((cellData) => {
      rowOptions.contents.push(this._cellOptions(cellData, index));
    });

    if (!this.readOnly) {
      rowOptions.contents.push(this._actionCellOptions(index));
    }

    return rowOptions;
  }

  _cellOptions(cellData, index) {
    const isTh = index === 0;

    const cellOptions = {
      tag: isTh ? "th" : "td",
      class: isTh ? this._selectors.th : this._selectors.td,
      contents: [
        {
          tag: "p",
          contenteditable: !this.readOnly,
          "data-placeholder": this.config.placeholder,
          innerHTML: cellData,
        },
      ],
    };

    if (isTh) {
      cellOptions.scope = "col";
    }

    return cellOptions;
  }

  _actionCellOptions(rowIndex, colIndex) {
    const isTh = rowIndex === 0;
    const isActionRow = _isNil(rowIndex);
    const isActionCol = _isNil(colIndex);

    // add action cell
    const actionCellOptions = {
      tag: isTh ? "th" : "td",
      class: this._selectors.actions,
      contents: [],
    };

    if (isTh) {
      return actionCellOptions;
    }

    const actionAddOptions = {
      tag: "button",
      type: "button",
      class: "btn btn-sm " + this._selectors.actions + "__add",
      title: isActionRow ? "ajouter une colonne" : "ajouter une ligne",
      innerHTML: TableBlock.icons.add,
      events: { click: isActionRow ? this._addCol.bind(this) : this._addRow.bind(this) },
    };

    actionCellOptions.contents.push(actionAddOptions);

    if (isActionRow && isActionCol) {
      actionCellOptions.contents.push(
        _assign({}, actionAddOptions, {
          class: actionAddOptions.class + " add-row",
          title: "ajouter une ligne",
          events: { click: this._addRow.bind(this) },
        })
      );

      return actionCellOptions;
    }

    actionCellOptions.contents.push({
      tag: "button",
      type: "button",
      tabindex: 0,
      class: "btn btn-sm " + this._selectors.actions + "__remove",
      title: isActionRow ? "supprimer la colonne" : "supprimer la ligne",
      innerHTML: TableBlock.icons.remove,
      events: {
        click: isActionRow ? this._removeCol.bind(this) : this._removeRow.bind(this),
        // blur: this._deactivateAction.bind(this)
      },
    });

    return actionCellOptions;
  }

  _cellIndex(cell) {
    let index = 0;
    while ((cell = cell.previousSibling) && cell.nodeType !== 9) {
      if (cell.nodeType === 1) {
        index++;
      }
    }

    return index;
  }

  // LISTENERS

  _addRow(event) {
    this._stopEverything(event);

    const colCount = _get(
      this._blockElem.querySelectorAll("table thead ." + this._selectors.tr + " ." + this._selectors.th),
      "length",
      0
    );

    const rowData = {
      cells: _times(colCount, _constant("")),
    };

    // create row, insert it before the row containing the clicked button
    Bliss.before(Bliss.create(this._rowOptions(rowData, 1)), event.target.closest("tbody tr"));
  }

  _removeRow(event) {
    this._stopEverything(event);

    // if (event.target && !event.target.classList.contains("active")) {
    //   event.target.classList.add("active");
    //   return;
    // }

    const currentRow = event.target.closest("tbody ." + this._selectors.tr);

    if (currentRow && currentRow.parentNode) {
      currentRow.parentNode.removeChild(currentRow);
    }
  }

  _addCol(event) {
    this._stopEverything(event);

    const currentCell = event.target.closest("td");
    const currentCellIndex = this._cellIndex(currentCell);

    // add cell in thead row
    const theadRow = this._blockElem.querySelector("table thead tr");
    Bliss.before(Bliss.create(this._cellOptions("", 0)), Bliss.$("th", theadRow)[currentCellIndex]);

    // add cells in tbody rows
    const tbodyRows = this._blockElem.querySelectorAll("table tbody tr");
    tbodyRows.forEach((row, index, array) => {
      const cellOptions = index === array.length - 1 ? this._actionCellOptions(null, 1) : this._cellOptions("", 1);
      Bliss.before(Bliss.create(cellOptions), Bliss.$("td", row)[currentCellIndex]);
    });
  }

  _removeCol(event) {
    this._stopEverything(event);

    const currentCell = event.target.closest("td");
    const currentCellIndex = this._cellIndex(currentCell);

    // remove cell in thead row
    const theadRow = this._blockElem.querySelector("table thead tr");
    theadRow.removeChild(Bliss.$("th", theadRow)[currentCellIndex]);

    // remove cells in tbody rows
    const tbodyRows = this._blockElem.querySelectorAll("table tbody tr");
    tbodyRows.forEach((row) => {
      row.removeChild(Bliss.$("td", row)[currentCellIndex]);
    });
  }

  // _deactivateAction(event) {
  //   console.log("BLUR ACTION", event)
  //   event.target.classList.remove("active");
  // }

  _stopEverything(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  _editLink(_event) {
    const anchorElem = this.api.selection.findParentTag("A");

    if (anchorElem) {
      this.api.selection.expandToTag(anchorElem);
      this.api.inlineToolbar.open();
    }
  }
}
