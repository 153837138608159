import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row", "updateBtn"];

  ACTIONS
  toggleChoice(event) {
    const elem = event.target;
    const wrapper = event.target.closest(".open-agenda-row");
    const valueField = wrapper.querySelector(".open-agenda-value");

    const pageElem = wrapper.querySelector(".page-elem");
    const openAgendaElem = wrapper.querySelector(".open-agenda-elem");

    if (elem.classList.contains("open-agenda-event")) {
      wrapper.querySelectorAll(".open-agenda-event-value").forEach((item) => {
        const name = elem.checked ? item.dataset.name : null;
        item.setAttribute("name", name);
      });
    } else if (elem.classList.contains("open-agenda-place")) {
      wrapper.querySelectorAll(".open-agenda-value").forEach((field) => {
        field.name = elem.checked ? field.dataset.name : null;
      });
    } else {
      if (openAgendaElem.classList.contains("image")) {
        valueField.value = elem.checked ? openAgendaElem.getAttribute("src") : pageElem.getAttribute("src");
      } else if (openAgendaElem.hasAttribute("data-value")) {
        const value = elem.checked ? openAgendaElem.dataset.value : pageElem.dataset.value;
        valueField.value = value || "";
      } else {
        valueField.value = elem.checked ? openAgendaElem.innerText : pageElem.innerText;
      }
    }

    this._toggleUpdateBtn();
  }

  _getOpenAgendasController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("open-agendas-listing-wrapper"),
      "open-agendas"
    );
  }

  _toggleUpdateBtn() {
    if (!this.hasUpdateBtnTarget) return;
    const checkeds = document.querySelectorAll("input[type=checkbox]:checked");
    const openAgendasController = this._getOpenAgendasController();

    openAgendasController.updateLinkTarget.classList.toggle("disabled", !checkeds.length);
  }
}
