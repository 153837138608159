/* global Bliss */

import _assign from "lodash/assign";
import _get from "lodash/get";
import _isString from "lodash/isString";
import _pick from "lodash/pick";

import "./html_block.scss";

export default class HtmlBlock {
  // HtmlBlock data schema
  // {
  //   html: < string: HTML >
  // }

  static get icons() {
    return {
      terminal: require("!svg-inline-loader?classPrefix!@fortawesome/fontawesome-free/svgs/solid/terminal.svg"),
    };
  }

  static get toolbox() {
    return {
      title: "html",
      icon: HtmlBlock.icons.terminal,
    };
  }

  static get defaultConfig() {
    return {
      readOnly: true,
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  static get sanitize() {
    return {
      html: true,
    };
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = this._parseConfig(config);
    this.readOnly = readOnly || this.config.readOnly;

    this.data = this._normalizeData(data);

    this._selectors = {
      block: "pe-html",
      input: "pe-html-input",
      feedback: "pe-html-feedback",
    };

    this._blockElem = null;
  }

  // data => view
  render() {
    this._blockElem = this._buildBlockElem(this.data);

    return this._blockElem;
  }

  // view => data
  save(blockContent) {
    // make sure not to modify on readOnly
    if (this.readOnly) {
      return this.data;
    }

    const inputElem = blockContent.querySelector("." + this._selectors.input);

    return {
      html: _get(inputElem, "value", "").trim(),
    };
  }

  // remove block if empty
  validate(savedData) {
    return savedData.html.trim() !== "";
  }

  merge(blockData) {
    this._blockElem.value += blockData.html;
  }

  // PRIVATE

  _normalizeData(data) {
    data = _pick(data, ["html"]);

    data.html = _isString(data.html) ? data.html : "";

    return data;
  }

  _parseConfig(config) {
    const assignedConfig = _assign({}, HtmlBlock.defaultConfig, config);

    return assignedConfig;
  }

  _buildBlockElem(blockData) {
    // init blockElem options
    const blockElemOptions = {
      tag: "label",
      className: this._selectors.block,
      contents: [
        {
          tag: "span",
          innerText: this.api.i18n.t("title"),
        },
        {
          tag: "textarea",
          className: this._selectors.input + " " + this.api.styles.input,
          placeholder: this.api.i18n.t("placeholder"),
          rows: 5,
          value: blockData.html,
        },
        {
          tag: "span",
          className: this._selectors.feedback,
          innerText: this.api.i18n.t("feedbackReadOnly"),
        },
      ],
    };

    if (this.readOnly) {
      blockElemOptions.contents[1].readonly = true;
    }

    // create blockElem
    return Bliss.create(blockElemOptions);
  }
}
