import { Modal } from "bootstrap";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["modal"];

  // LIFECYCLE

  initialize() {
    this.dataKey = "reject";
  }

  connect() {
    if (this.hasModalTarget) {
      this.modal = new Modal(this.modalTarget);
    }
  }

  // ACTIONS

  showModal(event) {
    this.triggerer = event.currentTarget;

    // no modal or it has already been validated
    if (!this.modal || this.getData(this.triggerer, this.dataKey, false)) {
      this.deleteData(this.triggerer, this.dataKey);
      return;
    }

    this.stopEventPropagation(event);

    this.modal.show();
  }

  submitReject(_event) {
    if (!this.triggerer) {
      return;
    }

    this.setData(this.triggerer, this.dataKey, true);
    this.modal.hide();
    this.triggerer.click();
  }

  resetFieldset(_event) {
    if (!this.hasModalTarget || this.getData(this.triggerer, this.dataKey, false)) {
      return;
    }

    // checkboxes
    const checkboxes = this.modalTarget.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => (checkbox.checked = false));

    // inputs
    const inputs = this.modalTarget.querySelectorAll("textarea");
    inputs.forEach((input) => (input.value = ""));
  }
}
