import _assign from "lodash/assign";
import _isEmpty from "lodash/isEmpty";
import _reduce from "lodash/reduce";

import ApplicationController from "./application_controller";

import EditorJS from "@editorjs/editorjs";

// inline
import BoldInline from "editor/bold_inline/bold_inline";
import ItalicInline from "editor/italic_inline/italic_inline";
import LinkInline from "editor/link_inline/link_inline";
import SubInline from "editor/sub_inline/sub_inline";
import SupInline from "editor/sup_inline/sup_inline";

// block
import TextBlock from "editor/text_block/text_block";
import TitleBlock from "editor/title_block/title_block";
import ListBlock from "editor/list_block/list_block";
import FrameBlock from "editor/frame_block/frame_block";
import VerbatimBlock from "editor/verbatim_block/verbatim_block";
import ImageBlock from "editor/image_block/image_block";
import VideoBlock from "editor/video_block/video_block";
import LinkBlock from "editor/link_block/link_block";
import ButtonBlock from "editor/button_block/button_block";
import ReferenceBlock from "editor/reference_block/reference_block";
import AccordionBlock from "editor/accordion_block/accordion_block";
import TableBlock from "editor/table_block/table_block";
import FormBlock from "editor/form_block/form_block";
import IframeBlock from "editor/iframe_block/iframe_block";
import HtmlBlock from "editor/html_block/html_block";
import QuizBlock from "editor/quiz_block/quiz_block";

export default class extends ApplicationController {
  static targets = ["holder", "blocks"];
  static values = { readOnly: Boolean, toolsConfig: Object };

  // LIFECYCLE

  initialize() {
    this.editorError = { message: "", data: [] };
  }

  connect() {
    this.initEditor();
  }

  disconnect() {
    // if (this.editor instanceof EditorJS) {
    //   this.editor.destroy();
    // }
  }

  initEditor(data) {
    let editorData = data || {};

    if (_isEmpty(editorData) && this.hasBlocksTarget) {
      try {
        editorData = JSON.parse(this.blocksTarget.value);
      } catch (err) {
        console.error("Editor data is invalid JSON");
      }
    }

    this.holderTarget.classList.toggle("is-readonly", this.readOnlyValue);

    // TODO set editor's config from editor component
    this.editor = new EditorJS({
      readOnly: this.readOnlyValue,
      holder: this.holderTarget,
      placeholder: "Votre texte...",
      defaultBlock: "text",
      inlineToolbar: ["bold-inline", "italic-inline", "link-inline", "sub-inline", "sup-inline"],
      tools: this.toolsConfig,
      i18n: this.i18nConfig,
      data: editorData,
      minHeight: 20,
      logLevel: "ERROR",
    });
  }

  render(data, readOnly) {
    readOnly = readOnly || false;

    this.editor.destroy();
    this.readOnlyValue = readOnly;
    this.initEditor(data);

    // TODO this API isn't working correctly, retry on version update after #2.19.1-paris
    // this.editor.render(data);
    // this.editor.readOnly.toggle(readOnly);
  }

  save() {
    return this.editor.save().then((outputData) => {
      const errors = _reduce(
        outputData.blocks,
        (result, block, index) => {
          if (block.data._errors) {
            const e = block.data._errors;
            e.index = index;
            result.push(e);
          }

          return result;
        },
        []
      );

      if (errors.length > 0) {
        throw Object.create(this.editorError, {
          message: { value: "editor errors" },
          data: { value: errors },
        });
      }

      if (this.hasBlocksTarget) {
        // update the hidden input field value
        this.blocksTarget.value = JSON.stringify(outputData);
      }

      return outputData;
    });
  }

  // GETTERS & SETTERS

  get toolsConfig() {
    const toolsConfig = {
      // INLINE
      "bold-inline": {
        class: BoldInline,
        shortcut: "CMD+B",
      },
      "italic-inline": {
        class: ItalicInline,
        shortcut: "CMD+I",
      },
      "link-inline": {
        class: LinkInline,
        shortcut: "CMD+K",
        config: {
          placeholder: "https://...",
        },
      },
      "sub-inline": SubInline,
      "sup-inline": SupInline,
      // BLOCK
      text: {
        class: TextBlock,
        inlineToolbar: true,
      },
      title: {
        class: TitleBlock,
        inlineToolbar: ["italic-inline", "sub-inline", "sup-inline"],
        config: {
          levels: [2, 3, 4, 5],
        },
      },
      list: {
        class: ListBlock,
        inlineToolbar: true,
      },
      frame: {
        class: FrameBlock,
        inlineToolbar: true,
      },
      verbatim: VerbatimBlock,
      image: {
        class: ImageBlock,
        config: {
          placeholder: "légende",
        },
      },
      video: {
        class: VideoBlock,
        config: {
          placeholder: "urls de vidéo reconnues : youtube, dailymotion, viméo et ina",
        },
      },
      link: {
        class: LinkBlock,
      },
      button: {
        class: ButtonBlock,
        config: {
          textPlaceholder: "texte du bouton...",
          hrefPlaceholder: "url du lien associé...",
          defaultModifiers: ["is-red", "is-centered"],
        },
      },
      reference: {
        class: ReferenceBlock,
        inlineToolbar: true,
        config: _assign({}, this.toolsConfigValue.reference),
      },
      accordion: {
        class: AccordionBlock,
        inlineToolbar: true,
      },
      table: {
        class: TableBlock,
        inlineToolbar: true,
        config: {
          placeholder: "à remplir",
        },
      },
      form: {
        class: FormBlock,
        config: _assign(
          {
            placeholder: "rechercher un formulaire existant (par son titre ou son identifiant)",
          },
          this.toolsConfigValue.form
        ),
      },
      quiz: {
        class: QuizBlock,
        config: _assign(
          {
            placeholder: "rechercher un quiz (par son titre ou son identifiant)",
          },
          this.toolsConfigValue.quiz
        ),
      },
      iframe: {
        class: IframeBlock,
        config: _assign({}, this.toolsConfigValue.iframe),
      },
      html: {
        class: HtmlBlock,
        config: _assign({}, this.toolsConfigValue.html),
      },
    };

    return toolsConfig;
  }

  get i18nConfig() {
    return {
      messages: {
        ui: {
          inlineToolbar: {
            converter: {
              "Convert to": "convertir en",
            },
          },
        },
        toolNames: {
          "bold-inline": "gras",
          "italic-inline": "italique",
          "link-inline": "lien",
          "sub-inline": "indice",
          "sup-inline": "exposant",
          accordion: "accordéon",
          button: "bouton",
          form: "formulaire",
          frame: "encadré",
          html: "html",
          iframe: "iframe",
          image: "images",
          link: "lien",
          list: "liste",
          quiz: "quiz",
          reference: "renvoi",
          table: "tableau",
          text: "paragraphe",
          title: "titre",
          verbatim: "verbatim",
          video: "vidéo",
        },
        tools: {
          accordion: {
            title: "accordéon",
          },
          button: {
            title: "bouton",
          },
          form: {
            title: "formulaire",
          },
          frame: {
            title: "encadré",
          },
          html: {
            title: "html",
            placeholder: "code HTML",
            feedbackReadOnly: "vous n'avez pas les droits suffisants pour éditer ce code HTML",
          },
          iframe: {
            title: "iframe",
            placeholder: "<iframe ...></iframe>",
            feedbackNoIframe: "le code ne contient pas de balise iframe valide",
            feedbackNoSrc: "la source de l'iframe est invalide",
            feedbackTooMany: "le code ne doit contenir qu'une balise iframe",
            feedbackVideo: "le composant vidéo est préférable pour les iframes de ce service",
          },
          image: {
            title: "images",
          },
          list: {
            title: "liste",
          },
          quiz: {
            title: "quiz",
          },
          reference: {
            title: "renvoi",
            radioQFAP: "Événements et activités QFAP",
            radioParis: "Autres contenus de Paris.fr",
          },
          stub: {
            "The block can not be displayed correctly.": "Ce composant ne peut s'afficher correctement.",
          },
          table: {
            title: "tableau",
          },
          title: {
            title: "titre",
          },
          verbatim: {
            title: "verbatim",
          },
          video: {
            title: "vidéo",
            label: "url de la vidéo",
            feedback:
              "cette url n'est pas reconnue, utilisez le composant iframe pour obtenir le player vidéo du service associé",
          },
        },
      },
    };
  }
}
