export const DEFAULT_ICON = {
  iconSize: [54, 62],
  iconAnchor: [26, 62],
};

export const DEFAULT_ZOOM = 14;
export const DEFAULT_LAYER_ATTRIBUTION =
  'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
export const TILE_LAYER = "https://cdn.paris.fr/leaflet/paris/{z}/{x}/{y}.png";
export const TILE_LAYER_MIN_ZOOM = 11;

export const OPTIONS = {
  zoom: DEFAULT_ZOOM,
  scrollWheelZoom: false,
  zoomControl: true,
};

export const DEFAULT_COORDS = [48.856578, 2.351828];
