/* global Bliss */

import _isString from "lodash/isString";
import { getElementTopYPosition } from "../helpers/position_helper";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["editorErrors"];

  initialize() {}

  alert(errors) {
    if (!this.editorErrorsTarget) {
      return;
    }

    this.element.hidden = false;
    this.element.removeChild(this.element.querySelector("ul"));

    const errorsOptions = {
      tag: "ul",
      contents: this._errorsListOptions(errors),
    };
    this.element.insertBefore(Bliss.create(errorsOptions), this.editorErrorsTarget);

    this._scrollToFeedback();
  }

  editorAlert(errors) {
    if (!this.editorErrorsTarget) {
      return;
    }

    this.element.hidden = false;
    this.editorErrorsTarget.hidden = false;
    this.editorErrorsTarget.removeChild(this.editorErrorsTarget.querySelector("ul"));

    const errorsOptions = {
      tag: "ul",
      contents: this._errorsListOptions(errors),
    };
    this.editorErrorsTarget.appendChild(Bliss.create(errorsOptions));

    this._scrollToFeedback();
  }

  // ACTIONS

  scrollToBlock(event) {
    event.preventDefault();

    const blockIndex = event.target.dataset.blockIndex;
    const blockElems = document.querySelectorAll(".ce-block");

    if (blockElems.length > blockIndex) {
      window.scrollTo({
        top: getElementTopYPosition(blockElems[blockIndex]) - 90,
        behavior: "smooth",
      });
    }
  }

  // PRIVATE

  _scrollToFeedback() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  _errorsListOptions(errors) {
    return errors.map((error) => {
      if (_isString(error)) {
        return { tag: "li", textContent: error };
      }

      return {
        tag: "li",
        contents: [
          {
            tag: "a",
            href: "",
            "data-action": "feedback#scrollToBlock",
            "data-block-index": error.index,
            textContent: error.title,
          },
          " : " + error.messages.join(", "),
        ],
      };
    });
  }
}
