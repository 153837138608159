import _get from "lodash/get";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["groupSelect", "envueThemeSelect"];
  static values = { shown: Boolean };

  connect() {
    this.toggleEnvueVisibility();
  }

  toggleEnvueVisibility() {
    if (!this.hasEnvueThemeSelectTarget) {
      return;
    }

    let hasEnvue = false;

    // visibility set according to controller shown value
    if (this.hasShownValue) {
      hasEnvue = this.shownValue;
    }

    // visibility set according to group select value
    if (this.hasGroupSelectTarget) {
      hasEnvue =
        _get(this.groupSelectTarget, ["options", this.groupSelectTarget.selectedIndex, "dataset", "hasEnvue"]) ==
        "true";
    }

    const envueThemeContainer = this.envueThemeSelectTarget.closest(".form-group");
    if (envueThemeContainer) {
      envueThemeContainer.hidden = !hasEnvue;
    }
  }
}
