import ApplicationController from "./application_controller";
import axios from "axios";
import {
  ENDPOINT,
  ROOT_URL,
  FILTERS,
  VELIB_API,
} from "../../components/form/transport_component/transport_config.component.js";
import "../../components/form/transport_component/transport_component.scss";

export default class extends ApplicationController {
  static targets = ["latitude", "longitude", "railway", "street", "velib", "transport"];

  connect() {
    this.token = "46475388-c255-434e-a3c9-d40b476e4666";
  }

  updateStopAreas() {
    const lat = this.latitudeTarget.value;
    const lng = this.longitudeTarget.value;

    if (lat) {
      this._requestAPIs(lat, lng);
    }
  }

  // PRIVATE
  _requestAPIs(lat, lng) {
    const path = `coverage/fr-idf/coord/${lng}%3B${lat}/`;
    const queries = [
      {
        url: `${ROOT_URL}${path}${ENDPOINT}?${FILTERS}&distance=2000&type%5B%5D=stop_area&filter=physical_mode.id%3Dphysical_mode%3AMetro%20or%20physical_mode.id%3Dphysical_mode%3ARapidTransit%20or%20physical_mode.id%3Dphysical_mode%3ALocalTrain&count=3`,
        modes: ["physical_mode:Metro", "physical_mode:RapidTransit", "physical_mode:LocalTrain"],
        type: "railway",
      },
      {
        url: `${ROOT_URL}${path}${ENDPOINT}?${FILTERS}&distance=2000&count=1&type%5B%5D=stop_area&filter=physical_mode.id%3Dphysical_mode%3ABus%20or%20physical_mode.id%3Dphysical_mode%3ATramway&count=1`,
        modes: ["physical_mode:Bus", "physical_mode:Tramway"],
        type: "street",
      },
      {
        url: `${VELIB_API}&rows=1&geofilter.distance=${lat},+${lng}, +1000`,
        target: this.velibTarget,
        label: "Vélib",
      },
    ];

    this._runAll(queries);
  }

  _runAll(endpoints_params) {
    this._clearTargetsHtml();
    return Promise.all(endpoints_params.map((item) => this._axiosQuery(item))).then((stop_areas) => {
      // Wait for all axios request & then ...
      const resultFromNavitia = stop_areas.filter((res) => res.modes); // Metro, RER, train, bus, tramway from Navitia API
      const resultFromOpenData = stop_areas.filter((res) => !res.modes); // Velib from opendata Paris

      let transportsJson = [];

      resultFromNavitia.forEach((item) => {
        if (!item.data || !item.data.places_nearby) return;

        const title = item.type === "railway" ? "Metro, RER, train" : "Bus, tramway";
        const target = item.type === "railway" ? this.railwayTarget : this.streetTarget;

        const transportLines = item.data.places_nearby.map((transport) => {
          // Attributes to save in DB:
          // transport.name, transport.distance, [transport.stop_area.coord.lat, transport.stop_area.coord.lon]
          // -> { :type, :name, :distance, coord: [lat, lng], lines: { :color, :code } }
          const linesObject = transport.stop_area.lines
            .filter((line) => item.modes.includes(line.physical_modes[0].id))
            .map((line) => {
              return { color: line.color, code: line.code };
            });

          transportsJson = [
            ...transportsJson,
            {
              type: item.type,
              name: transport.name,
              distance: transport.distance,
              lines: linesObject,
              coord: [Number(transport.stop_area.coord.lat), Number(transport.stop_area.coord.lon)],
            },
          ];

          const pictos = linesObject
            .map((line) => {
              return `<span class="rounded-circle transport-picto" style="background-color: #${line.color};">${line.code}</span>`;
            })
            .join(" ");

          return {
            distance: transport.distance,
            render: `<li>${pictos} ${transport.name} <small>(${transport.distance}m)</small></li>`,
          };
        });

        this._renderCard(title, transportLines.map((item) => item.render).join(" "), target);
      });

      resultFromOpenData.forEach((item) => {
        if (item.data.records.length === 0) return;
        const name = item.data.records[0].fields.name;
        const distance = Number(item.data.records[0].fields.dist).toFixed(2);
        // Attributes to save in DB:
        // name, distance, [item.data.records[0].geometry.coordinates[1], item.data.records[0].geometry.coordinates[0]]
        // -> { :type, :name, :distance, coord: [lat, lng] }
        transportsJson = [
          ...transportsJson,
          {
            type: item.type,
            name,
            distance,
            coord: [item.data.records[0].geometry.coordinates[1], item.data.records[0].geometry.coordinates[0]],
          },
        ];

        this._renderCard("Vélib", [`<li>${name} <small>(${distance}m)</small></li>`], this.velibTarget);
      });

      this.transportTarget.setAttribute("value", JSON.stringify(transportsJson));
    });
  }

  _axiosQuery(item) {
    const options = {
      method: "get",
      url: item.url,
      dataType: "json",
    };

    if (item.modes) {
      options.headers = {
        Authorization: "Basic " + btoa(this.token),
      };
    }

    return new Promise((resolve) => {
      axios(options)
        .then((response) => {
          resolve({ data: response.data, type: item.type, modes: item.modes });
        })
        .catch((error) => console.error(error));
    });
  }

  _renderCard(title, content, target) {
    target.innerHTML = `<div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">${title}</h5>
        <ul class="transport-list">${content}</ul>
      </div>
    </div>`;
  }

  _clearTargetsHtml() {
    this.railwayTarget.innerHTML = "";
    this.streetTarget.innerHTML = "";
    this.velibTarget.innerHTML = "";
  }
}
